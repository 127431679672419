import React, { useContext, useEffect, useRef, useState } from 'react'
import { Formik, Form } from "formik";
import { InputText, SwitchBox } from "../components/FormElements";
import { MyContext } from "../stores";
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import Loader from "../components/Loader";
import { ToggleOrgShare, ToggleSharingOptions } from "../components/FormManager/ToggleElements";
import FileUpload from '../components/FileUpload';
import { documents, storage } from "../firestore/firestoreService";
import useSWR from 'swr';
import Select from "react-select";
import axios from 'axios';
import Swal from 'sweetalert2';
import {RadioSwitchStyled} from '../components/FormManager/ToggleElements'
const FormWrapper = styled.div`
        .richTextContainer{
            border:1px solid #e8e8e8;
            margin:10px 0;
        }
        .is-fullwidth {
          color: white;
          font-weight: bold;
          background-color: var(--bazookka-orange);
          :hover {
            filter: drop-shadow(1px 1px 1px rgba(0,0,0, 0.25));
            font-size: 15px;
            transition: all 300ms;
          }
        } 
        .react-datepicker__input-container input {
          padding: 8px;
          border: 1px solid var(--bazookka-orange);
          border-radius: 5px;
          background-color: var(--bazookka-orange);
          color: white;
          font-weight: bold;
          filter: drop-shadow(0px 1px 1px rgba(0,0,0, 0.4));
        }
        .toolbar{
            height:30px;
            margin:0;
            padding:1px 0px 17px 5px;
        }

        .richInput{
            padding:10px;
        }

        .toolButton{
            height:24px;
        }
    `

const UploadInputWrapper = styled.div`
        margin:15px 0;
`

export default function EditDocumentForm({ mutate, document, groups, options, creating, onCreate }) {
    const { me, langChoice, globalId, globalIdSql, organizationId } = useContext(MyContext)
    const [toggle, setToggle] = useState("user");
    const [toggleOrgShare, setToggleOrgShare] = useState(false);
    const [test2, setTest2] = useState([])
    const [uploadedDocument, setUploadedDocument] = useState([]);
    const [fileDestination, setFileDestination] = useState("");
    const [fileUploadStatus, setFileUploadStatus] = useState('idle');
    const [newlyUploadedFileId, setNewlyUploadedFileId] = useState();
    const [fileName, setFileName] = useState('')
    const { t } = useTranslation();
    const formikRef = useRef()
    const [postId, setPostId] = useState()
 


    useEffect(() => {
        if (globalId) {
            setFileDestination(`documents/${globalId}/`)
        }
    }, [me, globalId])


    const { data: sharedWithUser, error, mutate: mutateSharedWithUser } = useSWR(!creating ?
        `https://api.bazookka.com/posts/documents/shares/${document.file.post_id}/users` : null,
        url => fetch(url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
            .then(r => r.json())

    )

    const { data: sharedWithGroup, sharedWithGroupError, mutate: mutateSharedWithGroup } = useSWR(!creating ? `https://api.bazookka.com/posts/documents/shares/${document.file.post_id}/groups` : null,
        url => fetch(url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
            .then(r => r.json())

    )

    let correctUrl = null
    if(creating && newlyUploadedFileId){
        correctUrl = `https://api.bazookka.com/posts/documents/shares/${newlyUploadedFileId}/org`
    }else if(!creating){
        correctUrl = `https://api.bazookka.com/posts/documents/shares/${document.file.post_id}/org`
    }

    const { data: sharedWithOrg, sharedWithOrgError, mutate: mutateSharedWithOrg } = useSWR(correctUrl,
    url => fetch(url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
        .then(r => r.json())

)

const [initialState, setData] = useState(creating ? {
    name: '',
    confidential: false,
    orgShare: false,
    users: [],
    groups: [],
} : {
    name: document.file.name,
    users: sharedWithUser,
    groups: sharedWithGroup,
    orgShare: sharedWithOrg ? sharedWithOrg : false,
    confidential: document.file.confidential

});

    useEffect(() => {
        const test = async () => {

            if (creating && newlyUploadedFileId) {
                let payload = []
                if (formikRef.current.values.users) {
                    payload = payload.concat(formikRef.current.values.users.map(u => ({ user_id: u.id, post_id: newlyUploadedFileId })))
                }
                if (formikRef.current.values.groups) {
                    payload = payload.concat(formikRef.current.values.groups.map(g => ({ group_id: g.id, post_id: newlyUploadedFileId })))
                }

                if(formikRef.current.values.orgShare === true){
                    payload.push({organisation_id : organizationId, post_id: newlyUploadedFileId})
                }
                await axios.put(`https://api.bazookka.com/posts/documents/shareUserUpsert`, { shares: payload, post_id: newlyUploadedFileId })

                mutate()
                mutateSharedWithUser()
                mutateSharedWithGroup()
                mutateSharedWithOrg()

            }
        }
        test()
    }, [newlyUploadedFileId])

    if (!sharedWithUser && !creating) {
        return <Loader />
    }

    if (!sharedWithGroup && !creating) {
        return <Loader />
    }

    if (sharedWithOrg === 'undefined' && !creating) {
        return <Loader />
    }


    //This function is to handle when group is selected and confidential turned on
    const handleConfidential = (e) => {
        //For some reason this is inverted false means that confidential is true
        if (e.target.value === 'false') {
            setToggle('user');
        }

    }

    const handleOrgShare = (e, setFieldValue) => {
            setFieldValue('orgShare', !formikRef.current.values.orgShare
            )
            setToggleOrgShare(!formikRef.current.values.orgShare);
        // }

    }

    const handleDocumentUpload = async (fileRef, metadata) => {
        let fileToUpload = {}

        let meta = { fileSize: metadata.size, confidential: formikRef.current.values.confidential, name: formikRef.current.values.name }
        fileToUpload.organisation_id = organizationId
        fileToUpload.title = formikRef.current.values.name
        fileToUpload.author_id = globalIdSql
        fileToUpload.user_id = globalIdSql
        const url = await fileRef.getDownloadURL()
        meta.url = url
        fileToUpload.meta = meta
        if (!creating) {
            fileToUpload.id = document.post_id
        }

        // setFileUploadStatus('uploading');
        let res = await axios.put(`https://api.bazookka.com/posts/documents/fileUpsert`, fileToUpload)

        setNewlyUploadedFileId(res.data.id)
        mutate()
        mutateSharedWithUser()
        mutateSharedWithGroup()

    }

    const onSubmit = async (data, actions) => {
        mutate()
        mutateSharedWithUser()
        mutateSharedWithGroup()
        mutateSharedWithOrg()
        let shGroupUser = []
        if (!creating) {
            if(data.orgShare === true){
        
                shGroupUser.push({organisation_id : organizationId, post_id: document.file.post_id})
            }

            if (data.users && data.users.length > 0) {
                shGroupUser = shGroupUser.concat(data.users.map(u => ({ user_id: u.id, post_id: document.file.post_id })))
            }
            if (data.groups && data.groups.length > 0) {
                shGroupUser = shGroupUser.concat(data.groups.map(g => ({ group_id: g.id, post_id: document.file.post_id })))
            }

            await axios.put(`https://api.bazookka.com/posts/documents/shareUserUpsert`, { shares: shGroupUser, post_id: document.file.post_id })
            shGroupUser.length > 0 && Swal.fire("document shared")
            mutate()
            mutateSharedWithUser()
            mutateSharedWithGroup()
            mutateSharedWithOrg()

        }

        if (!creating) {
            const documentClone = { ...document }
            const metaClone = documentClone.file.meta
            metaClone.name = data.name
            metaClone.confidential = data.confidential
            documentClone.meta = metaClone
            documentClone.id = document.file.post_id
            documentClone.organisation_id = organizationId
            documentClone.author_id = globalIdSql
            documentClone.user_id = globalIdSql

            documentClone.name = data.name
            actions.setSubmitting(true)
            let res = await axios.put(`https://api.bazookka.com/posts/documents/fileUpsert`, documentClone)
            Swal.fire(t("documents.documentUpdated"))
            actions.setSubmitting(false);
            mutate()
        }

        if (creating) {
            actions.setSubmitting(true)
            setFileUploadStatus('uploading');
            const saveDoc = {
                name: data.name,
                authorId: globalId,
                confidential: data.confidential,
                uploadDate: Date.now(),
            }
            documents.add(saveDoc).then(docref => {
                actions.setSubmitting(false);
            })
            setFileUploadStatus('idle');
        }
        if (!creating && onCreate) {
            onCreate()
        }

    }

    if (fileUploadStatus === 'uploading') {
        <Loader />
    }

    return (
        <Formik enableReinitialize={creating ? true : false} innerRef={formikRef} initialValues={initialState} onSubmit={onSubmit}>
            {({ values, isSubmitting, resetForm, setFieldValue, initialValues }) => {
                return (
                    <Form>
                        <FormWrapper className="settings-form-wrapper">
                            {creating &&
                                <UploadInputWrapper>
                                    <FileUpload
                                        dest={fileDestination} confidential={values.confidential} onUpload={handleDocumentUpload} queueStatus={fileUploadStatus} queue={uploadedDocument}
                                        setQueue={setUploadedDocument} setFileName={setFileName}
                                    >
                                        <div className="button is-primary is-fullwidth"><i className="mdi mdi-plus"></i></div>
                                    </FileUpload>
                                </UploadInputWrapper>
                            }

                            <div>{fileName}</div>

                            <div className="settings-form">
                                <div className="field">
                                    <InputText
                                        label={t('listing.name')}
                                        name="name" />
                                </div>
                                <div className="field">
                                    <SwitchBox
                                        label={langChoice === 'fr' ? 'Confidentiel' : 'Confidential'}
                                        name="confidential"
                                        onClick={handleConfidential}
                                    />
                                </div>

                                <div>
                                <SwitchBox
                                        label={t('documents.shareWithOrg')}
                                        name="orgShare"
                                        checked={values.orgShare}
                                        onClick={(e) => handleOrgShare(e,setFieldValue)}
                                    />
                                </div>
                            

                                <div className="field">
                                    {values.confidential === false ?
                                        (
                                            <ToggleSharingOptions
                                                toggle={toggle}
                                                setToggle={setToggle}
                                                translator={t}
                                            ></ToggleSharingOptions>
                                        ) : (
                                            <div>{t('general.users')}</div>
                                        )
                                    }
                                </div>
                                <div className="field">
                                    {toggle === "user" &&

                                        <Select
                                            name="user"
                                            defaultValue={sharedWithUser}
                                            onChange={(v) => {
                                                setFieldValue('users', v)
                                                setData({ ...initialValues, name: values.name, users: v, confidential: values.confidential, orgShare: values.orgShare  })
                                            }}
                                            placeholder={t('general.documents.usersLabel')}
                                            options={options}
                                            isMulti={true}
                                        />
                                    }
                                    {toggle === "group" &&

                                        <Select
                                            name="groups"
                                            defaultValue={sharedWithGroup}
                                            onChange={(v) => {
                                                setFieldValue('groups', v)
                                                setData({ ...initialValues, name: values.name, groups: v, confidential: values.confidential, orgShare: values.orgShare })
                                            }}
                                            placeholder={t('general.documents.groupsLabel')}
                                            options={groups}
                                            isMulti={true}
                                        />
                                    }
                                    {/* TODO FIGURE OUT HOW TO GET THE VALUE OF THIS SELECT*/}
                                </div>
                                {<button className="button is-fullwidth" type="submit">{t('general.save')}</button>}
                                {isSubmitting && <Loader />}
                            </div>
                        </FormWrapper>
                    </Form>
                )
            }}
        </Formik>
    )
}
