import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams, Link} from 'react-router-dom'
import {MyContext} from "../stores";
import styled from 'styled-components'
import Avatar from "react-avatar";
import {format} from "date-fns";
import fr from "date-fns/locale/fr-CA";
import moment from "moment";
import {useLocalStorage} from "../helper";
import {ShareBig} from "../components/Share";
import {RenderTextFromDB} from "./formGenerator/Preview";
import useSWR from "swr";
import axios from "axios";
import Loader from '../components/Loader';

const LoginOver = styled.div`
  a {
    padding: 8px;
    background-color: var(--bazookka-pink);
    color: white;
    border-radius: 8px;
  }
  hr {
    background-color: #0a0a0a;
  }
  width: 100%;
  height: ${ props => props.height + "px;"};
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0,0,0,0.7);
  .login {
    background: white;
    border-radius: 12px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    width: 320px;
    flex-direction: column;
    text-align: center;
    .top {
      justify-content: center;
      display: flex;
      flex-direction: row;
    }
    
  }
`

export const JobPageStyled = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: 50px;
  .post h2 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .image-banner {
    width: 100%;
    height: 400px;
    position: relative;
    div.filter {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      background: black;
      filter: opacity(0.6);
    }
    div.normal {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      height: 400px;
      overflow: hidden;
      .in-normal {
        z-index: 10;
        display: flex;
        flex-direction: row;
        width: 900px;
        justify-content: left;
        align-items: center;
        @media (max-width: 900px) {
          width: 90%;
          margin: auto;
        }
      }
      > div {
        h2 {
          font-size: 2rem;
          color: white;
          z-index: 10;
          padding-left: 1rem;
          filter: drop-shadow(2px 2px 3px rgba(255,255,255, 0.6));
        }
        h6 {
          font-size: 1rem;
          color: white;
          z-index: 10;
          padding-left: 1rem;
          text-align: center;
        }
      }
     
    }
    
  }
  .the-content {
    display: grid;
    grid-template-columns: 1fr 300px;
    width: 900px;
    position: relative;
    background-color: white;
    border: 1px solid #e8e8e8;
    padding: 15px;
    border-radius: 10px;
    margin: -7rem auto 1rem auto;
    height: fit-content;
    @media (max-width: 900px){
      width: 80%;       
    }

    @media (max-width: 768px){
      display: flex;
      flex-direction: column-reverse;
      width: 80%;
    }
    
    z-index: 1;
    .left {
      height: 100%;
      padding: 1rem;
      border-right: 1px solid #dedede;
    }
    .right {
      height: 100%;
      padding: 1rem;
    }
    
  }
  .bullet {
    display: grid;
    width: 100%;
    height: 50px;
    margin-bottom: 1rem;
    gap: 1px;
    grid-template: 
            "round top"
              "round buttom" / 50px 1fr;
    .round {
      border-radius: 50%;
      margin: 10px 10px 10px 0;
      border: 2px solid var(--bazookka-pink);
      grid-area: round;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 90%;
        background: var(--bazookka-pink);
        color: white;
        border: 2px solid var(--bazookka-pink);
        border-radius: 50%;
      }
    }
    .top {
      grid-area: top;
    }
    .buttom {
      grid-area: buttom;
    }
  }
  button {
    background: var(--bazookka-green);
    color: white;
    &:hover {
      color: white;
      filter: opacity(0.75) drop-shadow(1px rgba(0,0,0 ));
      transition: all 300ms;
    }
  }
  .story-post-wrapper {
    margin-right: 0px;
    width: 100%;
    .story-post {
      padding: 0px;
      margin-top: 2rem;
    }
  }
  .categories {
    padding: 3px 5px;
    color: white;
    margin: 2px;
    border-radius: 2px;
    background: var(--bazookka-blue-buble);
    font-size: 12px;
  }
  .mot {
    display: grid;
    height: 145px;
    grid-gap: 3px;
    margin: 10px 0px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    font-size: 1.7rem;
    .filter {
      filter: grayscale(100%) opacity(0.3);
    }
    > div {
      border: 2px solid var(--bazookka-green);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        width: 80%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: var(--bazookka-green);
      }
    }
    
  }
`;

export default function JobPage() {
    const {t} = useTranslation();
    const [post, setPost] = useState(null);
    const [admin, setAdmin] = useState()
    const {langChoice, me, globalIdSql} = useContext(MyContext);
    const [text, setText] = useState('');
    const {slug} = useParams();
    const [taskInProgress, setTaskInProgress] = useState(false)
    const [company, setCompany] = useState(null);

    const [cache, setCache] = useLocalStorage("loc",  null );

    let { data:applications, mutate:mutateApplications } = useSWR(`https://api.bazookka.com/posts/job/${slug}/participants`,
    url => fetch(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'get'
    }).then(r => {
        return r.json()
    }
    ));

    let { data:jobInfo, mutate:mutateJob } = useSWR(`https://api.bazookka.com/posts/job/${slug}`,
    url => fetch(url, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'get'
    }).then(r => {
        return r.json()
    }
    ));

    const onCancel = async (e) => {
        e.preventDefault()
        setTaskInProgress(true)
        await axios.delete(`https://api.bazookka.com/posts/job/${slug}/cancel_application/${globalIdSql}`)
        mutateApplications()
    }

    const onApply =  async (e) => {
        e.preventDefault()
        setTaskInProgress(true)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user_id : globalIdSql,
                post_id : slug
            })
        };

        await fetch('https://api.bazookka.com/posts/job/apply', requestOptions)
        console.log("apres fetch post")
        mutateApplications()
    }
    
    const onClick = async (e) => {
        e.preventDefault()
        setTaskInProgress(true)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user_id : globalIdSql,
                post_id : slug,
                message : text,
                is_public : true
            })
        };
        let res = await fetch('https://api.bazookka.com/posts/job/comments', requestOptions)
        mutateJob()


    }
    const onChange = (e) => {
        setText(e.target.value)
    }
    const setFormat = (value) => {
        if(!value) {
            return;
        }
        try {
            return format(value, 'PPP', {locale: langChoice === 'fr' && fr || null})
        } catch(e) {
            return null
        }
    }

    if(!jobInfo){
        return <Loader/>
    }
    return (
        <div>
            {!me && (
                <LoginOver height={window.innerHeight}>
                    <div className="login">
                        <div className="top">
                            <img src="assets/old/ico.png" width={100}/>
                        </div>
                        <p>{t('general.connect2seepost')}</p>
                        <Link to={"/"}>{t("general.login")}</Link>
                    </div>
                </LoginOver>
            )}
        {
            <JobPageStyled>
                <div className="image-banner">
                    <div className="normal" 
                    style={{background: company?.cover? `url(${company.cover})`:'url(assets/old/wallpaper.png)'}}
                    >
                        <div className="filter"></div>
                        <div className="in-normal">
                            {company?.avatar ?
                                <Avatar src={company?.avatar} round={true} style={{zIndex: 1}}/> :
                                <Avatar name={company?.name} round={true} style={{zIndex: 1}}></Avatar>}
                            <div>
                                <h2>{jobInfo.title}</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="the-content">
                    <div className="left">
                        <div className="post">
                            <h2>{t('general.jobdesc')}</h2>
                            {jobInfo && jobInfo.meta && <RenderTextFromDB desc={jobInfo.meta.desc}/>}

                        </div>
                        <div className="story-post-wrapper">
                            <div className="story-post">
                                <div className="post-compose">
                                    <div className="control">
                                        <textarea className="textarea" placeholder={`${t('general.typecomment')}...`} onChange={onChange} value={text}></textarea>
                                    </div>
                                    <div className="compose-controls">
                                        {me?.avatar ?
                                            <Avatar src={me?.avatar} round={true} size={40}/>
                                            : me && me.displayName ? <Avatar name={me?.displayName} round={true} size={40} />
                                                : <Avatar name={"NN"} round={true} size={40} />}
                                        {me ? <a className="button is-solid accent-button raised" onClick={onClick}>{t('general.postcomment')}</a> :
                                            <Link to="/" className="button is-solid accent-button raised" >{t('general.login')}</Link>}
                                    </div>
                                </div>
                                <div className="comments-wrap">
                                    <div className="comments-count">
                                        <h3>{t('general.comments')} ({jobInfo.comments ? jobInfo.comments.length : 0})</h3>
                                    </div>
                                    {jobInfo.comments && jobInfo.comments.map( (message, i) => {
                                        return (
                                            <div key={i} className="media is-comment">
                                                <div className="media-left">
                                                    <div className="avatar-wrap is-smaller">
                                                        <Avatar name={message.display_name} round={true} size={40}/>
                                                        <div className="badge">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                                 className="feather feather-check">
                                                                <polyline points="20 6 9 17 4 12"></polyline>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="media-content">
                                                    <div className="comment-meta">
                                                        <h4><a>{message.display_name}</a> <small> · {moment(message.created_at).format('LLLL')}</small></h4>
                                                        {message.message}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="bullet">
                            <div className="top">{t('jobs.startDate')}</div>
                            <div className="buttom">{jobInfo.meta && setFormat(jobInfo.meta.startDate*1000)}</div>
                            <div className="buttom">{jobInfo.meta && setFormat(jobInfo.meta.createAt || jobInfo.meta.date)}</div>
                            <div className="round">
                                <div className="circle">
                                    <i className="mdi mdi-calendar"></i>
                                </div>
                            </div>
                        </div>
                        <div className="bullet">
                            <div className="top">{t('jobs.endDate')}</div>
                            <div className="buttom">{jobInfo.meta && setFormat(jobInfo.meta.endDate)}</div>
                            <div className="round">
                                <div className="circle">
                                    <i className="mdi mdi-calendar"></i>
                                </div>
                            </div>
                        </div>
                        <div className="bullet">
                            <div className="top">{t('general.applications')}</div>
                            <div className="buttom">{applications ? applications.count : 0}</div>
                            <div className="round">
                                <div className="circle">
                                    <i className="mdi mdi-human"></i>
                                </div>
                            </div>
                        </div>
                        <div className="bullet">
                            <div className="top">{t('general.share')}</div>
                            <div className="buttom"></div>
                            <ShareBig share={{id: slug, ...post}} size={'42px'}/>
                        </div>

                        <div>
                            {   jobInfo.meta && jobInfo.meta.cat &&  jobInfo.meta.cat.map(c => {
                                return (
                                    <div className="categories">{t(`${c.key}`)}</div>
                                )
                            })
                              
                            }
                        </div>

                        <div className="mot">
                            {
                                jobInfo && jobInfo.meta && jobInfo.meta.mot && [
                                    "mot.ptransport",
                                    "mot.walk",
                                    "mot.pbike",
                                    "mot.bike",
                                    "mot.subway",
                                    "mot.car"
                                ].map( (mot, key) => {
                                    switch(mot){
                                        case "mot.car":
                                            return <div key={key}><i className={`mdi mdi-car ${!jobInfo.meta.mot.includes(mot) && "filter"}`}></i></div>
                                            break;
                                        case "mot.walk":
                                            return <div key={key}><i className={`mdi mdi-walk ${!jobInfo.meta.mot.includes(mot) && "filter"}`}></i></div>
                                            break;
                                        case "mot.pbike":
                                            return <div key={key}><i className={`fas fa-bicycle ${!jobInfo.meta.mot.includes(mot) && "filter"}`}></i></div>
                                            break;
                                        case "mot.bike":
                                            return <div key={key}><i className={`mdi mdi-bike ${!jobInfo.meta.mot.includes(mot) && "filter"}`}></i></div>
                                            break;
                                        case "mot.ptransport":
                                            return <div key={key}><i className={`mdi mdi-bus-side ${!jobInfo.meta.mot.includes(mot) && "filter"}`}></i></div>
                                            break;
                                        case "mot.subway":
                                            return <div key={key}><i className={`mdi mdi-subway ${!jobInfo.meta.mot.includes(mot) && "filter"}`}></i></div>
                                            break;
                                        default:
                                            return <div key={key}><i className={`mdi mdi-tag ${!jobInfo.meta.mot.includes(mot) && "filter"}`}></i></div>
                                    }
                                    return
                                })
                            }
                        </div>
                        {!admin && me &&  (
                            <>
                                {applications && applications.rows.filter(app => app.user_id === globalIdSql).length>0 &&
                                    <button className="button is-solid accent-button raised is-fullwidth" onClick={onCancel} style={{marginBottom: 15}}>{t('listing.cancelApp')}</button>
                                    ||
                                    <button className="button is-solid accent-button raised is-fullwidth"  onClick={onApply} style={{marginBottom: 15}}>{t('listing.apply')}</button>
                                }
                            </>
                        )}
                    </div>
                </div>
            </JobPageStyled>
        }
        </div>
    )
}
