
import {
    Link,
    Route,
    Switch,
    useLocation
} from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from "../stores";
import { ClassRoom } from "../components/ClassRoom";
import { Calendar } from '../components/EditCalendar';
import EditInternshipObjectForm from "../forms/EditInternshipObjectForm";
import { SubCompanyEvent, SubCompanySection, UsersPage } from './CorpDashboard'
import { useTranslation } from "react-i18next";
import UserProfileSettings, { BusinessProfileSetting } from "./Settings/Settings";
import CoverBG, { DeskTopMenu } from "./main/CoverBG";
import BusinessMenu from "../components/BusinessMenu";
import SubProfile from "./main/SubProfile";
import { JobPostFullWidth, InternshipFullWidthCard } from "../components/JobPostCard";
import ScrollToTop from "../components/ScrollToTop";
import { FormDashboard, FormUserDashboard } from "./FormDashboard"
import Userlogs from "./Userlogs";
import { Grid } from '@mui/material'
import { InnerMenu } from "../components/MenuDrawer"
import Documents from "./Documents";
import { GridF3x1 } from "../helper/Grid";
import JobCrud from "./JobCrud";
import InternshipCrud from "./InternshipCrud";
import EventCrud from "./EventCrud";
import Applications from "./Applications";
import UserProfilePage from "./UserProfilePage";
import InviteUsers from "./InviteUsers";
import { DisplayCovTemplateList } from "./CreateEditCoTemplate";
import FeedHome from '../components/home/FeedHome';
import { isJobs, isProfile } from '../helper/acl';
import PayJobsOffers from './PayJobsOffers';
import ManageCompany from "./ManageCompany";
import UsersView from "./UsersView"
import MentorView from "./MentorView"
import InternshipsObject from "./InternshipsObject";
import RatesRoutes from "./Rates"
import MyForms from "./MyForms"
import CalendarPage from "./Calendar";
import DashboardComponent from "./Dashboard";
import NoMatch from "./NoMatch";
import { ClassRoomMigration } from '../migration/components/ClassRoomMigration';

const MainProfile = () => {
    const { me } = useContext(MyContext)
    const { t } = useTranslation()
    const [job_only, set_job_only] = useState(false)
    const [profile, setProfile] = useState(null)

    useEffect(() => {
        if (me) {
            isJobs(me.select.id)
                .then(v => set_job_only(v))

            isProfile(me.select.id).then( v => {
                setProfile(v)
            })


        }
    })

    return (
        <div style={{ marginTop: 70 }}>
            <GridF3x1 container direction="row" mr={5} mt={10}>
                <Grid
                    style={{
                        backgroundColor: 'var(--bazookka-darkblue)',
                        boxShadow: 'var(--bazookka-box-shadow)',
                        borderRadius: 10,
                        height: 'fit-content',
                        color: 'var(--bazookka-blue)',
                        overflow: 'hidden'
                    }}
                    sx={{ display: { xs: 'none', xl: 'block', md: 'block' } }}
                >
                    <CoverBG me={me} edit={true} nav={true} />
                    {me && <InnerMenu me={me} t={t} />}
                </Grid>
                <Grid
                    sx={{ display: { xs: 'block', xl: 'block', md: 'block' } }}
                    style={{
                        padding: '0rem',
                        borderRadius: 10,
                        overflow: 'hidden',

                    }}
                >
                    {/*<Grid item >*/}
                    {/*    <CoverBG me={me} edit={true} />*/}
                    {/*    <DeskTopMenu />*/}
                    {/*</Grid>*/}

                    <Grid item >
                        {/*<BreadCrumb/>*/}
                    </Grid>

                    <Grid item>
                        <div className="flex w-[100%]">
                            <Route path="/me" component={DashboardComponent} exact={true}></Route>
                            {/*<Route path="/me/posts" component={Feeds} exact={true}></Route>*/}
                            <Route path="/me/manage-jobs"
                                component={SubCompanySection}
                            />
                            <Route path={'/me/documents'} component={Documents} exact={true} />
                            <Route
                                path="/me/jobs-internships"
                                component={() => <JobPostFullWidth />}
                            />
                            <Route
                                path="/me/logs"
                                component={() => <Userlogs />}
                            />
                            <Route path="/me/job-offers"
                                component={() => <JobPostFullWidth type="jobs" />}
                            />
                            <Route path="/me/internships"
                                component={() => <JobPostFullWidth type="internships" />}
                            />
                            {/* <Route path="/me/bookmarks"
                                component={() => <JobPostFullWidth type="bookmarks" />}
                            /> */}
                            <ScrollToTop>
                                <Switch>
                                    <Route path={`/me/settings`} component={UserProfileSettings} ></Route>
                                    <Route path={`/me/nomatch`} component={NoMatch} ></Route>
                                    <Route path={`/me/my-internships`} exact component={InternshipFullWidthCard} ></Route>
                                    <Route path={`/me/my-internships-groups`} exact component={InternshipFullWidthCard} ></Route>
                                    <Route path={`/me/my-internships-groups/:slug`} exact component={InternshipFullWidthCard} ></Route>
                                    <Route path={`/me/my-internships/create`} component={() => <EditInternshipObjectForm />} ></Route>
                                    <Route path={`/me/my-internships/edit/:slug`} component={() => <EditInternshipObjectForm />} ></Route>
                                    <Route path={`/me/my-internships/edit-group/:slug`} component={() => <EditInternshipObjectForm />} ></Route>
                                    <Route path={`/me/io`} component={() => <InternshipsObject />} ></Route>
                                    <Route path={`/me/calendar`} component={CalendarPage} ></Route>
                                    <Route path={`/me/events`} component={SubCompanyEvent} ></Route>
                                    {/* <Route path={`/me/myforms`} component={FormUserDashboard} ></Route> */}
                                    <Route path={`/me/users_view`} component={UsersView} ></Route>
                                    <Route path={`/me/invites`} component={InviteUsers} ></Route>
                                    <Route path={`/me/mentors_view`} component={MentorView} ></Route>
                                    <Route path={`/me/rates`} component={RatesRoutes} ></Route>
                                    <Route path={`/me/forms`} component={MyForms} ></Route>
                                    <Route path="/settings/applications" component={Applications} exact></Route>
                                    <Route path="/settings/jobs" component={JobCrud} exact></Route>
                                    <Route path="/settings/internships" component={InternshipCrud} exact></Route>
                                    <Route path="/settings/events" component={EventCrud} exact></Route>
                                    <Route path="/settings/applications/:userId" component={UserProfilePage}></Route>
                                    <Route path="/settings/Manage" component={() => <ManageCompany />}></Route>
                                    <Route path="/settings/invite" component={InviteUsers}></Route>
                                    <Route path={`/settings/users`} component={UsersPage} ></Route>
                                    <Route path={`/settings/groups`} component={ClassRoom}></Route>
                                    <Route path={`/settings/formdashboard`} component={FormDashboard}></Route>
                                    <Route path={`/settings/convtemplates`} component={DisplayCovTemplateList} exact></Route>
                                    <Route path={`/settings/edit`} component={BusinessProfileSetting} exact></Route>
                                    <Route path={`/me/migration/settings/groups`} component={ClassRoomMigration} exact></Route>
                                    {/* <Route path={`/settings/externals`} component={ExternalApis} exact></Route> */}
                                    <Route path={`/settings`} component={() => <BusinessMenu me={me} />} exact></Route>

                                </Switch>
                            </ScrollToTop>
                        </div>
                    </Grid>

                </Grid>

                <Grid
                    sx={{ display: { xs: 'none', xl: 'none', md: 'none' } }}
                    style={{ background: 'url(assets/old/wallpaper.png)', margin: 0, width: '100%' }}
                >
                </Grid>
            </GridF3x1>
        </div>
    )
}


export default MainProfile
