// collor : bg-orange-600 border

import { useTranslation } from "react-i18next"

const Stats = ({count,boxColor, displayCondition, countColor, title, symbol, symbolColor, icon}) => {
    const {t} = useTranslation()
    console.log(`${countColor} text-3xl`)
    return (
        displayCondition ? <div className="w-full md:w-1/2 xl:w-1/2 pt-3 px-3 md:pr-2 xl:pr-3 xl:pl-1">
        <div className={`${boxColor} rounded shadow p-2`}>
            <div className="flex flex-row items-center">
                <div className="flex-shrink pl-1 pr-4"><i
                    className={`fas ${icon} fa-2x fa-fw fa-inverse`}></i></div>
                <div className="flex-1 text-right pr-1">
                    <h5 className="text-white ">{title}</h5>
                    <h3 className={`${countColor} text-3xl`}>
                        { count }
                        <span className={symbolColor}><i
                        className={`fas ${symbol} pl-1`}></i></span></h3>
                </div>
            </div>
        </div>
        </div> : ""
    )
}

export default Stats