import React, {useContext, useEffect, useState} from "react";
import {MyContext} from "../../stores";
import {useParams} from "react-router-dom";
import Swal from "sweetalert2";
import {db} from "../../firestore/firestoreService";
import {formatDistance} from "date-fns";
import {PencilLineIco} from "../../ico";
import { useTranslation } from "react-i18next";
import axios from "axios";

const ListEduJob = ({mutateUserInfo, data, page}) => {
    let {dispatchModal, globalId,globalIdSql, me, langChoice, locales} = useContext(MyContext)
    const [locale, setLocale] = useState(locales.enCA)
    const {t} = useTranslation()

    useEffect(()=> {
        if(langChoice === 'fr') {
            setLocale(locales.frCA)
        }else {
            setLocale(locales.enCA)
        }
    }, [langChoice])
    const onDelete = async (data, idx) => {
        Swal.fire({
            title: page === 'educations' ? t("education.askDelete") : t("experience.askDelete"),
            showDenyButton: true,
            confirmButtonText: t("general.delete"),
            denyButtonText: t("general.job.deleteDecline"),
        }).then(async (result) =>  {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if(page === "educations"){
                    const educations = data.educations
                    educations.splice(idx, 1)
                    data.educations = educations
                }else{
                    const experiences = data.experiences
                    data.experiences.splice(idx, 1)
                    data.experiences = experiences
                }
                data.eid = globalId
                await axios.put(`https://api.bazookka.com/users/${globalIdSql}/info`,data)
                mutateUserInfo()

            } else if (result.isDenied) {
                Swal.fire(page === 'educations' ? t("education.itemDeletetionCanceled") : t("experience.itemDeletetionCanceled") , '', 'info')
            }
        })
        mutateUserInfo()
    }

    const editEntries = (e, i) => {
        dispatchModal({type: 'OPEN_MODAL', payload: {
            modalType: 'EducationModal',
            modalProps:
                {
                    page,
                    title: page === 'educations' ? t("general.editeducationTitle") : t("general.editExperienceTitle"),
                    data : data,
                    cursor: i,
                    mutateUserInfo: mutateUserInfo,
                    edit: true,
                    closeOut: () => dispatchModal({type: 'CLOSE_MODAL'})}}})

    }
    
    const getCorrectData = () => {
        return page === "experiences" ? data.experiences : data.educations
    }
 
    return (
        <div className="columns true-dom">
            <div className="column">
                <div className="question-content">
                    { data && Array.isArray(getCorrectData()) && getCorrectData().map( (e, i) => {
                        return (
                            <div className="question-block is-top-spaced" key={i}>
                                {/*<!-- Title -->*/}
                                <h2 className="question-title is-link">{e.title}</h2>
                                {/*<!-- Author -->*/}
                                <div className="question-author">
                                    <img src="https://via.placeholder.com/150x150" data-demo-src="assets/img/avatars/brian.jpg"
                                         data-user-popover="19" alt="" />
                                    <div className="meta">
                                        <span>{e.name}</span>
                                        <span>{formatDistance(
                                            new Date(e.startDate).getTime(),
                                            new Date().getTime(),
                                            {
                                                addSuffix: true,
                                                locale: locales.frCA
                                            }
                                        )}</span>
                                    </div>
                                </div>

                                <div className="question-footer">
                                    <div className="likes">
                                        <div className="like-icon" onClick={() => editEntries(e, i)} style={{cursor: 'pointer'}}>
                                            <PencilLineIco />
                                        </div>
                                        <div style={{width: 20}}></div>
                                        <div className="like-icon" style={{cursor: 'pointer'}} onClick={()=> onDelete(data,i)}>
                                            <i className="mdi mdi-delete-forever" style={{color: 'white'}}></i>
                                        </div>
                                    </div>

                                </div>
                                <div className="answers-count">

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default ListEduJob
