import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Selection } from "@syncfusion/ej2-react-grids"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { SectionHeader } from "../../pages/Dashboard"
import { MyContext } from "../../stores"

const LeaderBoard = ({title,category, dataSource, pageCount,selectionsettings,enableHover, columnsDefinition  }) => {

    const {setI10nLoadContent, langChoice} = useContext(MyContext)
    useEffect(() => {
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun étudiant á charger`,
                        'Item': 'étudiant',
                        'Items': 'étudiants',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} étudiants)',
                        'totalItemInfo': '({0} étudiant)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no students to load`,
                        'Item': 'student',
                        'Items': 'students',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} students)',
                        'totalItemInfo': '({0} student)'
                    }
                }
            }
        )

    },[])

    const {t} = useTranslation()
    return (<div className="w-1/2">
    <SectionHeader title={t(title)} category={category} />
    <GridComponent
        dataSource={dataSource}
        enableHover={enableHover}
        pageSettings={{ pageCount: pageCount }}
        selectionSettings={selectionsettings}
        locale={langChoice === "fr" ? "fr-FR" : "en-US"}
    >
        <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {columnsDefinition.map((item, index) => <ColumnDirective headerText={item.headerText} key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Selection]} />
    </GridComponent>
</div>
    )
}

export default LeaderBoard