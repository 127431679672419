import React, { useState, useContext, useEffect, useRef } from "react";
import { useField, useFormikContext } from "formik";
import Select from "react-select";
import Avatar from "react-avatar";
import {MyContext} from "../stores";
import styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import enCA from "date-fns/locale/en-CA";
import TextareaAutosize from 'react-textarea-autosize'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {RichTextEditorSimple} from "./RichTextEditor";
import 'react-datepicker/dist/react-datepicker.min.css'
import AdvanceInputText from "./AdvanceInputText";
import { useTranslation } from "react-i18next";

const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: var(--red-600);
  width: 400px;
  margin-top: 0.25rem;
  margin-bottom: 0.4rem;
  &:before {
    content: "❌ ";
    font-size: 10px;
    margin-right: 0.2rem;
  }
  @media (prefers-color-scheme: dark) {
    color: var(--red-300);
  }
`;

const StyledLabel = styled.label`
  margin-top: 1rem;
`;

// Styled components ....
const StyledSelect = styled.select`
  color: var(--blue);
  background-color: var(--gray-100);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.304 125.304"><path d="M62.652 103.895L0 21.41h125.304" fill="%23343334"/></svg>');
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: center right 0.5rem;
  @media (prefers-color-scheme: dark) {
    background-color: var(--gray-800);
  }
`;


export const NiceInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
      <div className="field field-group">
        <label htmlFor={props.id || props.name}>{label}</label>
        <div className={`control ${props.ico && "has-icon"}`}>
          <input className="input is-fade" {...field} {...props} />
          {props.ico && <div className="form-icon">{props.ico}</div>}
          {meta.touched && meta.error ? (
              <StyledErrorMessage>{meta.error}</StyledErrorMessage>
          ) : null}
        </div>
      </div>
  );
};

export const InputText = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return (
        <div className="field field-group">
            <label>{label}</label>
            <div className={`control ${props.ico && "has-icon"}`}>
                <input type="text" className="input is-fade" {...field} {...props} />
                {props.ico && (
                    <div className="form-icon">
                        {props.ico}
                    </div>
                )}

            </div>
        </div>
    )
}

export const RichFormikText = ({label, ...props}) => {
    const [field, meta] = useField(props)
    const {setFieldValue} = useFormikContext()

    useEffect(()=> {
    }, [field])

    const onEditorStateChange = (e) => {
        setFieldValue(field.name, e)
    }
    return (
        <AdvanceInputText
            stateData={props.value}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onContentStateChange={onEditorStateChange}
        />
    )
};

export const InputEmail = ({label, ...props}) => {
    const [field, meta] = useField(props)
    return (
        <div className="field field-group">
            <label>{label}</label>
            <div className={`control ${props.ico && "has-icon"}`}>
                <input type="email" className="input is-fade" {...field} {...props} />
                {props.ico && (
                    <div className="form-icon">
                        {props.ico}
                    </div>
                )}

            </div>
        </div>
    )

};

export const InputDate = ({label, ...props}) => {
  const [field, meta] = useField(props)
  console.log("meta ",meta)
  const {setFieldValue} = useFormikContext()
  useEffect(() => {
    registerLocale("en-CA", enCA);
  }, []);
  return (
      <div className="field field-group">
        <label>{label}</label>
        <div className={`control ${props.ico && "has-icon"}`}>
          <DatePicker
              {...field}
              {...props}
              autoComplete="off"
              selected={(field.value && new Date(field.value)) || new Date()}
              onChange={value => {
                setFieldValue(field.name, value)
                if(props.extraChange){
                  props.extraChange(value);
                }
              }
              }
          ></DatePicker>
          {props.ico && (
              <div className="form-icon">
                {props.ico}
              </div>
          )}
        </div>
      </div>
  )
};


export const NiceMultiSelect = ({ label, index, ...props }) => {
  const [field, meta] = useField(props);

  const channel = {
    display: "flex",
    alignItems: "center",
    padding: "6px 8px",
    borderRadius: 4,
    color: "#4a4a4a !important",
    backgroundColor: "#f5f6f7",
  };

  const channelIcon = {
    display: "flex",
    justifyContent: "center",
    height: 40,
    width: 40,
    alignItems: "center",
    borderRadius: "50%",
    border: "1px solid #f5f6f7",
    margin: "0px 10px",
    backgroundColor: "white",
  };

  const channelName = {
    fontSize: ".85rem",
    fontWeight: "500",
    color: "#393a4f",
  };

  const channelList = {
    marginLeft: "auto",
  };
  useEffect(() => {}, []);
  return (
    <div className="channel" style={channel}>
      <div className="round-checkbox is-small">
        <div>
          <input
            id={`checkbox-${index}`}
            type="checkbox"
            {...field}
            {...props}
          />
          <label htmlFor={`checkbox-${index}`}></label>
        </div>
      </div>
      <div className="channel-icon" style={channelIcon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-bell"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
      </div>
      <div className="channel-name" style={channelName}>
        {props.data.name}
      </div>
      <div
        className="dropdown is-spaced is-modern is-right is-neutral dropdown-trigger"
        style={channelList}
      >
        <div>
          <button
            className="button"
            aria-haspopup="true"
            style={{ lineHeight: 0, height: 36, padding: 6 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-smile main-icon"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
              <line x1="9" y1="9" x2="9.01" y2="9"></line>
              <line x1="15" y1="9" x2="15.01" y2="9"></line>
            </svg>
            <span style={{ margin: "0 4px" }}>Students</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-down caret"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </button>
        </div>
        <div className="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a href="#" className="dropdown-item">
              <div className="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-globe"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <line x1="2" y1="12" x2="22" y2="12"></line>
                  <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                </svg>
                <div className="media-content">
                  <h3>Public</h3>
                  <small>Anyone can see this publication.</small>
                </div>
              </div>
            </a>
            <a className="dropdown-item">
              <div className="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-users"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
                <div className="media-content">
                  <h3>Friends</h3>
                  <small>only friends can see this publication.</small>
                </div>
              </div>
            </a>
            <a className="dropdown-item">
              <div className="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-user"
                >
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <div className="media-content">
                  <h3>Specific friends</h3>
                  <small>Don't show it to some friends.</small>
                </div>
              </div>
            </a>
            <hr className="dropdown-divider" />
            <a className="dropdown-item">
              <div className="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-lock"
                >
                  <rect
                    x="3"
                    y="11"
                    width="18"
                    height="11"
                    rx="2"
                    ry="2"
                  ></rect>
                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                </svg>
                <div className="media-content">
                  <h3>Only me</h3>
                  <small>Only me can see this publication.</small>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ComposeText = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { me } = useContext(MyContext);
  const {t} = useTranslation()
  // console.log(me)
  useEffect(() => {}, [me]);
  const image = {
    height: 42,
    width: 42,
    borderRadius: "50%",
  };

  const control = {
    width: "100%",
    marginLeft: 40,
  };
  const composeform = {
    display: "flex",
  };

  const textarea = {
    padding: 0,
    borderColor: "transparent",
    resize: "none",
  };

  return (
    <div className="compose-card">
      <div className="tabs-wrapper">
        <div className="tab-content">
          <div className="compose">
            <div className="compose-form" style={composeform}>
              <Avatar
                size="42"
                name={me && me.displayName}
                round={true}
                textSizeRatio={0.1}
              />
              <div className="control" style={control}>
                <textarea
                  style={textarea}
                  id="publish"
                  className="textarea"
                  rows="3"
                  placeholder={t("message.contentPlaceHolder")}
                  {...field}
                  {...props}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SwitchBox = ({ label, onClick, ...props }) => {

  const [field, meta] = useField(props);
  
  const [checked, setChecked] = useState();
  return (
    <div className="field field-group">
      <label className="f-switch">
        <div>{label}</div>
        <input
          type="checkbox"
          className="is-switch"
          {...field}
          {...props}
          checked={field.value}
          onClick={onClick ? onClick : null}
        />
        <i></i>
      </label>
    </div>
  );
};

export const TextAreaInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="field field-group">
      <label>{label}</label>
      <div className={`control ${props.ico && "has-icon"}`}>
        <TextareaAutosize className="input is-fade" {...field} {...props} />
        {props.ico && <div className="form-icon">{props.ico}</div>}
      </div>
    </div>
  );
};

export const NiceSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="field field-group">
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <div className={`control ${props.ico && "has-icon"}`}>
        <StyledSelect className="input is-f ade" {...field} {...props} />
        {props.ico && <div className="form-icon">{props.ico}</div>}
        {meta.touched && meta.error ? (
          <StyledErrorMessage>{meta.error}</StyledErrorMessage>
        ) : null}
      </div>
    </div>
  );
};

export const NiceCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <div className="field field-group">
      <label className="checkbox">
        <input {...field} {...props} type="checkbox" />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </div>
  );
};

export const CustomSelect = ({
  placeholder,
  field,
  form,
  options,
  isMulti = false,
}) => {
  const onChange = (options) => {
    form.setFieldValue(
      field.name,
      isMulti ? options.map((option) => option.value) : options.value
    );
  };
  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value && field.value.includes(option.value))
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };
  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
    />
  );
};
