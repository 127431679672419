import React, { useState } from 'react';
import {AvatarGroup} from "@mui/material";
import { Create as CreateIcon } from "@mui/icons-material";
import Avatar from 'react-avatar';
import { Multiselect } from 'multiselect-react-dropdown';
import { useTranslation } from "react-i18next";
import moment from 'moment'

export default function EditableNote({noteId, avatar, displayName, message, createdAt, sharedWith, editable=false, shareableUsers, onSubmit}){
    const { t } = useTranslation()
    const [editMode, setEditMode] = useState(false)
    const [select, setSelect] = useState([])
    const [text, setText] = useState([])

    const onEditClick = () => {
        setText(message);
        if(sharedWith){
            setSelect(
                sharedWith.map(sharedUser => {
                    return {name: sharedUser.display_name, value: sharedUser.id}
                })
            )
        }
        setEditMode(true);
    }

    const onChange = (e) => {
        setText(e.target.value)
    }

    const handleSubmit = () => {
        onSubmit({id: noteId, text, oldSelect: sharedWith, select})
        setEditMode(false);
    }

    if(editMode){
        return(
            <div className="post-compose">
                <div className="control">
                    <textarea className="textarea" placeholder={`${t('general.typenote')}...`} onChange={onChange} value={text}></textarea>
                </div>
                <div className="compose-controls">
                    {avatar ?
                        <Avatar src={avatar} round={true} size={40} />
                        :
                        <Avatar name={displayName} round={true} size={40} />
                    }
                        <div className="shareSubmitContainer">
                            <Multiselect
                                name="shareId"
                                options={shareableUsers}
                                selectedValues={select}
                                onSelect={(v)=> setSelect(v)}
                                onRemove={(v)=> setSelect(v)}
                                displayValue="name"
                                placeholder={t('customForm.shareWith')+'...'}
                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                            />
                            <a className="button is-solid accent-button raised" onClick={handleSubmit}>{t('general.savenote')}</a>
                            <a className="button is-solid raised" onClick={() => setEditMode(false)}>{t('general.cancel')}</a>
                        </div>
                </div>
            </div>
        )
    }else{
        return (
            <React.Fragment>
                <div className="media is-comment">
                    <div className="media-left">
                        <div className="avatar-wrap is-smaller">
                            {avatar ? <Avatar src={avatar} round={true} size={40} /> : <Avatar name={avatar} round={true} size={40} />}
                            <div className="badge">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                    className="feather feather-check">
                                    <polyline points="20 6 9 17 4 12"></polyline>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="media-content">
                        <div className="comment-meta">
                            <h4>
                                <a>{displayName}</a> 
                                <small> · {moment(createdAt).format('LLLL')}</small>
                                {editable && 
                                    <div className='editButton' onClick={() => onEditClick()}>
                                        <CreateIcon />
                                    </div>
                                }
                            </h4>
                            {message}
                        </div>
                    </div>
                </div>
                {sharedWith &&
                    <div className='sharedTo'>
                        <div className="avatar-wrap is-smaller">
                            <AvatarGroup>
                                <span className='sharedWithTitle'>{t('customForm.shareWith')}</span>
                                {sharedWith.map((sharedUser, i) => {
                                    return sharedUser.avatar ? <Avatar key={i} src={sharedUser.avatar} title={sharedUser.display_name} round={true} size={40} /> : <Avatar key={i} name={sharedUser.display_name} title={sharedUser.display_name} round={true} size={40} />
                                })}
                            </AvatarGroup>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}