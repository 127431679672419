import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu'
import { Link } from 'react-router-dom';
import { ChatMessageIco, CheckIco, SettingsIco, LogoutIco } from "../ico"
import Avatar from 'react-avatar';
import { useContext, useState, useEffect } from 'react';
import { MyContext } from "../stores";
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from '@mui/material';
import { isAdmin } from "../helper/acl";
import NotificationList from './NotificationList';
import { usersDoc } from "../firestore/firestoreService";
import styled2 from 'styled-components'
import AppsIcon from '@mui/icons-material/Apps';
import ChatIcon from '@mui/icons-material/Chat';
import TemporaryDrawer from "./MenuDrawer.jsx";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginLeft: "1em",
  color: "black",
  backgroundColor: "#F7F7F7",
  '&:hover': {
    backgroundColor: "white",
  },
  marginRight: theme.spacing(2),
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: theme.spacing(10),
    width: '50vh',
  },

  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: theme.spacing(2),
    width: '25vh',
  },

  [theme.breakpoints.down('sm')]: {
    marginLeft: theme.spacing(2),
    width: '25vh',
  },

}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50vh',
    },
  },
}));

export const NavBarLogoutStyle = styled2.div`
  color: var(--bazookka-white);
  background-color: var(--bazookka-blue) !important;
  a {
    color: var(--bazookka-white) !important;
  }
  .navbar-item a {
   // background: var(--bazookka-blue) !important;
  }
  .navbar-dropdown a {
    background: var(--bazookka-white) !important;
    color: var(--bazookka-blue) !important;
  }
  a.button.is-light {
    background-color: var(--bazookka-white) !important;
    color: var(--bazookka-blue) !important;
  }
`

const Logo = styled2.div`
  height:60px;
  width:200px;
  @media (max-width: 768px) {
    background: url(/assets/old/ico.png) no-repeat center;
    background-size:contain;
  }
  background: url(/assets/old/logo-bazookka-cropped.png) no-repeat center;
  background-size:contain;
`

const IconButton = styled2.div`
  height:1.2em;
  padding:0 8px;
  margin-right: 0.9em;
  font-size:25px;
  cursor:pointer;
  border-radius:20px;
  width: 1.1em

  &:hover{
    background-color:rgba(0, 0, 0, 0.4);;
  }

  svg{
    vertical-align:middle;
  }
`

const FakeShadow = styled2.div`
    background-color:black;
    opacity:0.2;
    position:absolute;
    bottom:-2px;
    right:-120px;
    min-width:350px;
    height:2px;
    z-index:10;
`
export const NavBar = (props) => {

  return (
    <NavBarLogoutStyle
      id="main-navbar"
      className="navbar is-inline-flex is-transparent"
    >
      {/* <Link className="" to="/">
          <Logo />
        </Link> */}
      <div style={{ width: "50px" }} >

        <a href="/" style={{ display: "block", width: "50px" }} role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
          data-target="navbarBasicExample">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link className="button is-light" to="/">
                Log in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </NavBarLogoutStyle>
  )
}

export const FullNavBar = ({ }) => {
  const { me, verifyAuth } = useContext(MyContext)
  const [toggle, setToggle] = React.useState(false);

  const toggleDrawer = (_, bool) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setToggle(bool);
  };
  useEffect(() => {
    verifyAuth();
  }, [])

  return (
    <React.Fragment>
      {me && <PrimarySearchAppBar toggleDrawer={toggleDrawer} toggle={toggle} />}
      {me && <TemporaryDrawer toggleDrawer={toggleDrawer} toggle={toggle} me={me} />}
    </React.Fragment>
  )
};

export default function PrimarySearchAppBar({ toggle, toggleDrawer }) {
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = useState(null)
  const [mobileMoreLanguageAnchorEl, setMobileMoreLanguageAnchorEl] = useState(null);
  const { me, langChoice, langLabels, updateStateLanguage, setAllFalse, logout, globalId, handlePredicate, notifications, isMobileView, isDrawerOpen, setIsDrawerOpen } = useContext(MyContext)
  const isMenuOpen = Boolean(anchorElProfile);
  const isLanguageMenuOpen = Boolean(anchorElLanguage);
  const isNotificationMenuOpen = Boolean(notificationMenuAnchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [userRole, setUserRole] = useState(null);
  const [showNotifications, setShowNotifications] = useState(false)
  const unreadNotifClass = me && me.unreadNotif ? 'unread' : '';

  const toggleNotifications = evt => {
    setShowNotifications(!showNotifications);
    if (me && me.unreadNotif) {
      usersDoc.doc(globalId).update({ unreadNotif: false });
    }
  }

  useEffect(() => {
    if (me && me.select) {
      isAdmin(me.select.id).then(v => {
        setUserRole(v);
      })
    }
  }, [me])

  const onLogout = async evt => {
    evt.preventDefault();
    setAllFalse()
    await logout(history)
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleLanguageMenuOpen = (event) => {
    setAnchorElLanguage(event.currentTarget);
  }

  const handleNotificationMenuOpen = (event) => {
    setNotificationMenuAnchorEl(event.currentTarget)
    toggleNotifications(event)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileLanguageMenuClose = () => {
    setMobileMoreLanguageAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorElProfile(null);
    handleMobileMenuClose();
  };

  const handleLanguageMenuClose = () => {
    setAnchorElLanguage(null)
    handleMobileLanguageMenuClose()
  }

  const handleNotificationMenuClose = (e) => {
    setNotificationMenuAnchorEl(null);
    toggleNotifications(e)
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const HandleMain = e => {
    e.preventDefault()
    setAllFalse()
    handlePredicate('bigdrop', [
      ['notifications', false],
      ['lang', false],
      ['profile', false],
      ['bigdrop', true]
    ])
  }

  const menuId = 'primary-search-account-menu';
  const renderProfileMenu = ({ me }) => {
    return (
      <Menu
        anchorEl={anchorElProfile}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >

        <div className="inner">
          <div className="nav-drop-body account-items">
            <MenuItem onClick={handleMenuClose}>
              <a id="profile-link" className="account-item">
                <div className="media">
                  <div className="media-left">
                    <div className="image">
                      <Button>
                        {me.avatar
                          ? <Avatar
                            round={true}
                            size={40}
                            src={me.avatar}
                          /> :
                          <Avatar
                            round={true}
                            size={40}
                            name={me.displayName}
                          />}
                      </Button>
                    </div>
                  </div>
                  <div className="media-content">
                    <h3>{me ? me.displayName : ""}</h3>
                    <small>
                      <Trans i18nKey="general.mainaccount">
                        Main account
                      </Trans>
                    </small>
                  </div>
                  <div className="media-right">
                    <CheckIco />
                  </div>
                </div>
              </a>

              {me &&
                me.profiles &&
                me.profiles.map((p, key) => {
                  return (
                    <a key={key} id="profile-link">
                      <div className="media">
                        <div className="media-left">
                          <div className="image">
                            <img
                              src="https://via.placeholder.com/400x400"
                              data-demo-src="assets/img/avatars/jenna.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="media-content">
                          <h3>{p.name}</h3>
                          <small>
                            <Trans i18nKey="general.mainaccount">
                              Main account
                            </Trans>
                          </small>
                        </div>
                        <div
                          className="media-right"
                          style={{
                            display: p.selected ? "block" : "none",
                          }}
                        >
                          <CheckIco />
                        </div>
                      </div>
                    </a>
                  );
                })
              }
            </MenuItem>

            <hr className="account-divider" />
            <MenuItem onClick={handleMenuClose}>
              <Link to={`/me/settings`} className="account-item">
                <div className="media">
                  <div className="">
                    <Box mr={5} >
                      <SettingsIco />
                    </Box>
                  </div>
                  <div className="media-content">
                    <h3>
                      <Trans i18nKey="general.settings">
                        Settings
                      </Trans>
                    </h3>
                    <small>
                      <Trans i18nKey="general.widgetsettings">
                        Access widget settings.
                      </Trans>
                    </small>
                  </div>
                </div>
              </Link>
            </MenuItem>

            <hr className="account-divider" />

            <MenuItem onClick={handleMenuClose}>
              <a className="account-item" onClick={onLogout}>
                <div className="media">
                  <Box mr={3}>
                    <LogoutIco />
                  </Box>
                  <div className="media-content">
                    <h3>
                      <Trans i18nKey="general.logout">Logout</Trans>
                    </h3>
                    <small>
                      <Trans i18nKey="general.logoutaccount">
                        Log out from your account
                      </Trans>
                    </small>
                  </div>
                </div>
              </a>
            </MenuItem>
          </div>
        </div>
      </Menu>
    )
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileProfileMenu = ({ me }) => {
    return (<Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <div className="inner">
        <div className="nav-drop-body account-items">
          <MenuItem onClick={handleMenuClose}>
            <a id="profile-link" className="account-item">
              <div className="media">
                <div className="media-left">
                  <div className="image">
                    <Button>
                      {me.avatar ?
                        <Avatar
                          round={true}
                          size={40}
                          src={me.avatar}
                        /> :
                        <Avatar
                          round={true}
                          size={40}
                          name={me.displayName}
                        />}
                    </Button>
                  </div>
                </div>
                <div className="media-content">
                  <h3>{me ? me.displayName : ""}</h3>
                  <small>
                    <Trans i18nKey="general.mainaccount">
                      Main account
                    </Trans>
                  </small>
                </div>
                <div className="media-right">
                  <CheckIco />
                </div>
              </div>
            </a>

            {me &&
              me.profiles &&
              me.profiles.map((p, key) => {
                return (
                  <a key={key} id="profile-link">
                    <div className="media">
                      <div className="media-left">
                        <div className="image">
                          <img
                            src="https://via.placeholder.com/400x400"
                            data-demo-src="assets/img/avatars/jenna.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="media-content">
                        <h3>{p.name}</h3>
                        <small>
                          <Trans i18nKey="general.mainaccount">
                            Main account
                          </Trans>
                        </small>
                      </div>
                      <div
                        className="media-right"
                        style={{
                          display: p.selected ? "block" : "none",
                        }}
                      >
                        <CheckIco />
                      </div>
                    </div>
                  </a>
                );
              })
            }
          </MenuItem>

          <hr className="account-divider" />
          <MenuItem onClick={handleMenuClose}>
            <Link to={`/me/settings`} className="account-item">
              <div className="media">
                <div className="">
                  <Box mr={5} >
                    <SettingsIco />
                  </Box>
                </div>
                <div className="media-content">
                  <h3>
                    <Trans i18nKey="general.settings">
                      Settings
                    </Trans>
                  </h3>
                  <small>
                    <Trans i18nKey="general.widgetsettings">
                      Access widget settings.
                    </Trans>
                  </small>
                </div>
              </div>
            </Link>
          </MenuItem>

          <hr className="account-divider" />

          <MenuItem onClick={handleMenuClose}>
            <a className="account-item" onClick={onLogout}>
              <div className="media">
                <Box mr={3}>
                  <LogoutIco />
                </Box>
                <div className="media-content">
                  <h3>
                    <Trans i18nKey="general.logout">Logout</Trans>
                  </h3>
                  <small>
                    <Trans i18nKey="general.logoutaccount">
                      Log out from your account
                    </Trans>
                  </small>
                </div>
              </div>
            </a>
          </MenuItem>

           {/* temporary remove */}
           
          {/* <MenuItem sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <Box
              sx={{
                display: 'flex'
              }}
            >
              <Box mr={2} bgcolor="#EB5264"
                borderRadius="10%"
                alignItems='center' textAlign='center'
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  width: "2em", height: "2em", '&:hover': {
                    backgroundColor: '#18d100',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}>
                <Link style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }} to={`/chat`}>

                  <ChatIcon />


                  <span className="indicator"></span>
                </Link>
              </Box>

              <Box mr={2} bgcolor="#EB5264"
                borderRadius="10%"
                alignItems='center' textAlign='center'
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  width: "2em", height: "2em", '&:hover': {
                    backgroundColor: '#18d100',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              >
                <a className="icon-link is-primary" onClick={HandleMain} >
                  <AppsIcon />
                </a>
              </Box>
              
              {userRole &&
                <Box bgcolor="#EB5264"
                  borderRadius="10%"
                  alignItems='center' textAlign='center'
                  sx={{
                    display: { sm: 'flex', xs: 'flex', xl: 'flex', md: 'flex' },
                    justifyContent: "center",
                    alignItems: "center",
                    width: "2em", height: "2em",
                    '&:hover': {
                      backgroundColor: '#18d100',
                      opacity: [0.9, 0.8, 0.7],
                    }
                  }}
                >
                  <Link to={`/settings`} className="icon-link is-primary">
                    <i style={{ backgroundColor: '#3f88e3', fontSize: "1em" }} className="mdi mdi-office-building" />
                    <span className="indicator"></span>
                  </Link>
                </Box>
              }
            </Box>
          </MenuItem> */}

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              aria-label="show 18 new notifications"
              onClick={(e) => {
                toggleNotifications(e)
                handleNotificationMenuOpen(e)
              }}
            >
              <Badge badgeContent={notifications ? notifications.length : ""} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              {me.avatar ?
                <Avatar
                  round={true}
                  size={40}
                  src={me.avatar}
                /> :
                <Avatar
                  round={true}
                  size={40}
                  name={me.displayName}
                />
              }

            </IconButton>

            <IconButton onClick={handleLanguageMenuOpen}>
              <Avatar
                name={langLabels.find(label => label.small === langChoice).small}
                size="35"
                round={true}
              />
            </IconButton>

          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
            </IconButton>
          </Box>
        </div>

      </div>

    </Menu>
    )
  };
  const history = useHistory()
  const location = useLocation()
  const onTarget = (value) => {
    updateStateLanguage(value)
    history.push(location.pathname)
  }

  const renderLanguageMenu = ({ me }) => {
    return (
      <Menu
        anchorEl={anchorElLanguage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isLanguageMenuOpen}
        onClose={handleLanguageMenuClose}
      >

        <div className="inner">
          <MyContext.Consumer>
            {(value) =>
              value.langLabels.map((lang) => {
                return (
                  <MenuItem onClick={() => {
                    onTarget(lang.small)
                    handleLanguageMenuClose()
                  }
                  } key={lang.small}>
                    <a
                      id="profile-link"

                      className="account-item"
                      data={lang.small}
                    >
                      <div className="media">
                        <div className="media-left">
                          <div className="image">
                            <Avatar
                              name={lang.small}
                              size="35"
                              round={true}
                            />
                          </div>
                        </div>
                        <div className="media-content">
                          <h3>{lang.name}</h3>
                          <small></small>
                        </div>
                        {lang.select && (
                          <div className="media-right">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-check"
                            >
                              <polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                          </div>
                        )}
                      </div>
                    </a>
                    <hr className="account-divider" />
                  </MenuItem>
                );
              })
            }
          </MyContext.Consumer>
        </div>
      </Menu>
    )
  };

  const renderNotificationMenu = ({ me }) => {
    return (
      <Menu
        anchorEl={notificationMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}

        PaperProps={{
          style: {
            width: 550,
            height: "auto",
          },
        }}

        MenuListProps={{
          disablePadding: true,
          style: {
            width: "100%",
            height: "100%",
          }
        }}

        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isNotificationMenuOpen}
        onClose={handleNotificationMenuClose}
      >
        {globalId && <NotificationList closeNotfications={handleNotificationMenuClose} globalId={globalId} />}
      </Menu>
    )
  }

  return (
    <Box sx={{ flexGrow: 2 }}>
      <AppBar>
        <Toolbar>

          <Box sx={{ display: { xs: 'flex', sm: 'flex', xl: 'none', md: 'none' } }}>
            <IconButton onClick={e => toggleDrawer('left', !toggle)(e)}>
              <MenuIcon style={{ color: 'white' }} />
            </IconButton>
          </Box>

          <Box sx={{ marginLeft: "15px", justifyContent: "center", width: "20vw", display: { xs: 'flex', sm: 'flex', xl: 'flex', md: 'flex' } }}>

            <Link to="/">
              <Logo />
            </Link>
          </Box>

          {/* temporary remove*/}

          {/* <Box sx={{ display: { sm: 'none', xs: 'none', xl: 'flex', md: 'flex' } }}>
            <Link to={`/me`} className="icon-link is-primary">
              <IconButton>
                <i style={{ color: "white", fontSize: "1em" }} className="mdi mdi-home" />
              </IconButton>
            </Link>
          </Box>

          <Box sx={{ display: { sm: 'none', xs: 'none', xl: 'flex', md: 'flex' } }}>
            {userRole &&
              <Link to={`/settings`} className="icon-link is-primary">
                <IconButton>
                  <i style={{ color: "white", fontSize: "1em" }} className="mdi mdi-office-building" />
                </IconButton>
              </Link>
            }
          </Box>

          <Box sx={{ display: { sm: 'none', xs: 'none', xl: 'flex', md: 'flex' } }}>
            <Box>
              <a className="icon-link is-primary" onClick={HandleMain} >
                <IconButton>
                  <i style={{ color: "white", fontSize: "1em" }} className="mdi mdi-apps"></i>
                </IconButton>
              </a>
            </Box> */}

            {/* <Box>
              <Link to={`/chat`}>
                <IconButton>
                  <ChatMessageIco className="svg_icons" stroke={"white"} />
                  <span className="indicator"></span>
                </IconButton>
              </Link>
            </Box> *

          </Box>/}

          {/*Removing Search bar until we implement the search*/}
          {false &&
            <Box sx={{ display: { xs: 'none', sm: 'flex', md: "flex" } }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
            </Box>
          }
          <Box sx={{ flexGrow: 1 }} />
          {/* <IconButton
            size="large"
            aria-label={`show ${notifications ? notifications.length : "0"} new notifications`}
            color="inherit"
            onClick={(e) => {
              toggleNotifications(e)
              handleNotificationMenuOpen(e)

            }}
          >
            <Badge badgeContent={notifications ? notifications.length : ""} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
            >
              <Avatar
                round={true}
                size={40}
                src={me.avatar}
                name={me.displayName}
              />
            </IconButton>

          </Box>

          <Box>
            <IconButton onClick={handleLanguageMenuOpen}>
              <Avatar
                name={langLabels.find(label => label.small === langChoice).small}
                size="35"
                round={true}
              />
            </IconButton>

          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>
      {/* {renderNotificationMenu({ me })} */}
      {renderMobileProfileMenu({ me })}
      {renderProfileMenu({ me })}
      {renderLanguageMenu({ me })}
    </Box>
  );
}
