import React, {useState, useEffect, useContext} from "react";
import {MyContext} from "../../stores";
import styled from 'styled-components'
import {useParams, useHistory, useLocation}  from 'react-router-dom'
import {useTranslation} from "react-i18next";
import Answers from "./Answers";
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CenteredTabs from "./CenteredTabs";
import QuestionForm from "./QuestionForm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Preview from "./Preview";
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import InputBase from '@material-ui/core/InputBase'
import {v4 as uuid} from "uuid";
import {IconButton, FormGroup, InputAdornment} from "@material-ui/core";
import {Save as SaveIcon, BorderColorRounded as EditIcon, Share as SendIcon, Close as CloseIcon} from "@mui/icons-material";
import {GlobeIco} from "../../ico";
import useSWR from 'swr';
import bzkFetcher from "../../utils/bzkFetcher";

const ToolBarCus = styled(Toolbar)`
    & header.MuiAppBar-colorPrimary {
      background-color: var(--bazookka-blue) !important;
    }
`

const PushRight = styled.div`
    margin-left:auto;
`

const EditFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  z-index: 10000;
  background: var(--bazookka-default-bgcolor);
  min-height: 100vh;
  width: 100vw;
  max-height: fit-content;
  .navBar {
    display: grid !important;
    grid-template-columns: 70px 3fr minmax(50px, 100px) 20px;
    align-items: center;
    background: white;
    width: 100%;
    height: 90px;
    .name {
      background: var(--bazookka-blue) !important;
      color: white;
      border: none;
      height: 90%;
      font-size: 90%;
      width: fit-content;
    }
  }
  .main {
    margin-top: 2rem;
    max-width: 90vw;
    min-width: 320px;
    padding: 2rem;
    
  }
`

function allyProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function EditFormComponent(){
    const {slug} = useParams();
    const history = useHistory()
    const location = useLocation();
    const {t} = useTranslation()
    const {globalId, me, globalIdSql, organizationId, dispatchModal} = useContext(MyContext)
    const [name, setName] = useState(t('formBuilder.defaultTitle'))
    const [value, setValue] = useState(0)
    const [desc, setDesc] = useState('')
    const [questions, setQuestions] = useState([])
    const [form, setForm] = useState(null)
    const [type, setType] = useState('form.question')
    const [groups, setGroups] = useState([])
    const [formUsers, setFormUsers] = useState([])
    const [options, setOptions] = useState([])
    const [checked, setChecked] = useState(false)
    const [didExecuteApi, setDidExecuteApi] = useState(false)

    const useForm = (formId, userId, didExecuteApi) => {
        //Use Condition for the URL arg to make SWR wait for necessary data to do the request
        const {data, error} = useSWR(
            (!didExecuteApi && formId && userId)
                ? [`https://api.bazookka.com/forms/${formId}`, userId]
                : null
            , bzkFetcher
        );

        return {
            formData: data,
            formIsLoading: !error && !data,
            formIsError: error
        }
    }

    const useGroupBySchool = (schoolId, userId) => {
        const {data, error} = useSWR(
            (schoolId)
                ? [`https://api.bazookka.com/groups/school/${schoolId}`, userId]
                : null
            , bzkFetcher
        );

        return {
            groupData: data,
            groupIsLoading: !error && !data,
            groupIsError: error
        }
    }

    const useUserBySchool = (schoolId, userId) => {
        const {data, error} = useSWR(
            (schoolId)
                ? [`https://api.bazookka.com/users/school/${schoolId}`, userId]
                : null
            , bzkFetcher
        );

        return {
            userData: data,
            userIsLoading: !error && !data,
            userIsError: error
        }
    }

    async function onSave(){
        if(slug) {
            const {c, error} = fetch("https://api.bazookka.com/forms", {
                headers: {
                    'Content-Type': 'application/json',
                    'eid': globalId
                },
                method: 'put',
                body: JSON.stringify({
                    id:slug, 
                    title:name, 
                    content:questions, 
                    author_id: globalIdSql, 
                    tiny:desc, 
                    organisation_id:organizationId, 
                    type, 
                    meta: {signature: checked}
                })
            }).then(r => r.json());
        } else {
            const resp = await fetch("https://api.bazookka.com/forms", {
                headers: {
                    'Content-Type': 'application/json',
                    'eid': globalId
                },
                method: 'post',
                body: JSON.stringify({
                    title:name, 
                    content:questions, 
                    author_id: globalIdSql, 
                    tiny:desc, 
                    organisation_id:organizationId, 
                    type, 
                    meta: {signature: checked}
                })
            });
            let json = await resp.json();
            history.push(`/formcreator/${json.id}`)
        }
    }
    const handleChange = (e, v) => {
        if(v === 0){
            history.push(`/formcreator/edit/${slug}`);
        }else if(v === 1){
            history.push(`/formcreator/view/${slug}`);
        }else if(v === 2){
            history.push(`/formcreator/results/${slug}`);
        }
    }

    const delay =  () => {
        return new Promise( resolve => {
            setTimeout(()=> {
                resolve()
            }, 1000)
        })
    }

    const onShare = async () => {
        if(groups.length > 0 && formUsers.length > 0){
            await delay()
            dispatchModal({
                type: "OPEN_MODAL",
                payload: {
                    modalType: "FormInviteModal",
                    modalProps: {
                        title: t("formCreator.share"),
                        ico: <GlobeIco />,
                        template: form,
                        formUsers: formUsers,
                        options: options,
                        groups,
                        closeOut: () => dispatchModal({ type: "CLOSE_MODAL" }),
                    },
                },
            });
        }
    }

    const { groupData } = useGroupBySchool(organizationId, globalId);

    useEffect(()=>{
        if(groupData) {
            setGroups(groupData.map( doc => {
                return {id: doc.groupid, label: doc.name, value: doc.groupid}
            }))
        }       
    }, [groupData])

    const { userData } = useUserBySchool(organizationId, globalId);

    useEffect(()=>{
            if(userData) {
                setFormUsers(userData.map( doc => {
                    return {id: doc.id, ...doc}
                }))
                setOptions(userData.map( doc => {
                    return {id: doc.id, label: doc.display_name, value: doc.id}
                }));
            }
       
    }, [userData])

    useEffect(() => {
        if(location.pathname.includes('edit')){
            setValue(0);
        }else if(location.pathname.includes('view')){
            setValue(1);
        }else if(location.pathname.includes('results')){
            setValue(2);
        }
    }, [location])

    const { formData } = useForm(slug, globalId, didExecuteApi);
    
    useEffect(()=>{
        if(formData){
            setDidExecuteApi(true)
            setName(formData.title) //meta.name
            setQuestions(formData.content) //content
            setDesc(formData.tiny) //meta.desc
            setType(formData?.type) //meta.type
            setForm({id: formData.id, ...formData.meta})
            setChecked(formData.meta.signature || false)
        }
    }, [formData])

    return (
        <EditFormStyled>
            <div className="navBar navbar is-inline-flex is-transparent" id="main-navbar">
            </div>
            <AppBar>
                <ToolBarCus style={{backgroundColor: 'var(--bazookka-blue)' }}>
                    <IconButton>
                        <a className="" href="/">
                            <img src="/assets/old/ico.png" width="50" height="50" />
                        </a>
                    </IconButton>
                    <FormGroup>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Enter name"
                            style={{
                                border: '1px solid black',
                                paddingLeft: '10px',
                                backgroundColor: 'white'
                            }}
                            value={name}
                            onChange={ v => setName(v.target.value)}
                        />
                    </FormGroup>
                    <PushRight />
                    {slug && (
                        <IconButton onClick={onShare}>
                            <SendIcon style={{fill: 'white'}} ></SendIcon>
                        </IconButton>
                    )}

                    <IconButton onClick={onSave}>
                        <SaveIcon color="danger" style={{fill: 'white'}} />
                    </IconButton>
                    <IconButton onClick={ e => history.push(`/me/forms`)}>
                        <CloseIcon style={{fill: 'white'}}></CloseIcon>
                    </IconButton>

                </ToolBarCus>
            </AppBar>
            <Paper style={{width: '100%'}}>
                <Tabs
                    textColor="primary"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    centered
                >
                    <Tab label={t("formCreator.tabs.questions")} {...allyProps(0)}></Tab>
                    <Tab label={t("formCreator.tabs.preview")} {...allyProps(1)}></Tab>
                    <Tab label={t("formCreator.tabs.answers")}  {...allyProps(2)}></Tab>
                </Tabs>
            </Paper>

            <div className="main">
                <CenteredTabs value={value} index={0}>
                    <QuestionForm
                        desc={desc}
                        setDesc={setDesc}
                        type={type}
                        setType={setType}
                        checked={checked}
                        setChecked={setChecked}
                        name={name} questions={questions} setQuestions={setQuestions}/>
                </CenteredTabs>
                <CenteredTabs value={value} index={1}>
                    <Preview desc={desc}
                             setDesc={setDesc}
                             checked={checked}
                             type={type}
                             name={name} questions={questions} setQuestions={setQuestions}></Preview>
                </CenteredTabs>
                <CenteredTabs value={value} index={2}>
                    <Answers slug={slug} globalId={globalId} questions={questions} title={name}></Answers>
                </CenteredTabs>
            </div>
        </EditFormStyled>
    )
}
