import React, {
  useReducer,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import { MyContext } from "../stores";
import { CloseIco } from "../ico";
import DatePicker, { registerLocale } from 'react-datepicker';
import { Formik, Form, ErrorMessage, Field } from "formik";
import {
  InputText,
  SwitchBox,
  InputDate,
  ComposeText,
  NiceMultiSelect,
  TextAreaInput,
  NiceSelect,
  CustomSelect,
} from "../components/FormElements";
import RichTextEditor, { RichTextEditorFix } from "../components/RichTextEditor";
import { Multiselect } from "multiselect-react-dropdown";
import {
  db,
  firestore,
  bugReports,
  firebaseInstance,
  formElements,
  groupsDoc,
} from "../firestore/firestoreService";
import * as Yup from "yup";
import FileUpload from "../components/FileUpload";
import { initialValue } from "../components/DisplayContentFromSlate";
import AttachmentList from "../components/AttachmentList";
import ImageCrUpload from "../components/ImageCrUpload";
import EditMeetingModal from "./EditMeetingModal";
import EditJobModal from "./EditJobModal";
import EditEventModal from "./EditEventModal";
import EditGroupModal from "./EditGroupModal";
import EditUserRoleModal from "./EditUserRoleModal";
import StartConversationModal from "./StartConversationModal";
import UsersListModal from "./UsersListModal"
import PreviewUser from "./PreviewUser";
import styled from "styled-components";
import Loader from "../components/Loader";
import { Trans, useTranslation } from "react-i18next";
import FormInviteModal from "./FormInviteModal";
import { usePromise } from "../helper";
import { ModalSocial } from "../components/Share";
import { useAlert } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import CreatePayJobs from "./CreatePayJobs";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { addDays, format, parseISO, subDays } from "date-fns";

const HeaderModal = styled.div`
  background-image: url(assets/old/wallpaper.png);
`;
export default function ModalWrapper({
  children,
  type,
  size,
  header,
  backgroundColor,
}) {
  const { modal, dispatchModal } = useContext(MyContext);
  useEffect(() => { }, [modal]);

  if (type && (type === "imageModal" || type === "imageModalFullWidth")) {
    return (
      <ImageModal
        backgroundColor={backgroundColor}
        open={modal && true}
        close={() => dispatchModal({ type: "CLOSE_MODAL" })}
        fullWidth={type === "imageModalFullWidth"}
      >
        {children}
      </ImageModal>
    );
  } else {
    return (
      <Modal
        open={modal && true}
        close={() => dispatchModal({ type: "CLOSE_MODAL" })}
        data={modal && modal.modalProps}
      >
        {children}
      </Modal>
    );
  }
}

const EditJobPost = ({ data }) => {
  const initialState = {
    title: "",
    tiny: "",
    desc: "",
    startDate: null,
    endDate: null,
    date: new Date().getMilliseconds(),
    cat: [],
    display: false,
    location: "",
    needed: 1,
    applications: [],
    views: [],
    messages: [],
    author: {},
  };

  const onSubmit = (values, { setSubmitting }) => { };
  return (
    <ModalWrapper>
      <div>
        <Formik initialValues={initialState} onSubmit={onSubmit}>
          {({ values, handleChange, handleSubmit, isSubmitting }) => {
            return <Form></Form>;
          }}
        </Formik>
      </div>
    </ModalWrapper>
  );
};

const TestModal = () => {
  return (
    <ModalWrapper>
      <div>test</div>
    </ModalWrapper>
  );
};

const CoverCropUploadModal = ({ closeOut }) => {
  const { globalId, me } = useContext(MyContext);
  const [coverDestination, setCoverDestination] = useState("");
  useEffect(() => {
    if (globalId) {
      setCoverDestination(`profile_pictures/cover/${globalId}/`);
    }
  }, [globalId]);

  const handleCoverUpload = (fileRef, metadata) => {
    fileRef.getDownloadURL().then((url) => {
      me.cover = url;
      db.collection("users")
        .doc(globalId)
        .update(me)
        .then(() => {
          closeOut();
        });
    });
  };

  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        dest={coverDestination}
        onUpload={handleCoverUpload}
        aspectRatio={80 / 23}
        minWidth={1600}
        minHeight={460}
      />
    </ModalWrapper>
  );
};

const AvatarCropUploadModal = ({ closeOut }) => {
  const { globalId, me } = useContext(MyContext);
  const [avatarDestination, setAvatarDestination] = useState("");
  useEffect(() => {
    if (globalId) {
      setAvatarDestination(`profile_pictures/profile/${globalId}/`);
    }
  }, [globalId]);

  const handleAvatarUpload = (fileRef, metadata) => {
    fileRef.getDownloadURL().then((url) => {
      me.avatar = url;
      db.collection("users")
        .doc(globalId)
        .update(me)
        .then(() => {
          closeOut();
        });
    });
  };

  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        dest={avatarDestination}
        onUpload={handleAvatarUpload}
        aspectRatio={1}
        minWidth={100}
        minHeight={100}
      />
    </ModalWrapper>
  );
};

const SchoolCoverCropUploadModal = ({ closeOut }) => {
  const { me, globalId, organizationId } = useContext(MyContext);
  const [coverDestination, setCoverDestination] = useState("");
  useEffect(() => {
    if (me) {
      setCoverDestination(`profile_pictures/schools/cover/${me.select.id}/`);
    }
  }, [me]);

  const handleCoverUpload = async (fileRef, metadata) => {
    const url = await fileRef.getDownloadURL();
    await fetch("https://api.bazookka.com/organizations/", {
        headers: {
            'Content-Type': 'application/json',
            'eid': globalId
        },
        method: 'put',
        body: JSON.stringify({
            id: organizationId,
            cover: url,
        })
    })
    
    closeOut();
  };

  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        dest={coverDestination}
        onUpload={handleCoverUpload}
        aspectRatio={80 / 23}
        minWidth={1600}
        minHeight={460}
      />
    </ModalWrapper>
  );
};

const SchoolAvatarCropUploadModal = ({ closeOut }) => {
  const { me, globalId, organizationId } = useContext(MyContext);
  const [avatarDestination, setAvatarDestination] = useState("");
  useEffect(() => {
    if (me) {
      setAvatarDestination(`profile_pictures/schools/profile/${me.select.id}/`);
    }
  }, [me]);

  const handleAvatarUpload = async (fileRef, metadata) => {
    const url = await fileRef.getDownloadURL();
    await fetch("https://api.bazookka.com/organizations/", {
        headers: {
            'Content-Type': 'application/json',
            'eid': globalId
        },
        method: 'put',
        body: JSON.stringify({
            id: organizationId,
            avatar: url,
        })
    })

    closeOut();
  };

  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        dest={avatarDestination}
        onUpload={handleAvatarUpload}
        aspectRatio={1}
        minWidth={100}
        minHeight={100}
      />
    </ModalWrapper>
  );
};

const BzkSeekerAvatarCropUploadModal = ({ returnCroppedImage }) => {
  const [avatarDestination, setAvatarDestination] = useState("");

  const handleAvatarUpload = () => {
    console.log("handle avatar upload")
  }
  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        dest={avatarDestination}
        onUpload={handleAvatarUpload}
        aspectRatio={1}
        minWidth={100}
        minHeight={100}
        returnMode
        onReturn={returnCroppedImage}
      />
    </ModalWrapper>
  );
};

const BzkSeekerCoverCropUploadModal = ({ returnCroppedImage }) => {
  const [coverDestination, setCoverDestination] = useState("");

  const handleCoverUpload = () => {
    console.log("handle cover upload")
  }
  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        dest={coverDestination}
        onUpload={handleCoverUpload}
        aspectRatio={1}
        minWidth={1600}
        minHeight={460}
        returnMode
        onReturn={returnCroppedImage}
      />
    </ModalWrapper>
  );
};

const EventCoverCropUploadModal = ({ closeOut, onCrop, setHandle }) => {
  const handleEventCrop = (fileRef) => {
    onCrop(fileRef);
    setHandle(true);
    closeOut();
  };

  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        returnMode={true}
        onReturn={handleEventCrop}
        aspectRatio={80 / 23}
        minWidth={1600}
        minHeight={460}
      />
    </ModalWrapper>
  );
};

const EventGalleryCropUploadModal = ({ closeOut, onCrop, setHandle, imageNumber }) => {
  const handleEventCrop = (fileRef) => {
    onCrop(fileRef, imageNumber);
    setHandle(true);
    closeOut();
  };

  return (
    <ModalWrapper type="imageModalFullWidth">
      <ImageCrUpload
        returnMode={true}
        onReturn={handleEventCrop}
        aspectRatio={16 / 9}
        minWidth={100}
        minHeight={100}
        maxWidth={600}
        filename={`gallery_${imageNumber}.jpeg`}
      />
    </ModalWrapper>
  );
};

const EducationModal = ({ page, data, title, edit, cursor, closeOut }) => {
  const { content, setContent, me, globalId, globalIdSql } = useContext(MyContext);
  const { t } = useTranslation()

  const getEduOrExpState = () => {
    if (page === "educations") {
      return data.educations[cursor]
    }
    return data.experiences[cursor]

  }
  const [initialState, setState] = useState(
    getEduOrExpState() || {
      title: "",
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      completed: false,
      desc: "",
    }
  );



  const getRichText = () => {
    if (edit && data.educations && data.educations[cursor] && data.educations[cursor].content) {
      return data.educations[cursor].content
    } else if (edit && data.experience && data.educations[cursor] && data.experiences[cursor].content) {
      return data.experiences[cursor].content
    }

    return [
      {
        children: [{ text: '' }],
      }
    ]
  }

  useEffect(() => {

    setState(edit ? getEduOrExpState() : {
      title: "",
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      completed: false,
      desc: "",
    })
  }, [cursor, page])



  useEffect(() => {
    if (edit) {
      setContent(getRichText())
    } else {
      setContent([
        {
          children: [{ text: '' }],
        }
      ]
      )
    }
  }, [data, cursor]);

  useEffect(() => {
    return content;
  }, [content]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    name: Yup.string().required("company name is a required field"),
    // startDate: Yup.date().max(new Date(), "Future date not allowed"),
    // endDate: Yup.date().default(null).when("startDate", (startDate, Yup) =>
    //     startDate && Yup.min(startDate, "End date cannot be lesser")
    // ),
    // desc: Yup.string()
    //   .min(20, "too short!")
    //   .required("description is a required field"),
  });
  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (data && !edit) {
      if (page === "educations") {
        if (data.hasOwnProperty('educations')) {
          values.id = uuidv4()
          values.content = content
          const educs = data.educations
          educs.push(values)
          data.educations = educs
        } else {
          data.educations = [{ id: uuidv4(), ...values, content: content }]
        }
      } else {
        if (data.hasOwnProperty('experiences')) {
          values.id = uuidv4()
          values.eid = globalId
          values.content = content
          data.eid = globalId
          const experiences = data.experiences
          experiences.push(values)
          data.experiences = experiences
        } else {
          data.eid = globalId
          data.experiences = [{ id: uuidv4(), ...values, eid: globalId, content: content }]
        }
      }
      await axios.put(`https://api.bazookka.com/users/${globalIdSql}/info`, data)

    } else if (data && edit) {
      if (page === "educations") {
        let educs = data.educations
        educs[cursor].completed = values.completed
        educs[cursor].content = content
        educs[cursor].title = values.title
        educs[cursor].endDate = values.endDate
        educs[cursor].startDate = values.startDate
        educs[cursor].name = values.name
        data.educations = educs
      } else {
        let exps = data.experiences
        exps[cursor].completed = values.completed
        exps[cursor].content = content
        exps[cursor].title = values.title
        exps[cursor].endDate = values.endDate
        exps[cursor].startDate = values.startDate
        exps[cursor].name = values.name
        data.experiences = exps
      }
      await axios.put(`https://api.bazookka.com/users/${globalIdSql}/info`, data)
    }
    closeOut()
    setSubmitting(false);
  };
  return (
    <ModalWrapper>
      <div>
        <Formik
          initialValues={{ ...initialState }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, handleChange, handleSubmit, setFieldValue, initialValues, isSubmitting }) => {

            return (
              <Form>
                <div className="settings-form-wrapper">
                  <div className="settings-form">
                    <div className="field">
                      <InputText
                        label={page === 'educations' ? t('education.programName') : t('experience.title')}
                        name="title"
                      // value={values?.title}
                      />
                      <ErrorMessage
                        render={(error) => (
                          <div style={{ color: "red" }}>{error}</div>
                        )}
                        name="title"
                      />
                    </div>
                    <div className="field">
                      <InputText
                        label={page === "educations" ? t('general.schools') : t('general.companyName')}
                        name="name"
                        value={values.name}
                      />
                      <ErrorMessage
                        render={(error) => (
                          <div style={{ color: "red" }}>{error}</div>
                        )}
                        name="name"
                      />
                    </div>
                    <div className="field field-group">
                      <label>{page === "educations" ? t('education.startDate') : t('education.startDate')}</label>
                      <div className={`control ${"has-icon"}`}>

                        <DatePicker
                          showMonthYearPicker={true}
                          dateFormat={`MM/yyyy`}
                          autoComplete="off"
                          selected={values.startDate ? new Date(values.startDate) : new Date()}
                          onChange={value => {
                            setFieldValue('startDate', value)
                          }
                          }
                        ></DatePicker>
                      </div>


                    </div>
                      {  page === 'educations' || (!values.completed && page === 'experiences') ?
                    <div className="field field-group">
                      <label>{page === 'educations' ? t('education.graduationDate') : t('experience.endDate')}</label>
                      <div className={`control ${"has-icon"}`}>
                        <DatePicker
                          showMonthYearPicker={true}
                          dateFormat={`MM/yyyy`}
                          label={t('education.graduationDate')}
                          name="endDate"
                          selected={values.endDate ? new Date(values.endDate) : new Date()}
                          onChange={value => {
                            setFieldValue('endDate', value)
                          }}
                        ></DatePicker>
                      </div>
                      <ErrorMessage
                        render={(error) => (
                          <div style={{ color: "red" }}>{error}</div>
                          )}
                          name="endDate"
                          />
                    </div>
                        : null}
                    <div className="field">
                      <SwitchBox label={page === 'educations' ? t('general.completed') : t('job.stillWorking')} name="completed" />
                    </div>
                    <div className="field">

                      <RichTextEditor content={edit && data.educations[cursor] ? data.educations[cursor].content : [
                        {
                          children: [{ text: '' }],
                        }
                      ]} setContent={setContent} />
                    </div>
                    <div className="field"></div>
                    <div className="field">
                      <button
                        type="submit"
                        className="button is-solid primary-button is-fullwidth"
                        disabled={isSubmitting}
                      >
                        {t('general.save')}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalWrapper>
  );
};

export const FeedModal = ({ closeOut }) => {
  const { me, globalId } = useContext(MyContext);
  const [modalAttachments, setModalAttachments] = useState([]);
  const [fileUploadStatus, setFileUploadStatus] = useState("idle");
  const [fileDestination, setFileDestination] = useState("");
  const [createdDoc, setCreatedDoc] = useState("");
  const { t } = useTranslation();
  const alert = useAlert()
  const [groups, groupError, groupLoad] = usePromise(async () => {
    let doc = await groupsDoc.where('admin', 'array-contains-any', [globalId]).get()
    return doc.docs.map(d => Object.assign({ id: d.id, ...d.data() }))
  }, [globalId])

  const composeoptions = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    transition: "all .3s",
    color: "#888da8",
    borderRadius: 4,
    backgroundColor: "#f7f7f7",
    padding: "6px 16px",
    position: "relative",
  };
  const span = {
    padding: "0 8px",
  };
  const input = {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    cursor: "pointer",
  };
  const initialValues = {
    message: "",
    users: [],
    files: [],
  };
  const handleModalUpload = (fileRef, metadata) => {
    fileRef.getDownloadURL().then((url) => {
      const newAttachment = {
        name: metadata.name,
        path: url,
        type: metadata.contentType,
        timestamp: Date.now(),
      };
      db.collection("posts")
        .doc(createdDoc)
        .update({
          attachments: firestore.FieldValue.arrayUnion(newAttachment),
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    });
  };

  const handleDelete = (toDelete) => {
    setModalAttachments((prevState) => {
      const newState = prevState.filter(function (att) {
        return att !== toDelete;
      });
      return newState;
    });
  };

  const handleQueueComplete = () => {
    //Reset all the states and Close the modal
    setFileUploadStatus("idle");
    setModalAttachments([]);
    setFileDestination("");
    setCreatedDoc("");
    closeOut();
  };

  const onSubmit = (values, { setSubmitting }) => {
    db.collection("posts")
      .add({
        author: { id: globalId, displayName: me.displayName },
        text: values.message,
        groups: firestore.FieldValue.arrayUnion(...values.users),
        date: new Date().getTime(),
        authorIds: globalId,
        hasAttachment: modalAttachments.length > 0,
      })
      .then((r) => {
        setSubmitting(false);
        alert.success(t("general.mesinvstu"));
        setCreatedDoc(r.id);
        setFileDestination(`posts_attachments/${r.id}/${globalId}/`);
        setFileUploadStatus("uploading");
      });
  };

  if (groupLoad) {
    return <Loader></Loader>
  }
  return (
    <ModalWrapper>
      {fileUploadStatus === "uploading" && <Loader />}
      <div>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting }) => {
            return (
              <Form>
                <div className="settings-form-wrapper">
                  <div className="settings-form">
                    <div className="field">
                      <ComposeText name="message" />
                    </div>
                    <div className="field">
                      <div
                        id="extended-options"
                        className="compose-options"
                        style={composeoptions}
                      >
                        <div
                          className="columns is-multiline is-full"
                          style={{ width: "100%" }}
                        >
                          <div className="column is-12 is-narrower">
                            <div className="compose-option is-centered">
                              <FileUpload
                                dest={fileDestination}
                                onUpload={handleModalUpload}
                                queueStatus={fileUploadStatus}
                                queue={modalAttachments}
                                setQueue={setModalAttachments}
                                onQueueComplete={handleQueueComplete}
                              >
                                <svg
                                  style={{
                                    height: 20,
                                    width: 20,
                                    verticalAlign: "sub",
                                  }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-camera"
                                >
                                  <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                                  <circle cx="12" cy="13" r="4"></circle>
                                </svg>
                                <span style={span}>{t("upload.addDocsMessage")}</span>
                              </FileUpload>
                              <div>
                                <AttachmentList
                                  attachments={modalAttachments}
                                  metas={["name"]}
                                  onDelete={handleDelete}
                                  isHorizontal
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="hidden-options">
                        <div className="target-channels">
                          <Multiselect
                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                            options={groups}
                            displayValue={'name'}
                          />
                          {/*<NiceMultiSelect*/}
                          {/*  index={0}*/}
                          {/*  key={0}*/}
                          {/*  name="users"*/}
                          {/*  data={{ name: "all" }}*/}
                          {/*  value={`all`}*/}
                          {/*/>*/}
                          {/*{me &&*/}
                          {/*  me["classrooms"] &&*/}
                          {/*  me["classrooms"].map((cls, i) => {*/}
                          {/*    return (*/}
                          {/*      <NiceMultiSelect*/}
                          {/*        index={i + 1}*/}
                          {/*        key={i + 1}*/}
                          {/*        name="users"*/}
                          {/*        data={cls}*/}
                          {/*        value={cls.id}*/}
                          {/*      ></NiceMultiSelect>*/}
                          {/*    );*/}
                          {/*  })}*/}
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <button
                        type="submit"
                        className="button is-solid primary-button is-fullwidth"
                        disabled={isSubmitting}
                      >
                        Publish
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </ModalWrapper>
  );
};

export const ModalManager = () => {
  const lookup = {
    TestModal,
    EducationModal,
    EditJobPost,
    FeedModal,
    CoverCropUploadModal,
    EditMeetingModal,
    AvatarCropUploadModal,
    EditJobModal,
    EditEventModal,
    SchoolAvatarCropUploadModal,
    BzkSeekerAvatarCropUploadModal,
    BzkSeekerCoverCropUploadModal,
    SchoolCoverCropUploadModal,
    EventCoverCropUploadModal,
    EventGalleryCropUploadModal,
    BugReportModal,
    PreviewUser,
    FormElementCreationModal,
    FormElementModificationModal,
    FormElementDeletionModal,
    FormInviteModal,
    EditGroupModal,
    StartConversationModal,
    EditUserRoleModal,
    ModalSocial,
    UsersListModal,
    CreatePayJobs

  };
  const { modal } = useContext(MyContext);
  const [rModal, setModal] = useState(null);

  useEffect(() => {
    if (modal) {
      const { modalType, modalProps } = modal;
      const ModalComponent = lookup[modalType];
      setModal(<ModalComponent {...modalProps} />);
    }
  }, [modal]);
  if (!modal) {
    return <span></span>;
  }
  return <span id="an-indo">{rModal}</span>;
};

const ImageModal = ({ open, children, close, fullWidth, backgroundColor }) => {
  const fullWidthStyle = fullWidth
    ? { width: "auto", height: "auto", maxWidth: "none" }
    : {};

  return (
    <div
      id="share-modal"
      style={{ backgroundColor }}
      className={`modal share-modal has-light-bg ${open && "is-active"}`}
    >
      <div className="modal-background"></div>
      <div className="modal-content" style={fullWidthStyle}>
        {children}
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => close()}
      ></button>
    </div>
  );
};

const Modal = ({ open, children, close, data }) => {
  return (
    <div
      id="share-modal"
      className={`modal share-modal has-light-bg ${open && "is-active"}`}
    >
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="card">
          <HeaderModal className="card-heading">
            <div className="dropdown is-primary share-dropdown">
              <div>
                <div className="button">
                  {data.ico || <i className="mdi mdi-format-float-left"></i>}{" "}
                  <span>{data.title}</span> <i data-feather="chevron-down"></i>
                </div>
              </div>
              <div className="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  <div className="dropdown-item" data-target-channel="feed">
                    <div className="media">
                      <i className="mdi mdi-format-float-left"></i>
                      <div className="media-content">
                        <h3>{data.title}</h3>
                        <small>Share this publication on your feed.</small>
                      </div>
                    </div>
                  </div>
                  <hr className="dropdown-divider" />
                  <div
                    className="dropdown-item"
                    data-target-channel="private-message"
                  >
                    <div className="media">
                      <i className="mdi mdi-email-plus"></i>
                      <div className="media-content">
                        <h3>Share in message</h3>
                        <small>
                          Share this publication in a private message.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="close-wrap" onClick={() => close()}>
              <span className="close-modal">
                <CloseIco />
              </span>
            </div>
          </HeaderModal>
          <div className="card-body">
            <div className="modal-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BugReportModal = ({ bitMapImage, closeOut }) => {
  const { globalId, me } = useContext(MyContext);
  const canvasRef = useRef();
  const [submitted, setSubmitted] = useState(false);
  const storageRef = firebaseInstance().storage().ref();
  const { alert } = useAlert()
  const { t } = useTranslation();

  let blobToUpload = null;

  const drawScaledDownCanvas = (canvas, img) => {
    let ratio = Math.min(canvas.width / img.width, canvas.height / img.height);
    let x = (canvas.width - img.width * ratio) / 2;
    let y = (canvas.height - img.height * ratio) / 2;
    canvas
      .getContext("2d")
      .drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        x,
        y,
        img.width * ratio,
        img.height * ratio
      );
  };

  useEffect(() => {

    const offscreenCanvas = new OffscreenCanvas(
      bitMapImage.width,
      bitMapImage.height
    );
    offscreenCanvas.getContext("2d").drawImage(bitMapImage, 0, 0);
    offscreenCanvas
      .convertToBlob({
        type: "image/jpeg",
        quality: 0.8,
      })
      .then((blob) => {
        blobToUpload = blob;
      });
    const canvas = canvasRef.current;
    drawScaledDownCanvas(canvas, bitMapImage);
  });

  const schema = Yup.object().shape({
    bugDescription: Yup.string().required("Required"),
  });

  const initialFormValues = {
    user: me.displayName,
    userEmail: me.email,
    bugDescription: "",
    imagePath: "",
    createdAt: "",
  };

  return (
    <ModalWrapper>
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          values.createdAt = firestore.FieldValue.serverTimestamp();

          bugReports
            .add(values)
            .then((docRef) => {
              let bugReportAttachmentsRef = storageRef.child(
                "bug_reports_attachments/" + docRef.id
              );
              bugReportAttachmentsRef.put(blobToUpload).then((snapshot) => {
                bugReportAttachmentsRef.getDownloadURL().then((url) => {
                  bugReports
                    .doc(docRef.id)
                    .update({ imagePath: url })
                    .then(() => {
                      setSubmitted(true);
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      console.error("Error updating document: ", error);
                    });
                });
              });
            })
            .catch((error) => {
              setSubmitting(false);
              console.log("Error: " + error);
            });
          //TODO: close modal automagically
          //  closeOut();
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="settings-form-wrapper">
              <div className="settings-form">
                <div style={{ width: "95%" }}>
                  <div className="field">
                    <InputText
                      id="user"
                      name="user"
                      label={t("general.user")}
                      disabled={true}
                    />
                  </div>
                  <div className="field">
                    <InputText
                      id="userEmail"
                      name="userEmail"
                      label={t("settings.email")}
                      disabled={true}
                    />
                  </div>
                  <div className="field">
                    <TextAreaInput
                      id="bugDescription"
                      name="bugDescription"
                      label={t("general.bugDescription")}
                      placeholder="Describe here"
                    />
                  </div>
                  <div className="field">
                    <canvas ref={canvasRef}></canvas>
                  </div>
                  <div className="field">
                    <button
                      type="submit"
                      className="button is-solid primary-button is-fullwidth"
                      disabled={isSubmitting}
                    >
                      {submitted ? (
                        <Trans i18nKey="general.bugReportSent">
                          Bug report sent successfully
                        </Trans>
                      ) : (
                        <Trans i18nKey="general.submit">Submit</Trans>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

const FormElementCreationModal = ({ handleAddElement, closeOut }) => {
  const { me, globalId } = useContext(MyContext);

  const initialFormValues = {
    id: "",
    adminId: [globalId],
    organisationId: me.select.id, //School or business id
    question: "",
    questionDescription: "",
    answerType: "", //Make a drop down select
    required: "",
    elementType: "custom",
  };

  return (
    <ModalWrapper>
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          formElements
            .add(values)
            .then((docRef) => {
              formElements
                .doc(docRef.id)
                .update({ id: docRef.id })
                .then(() => {
                  values.id = docRef.id;
                  handleAddElement(values);
                  closeOut();
                });
            })
            .catch((error) => {
              setSubmitting(false);
              console.log("Error: " + error);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="settings-form-wrapper">
              <div className="settings-form">
                <div style={{ width: "95%" }}>
                  <div className="field">
                    <InputText id="question" name="question" label="Question" />
                  </div>
                  <div className="field">
                    <TextAreaInput
                      id="questionDescription"
                      name="questionDescription"
                      label="Description"
                      placeholder="Describe your question"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="required">Required?</label>
                    {/*TODO CSS FOR RADIO SELECT */}
                    <div>
                      <label>
                        <Field type="radio" name="required" value="true" />
                        <span>True</span>
                      </label>
                      {"\u00A0"}
                      {"\u00A0"}
                      <label>
                        <Field type="radio" name="required" value="false" />
                        <span>False</span>
                      </label>
                    </div>
                  </div>
                  <div className="field">
                    <button
                      type="submit"
                      className="button is-solid primary-button is-fullwidth"
                      disabled={isSubmitting}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

const FormElementModificationModal = ({
  element,
  handleElementUpdate,
  closeOut,
}) => {
  const { me, globalId } = useContext(MyContext);

  let initialFormValues = {
    id: element.id,
    organisationId: me.select.id, //School or business id
    question: element.question,
    questionDescription: element.questionDescription,
    answerType: "", //Make a drop down select
    required: element.required,
    elementType: "custom",
  };

  return (
    <ModalWrapper>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          formElements
            .doc(values.id)
            .update(values)
            .then(() => {
              handleElementUpdate(values);
              closeOut();
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="settings-form-wrapper">
              <div className="settings-form">
                <div style={{ width: "95%" }}>
                  <div className="field">
                    <InputText id="question" name="question" label="Question" />
                  </div>
                  <div className="field">
                    <TextAreaInput
                      id="questionDescription"
                      name="questionDescription"
                      label="Description"
                      placeholder="Describe your question"
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="required">Required?</label>
                    {/*TODO CSS FOR RADIO SELECT */}
                    <div>
                      <label>
                        <Field type="radio" name="required" value="true" />
                        <span>True</span>
                      </label>
                      {"\u00A0"}
                      {"\u00A0"}
                      <label>
                        <Field type="radio" name="required" value="false" />
                        <span>False</span>
                      </label>
                    </div>
                  </div>
                  <div className="field">
                    <button
                      type="submit"
                      className="button is-solid primary-button is-fullwidth"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

const FormElementDeletionModal = ({
  element,
  handleElementDelete,
  closeOut,
}) => {
  const DeleteStyled = styled.div`
    .button-group {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  `;

  return (
    <ModalWrapper>
      <DeleteStyled>
        <div className="settings-form-wrapper">
          <div className="settings-form">
            <div style={{ width: "95%" }}>
              <div className="button-group">
                <button
                  className="button is-solid primary-button "
                  onClick={() => {
                    handleElementDelete(element);
                    closeOut();
                  }}
                >
                  Yes
                </button>
                {"\u00A0"}
                <button
                  className="button is-solid primary-button"
                  onClick={closeOut}
                  style={{ backgroundColor: "red" }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </DeleteStyled>
    </ModalWrapper>
  );
};

