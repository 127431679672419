import React, { useContext, useState, useEffect, createRef } from 'react';
import { MyContext } from "../stores";
import moment from 'moment'
import 'moment/locale/fr'  // without this line it didn't work
import { Multiselect } from 'multiselect-react-dropdown';
import { useParams } from "react-router-dom";
import {
    AvatarGroup,
    Avatar as AvatarMUI,
    IconButton,
    Button,
    Dialog,
    Divider,
    AppBar,
    Toolbar,
    Typography,
    Box

} from "@mui/material";
import { Create as CreateIcon, Visibility as EyeIcon, Print as PrintIcon } from "@mui/icons-material";
import Avatar from 'react-avatar';
import FileUpload from '../components/FileUpload';
import slc from 'styled-components'
import Pdf from 'react-to-pdf'
import SignaturePad from 'react-signature-pad-wrapper'
import AttachmentList from "../components/AttachmentList";
import EditableNote from '../components/EditableNote';
import { useTranslation } from "react-i18next";
import PrintableMyInternship from './DocumentGeneration/printableMyInternship';
import arraySortByField from '../utils/arraySortByField';
import { JobPageStyled } from "./JobPage";
import { usePromise, stringAvatar, stringToColor } from "../helper";
import { format } from "date-fns";
import fr from "date-fns/locale/fr-CA";
import { useAlert } from 'react-alert';
import Loader from "../components/Loader";
import { useDialog } from "../stores/dialog";
import Preview, { renderDesc } from "./formGenerator/Preview";
import { activeJobs, schoolsDoc, firebaseInstance } from "../firestore/firestoreService";
import { UserAction } from "../stores/enums/UserAction";
import useSWR from "swr";

export const WrappedPostContent = slc.div`
 .story-post-wrapper {
   margin: auto!important;
   width: 100% !important;
 }
 .story-post {
    background: white;
    border-radius: 10px;
    margin-top: -6rem;
    position: relative;
    margin-bottom: 1rem;
    border: 1px solid #e0e0e0;
    box-shadow: 1px 2px #e0e0e0;
 }
`

const PdfStyled = slc.section`
    width: 800px;
    padding: 50px 30px; 
    margin: auto;
    height: 2000px;
`

const FooterModal = slc.footer`0
    position: absolute;
    bottom: 0px;
    width: 100vw;
`

const CommentsContainer = slc.div`

`

const ModeButtonContainer = slc.div`
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    gap:10px;
    margin:10px 0;

    button.active{
        background-color:var(--bazookka-blue);
    }
`

const ShareSubmitContainer = slc.div`
    width:100%;
    margin-left:10px;

    .button{
        margin-top:10px;
        width:100%;
    }
`

const NotesContainer = slc.div`
    h3{
        background-color:var(--bazookka-blue);
        border-radius:5px;
        padding:6px;
        font-size:18px;
        color:white;
    }
`

const NoteContainer = slc.div`
    border-top: 1px solid #e0e0e0;

    .sharedWithTitle{
        vertical-align:middle;
        font-size:14px;
        line-height:40px;
        color:#999999;
    }

    .sharedTo .sb-avatar{
        vertical-align:middle;
        transform: scale(0.7);
    }

    .shareSubmitContainer{
        width:100%;
        margin-left:10px;

        .button{
            margin-top:10px;
            width:100%;
        }
    }

    .editButton{
        display:inline;
        margin-left:10px;
        cursor:pointer;
        background-color:var(--bazookka-green);
        color:white;
        padding:0 3px;
        border-radius:10px;

        svg{
            font-size:12px;
        }
    }
`

const PrintButton = slc.a`
    width: fit-content;
    right: 20px;
    padding: 4px 10px;
    border-radius: 15px;
    border:1px solid #e8e8e8;
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor:pointer;

    span{
        vertical-align:middle;
    }

    i{
        padding-left:4px;
        vertical-align:top;
    }

    &:hover{
        color: var(--bazookka-blue);
        border-color: var(--bazookka-blue);
    }
`

const TreeBoxContainer = slc.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-center: center;
    align-items: center;
    height: 100px;
    div {
       width: 30% !important;
       height: 80%;
       display: flex;
    }
`

const ButtonContract = slc.a`
    box-shadow: 2px 2px rgba(0,0,0,0.1);
    margin-top: 1rem;
`


const Pad = ({ isActive, setActive, data, fulldata, uri }) => {
    const ref = createRef()
    const [ctx, setctx] = useState()
    const t = useTranslation()
    const alert = useAlert()

    useEffect(() => {
        if (isActive) {
            try {
                const img = new Image();
                img.src = data.imageData;

                ref.current.canvas.getContext('2d').drawImage(img, 0, 0)
            } catch (e) {
                erase()
            }
        }
    }, [ref])

    const erase = () => {
        ref.current.canvas.getContext('2d').clearRect(0, 0, 400, 150)
    }

    const onSave = () => {
        let { relatedUsers } = fulldata
        relatedUsers = relatedUsers.map(u => {
            if (u.id === data.id) {
                u.sign = true;
                u.imageData = ref.current.canvas.toDataURL('image/png')
                return u;
            }
            return u;
        })
        activeJobs.doc(uri).update({ relatedUsers }).then(r => {
            alert.success(t("general.updateinfo"))
            setActive(false)
        }).catch(e => {
            alert.success(t("general.notupdatedinfo"))
        })

    }
    return (
        <div className={`modal ${isActive && 'is-active'}`} >
            <div className="modal-background" ></div>
            <div className="modal-card" style={{ width: 400, height: 280 }}>
                <div className="modal-card-body">
                    {
                        <SignaturePad width={400} height={150} ref={ref} />
                    }
                </div>
                <div className="modal-card-foot" style={{ width: 400 }}>
                    <button className="button is-primary" onClick={onSave}>Save</button>
                    <button className="button is-danger" onClick={() => setActive(false)}>Close</button>
                    <button className="button is-danger" onClick={() => erase()}>Erase</button>
                </div>
            </div>

        </div>
    )
}

const Convention = ({ isActive, setActive, form }) => {
    const ref = createRef()
    return (
        <div className={`modal ${isActive && 'is-active'}`} >
            <div className="modal-background" style={{ backgroundColor: 'white' }}></div>
            <div className="modal-card" style={{ width: '100vw', height: '100vh', paddingBottom: 100 }}>
                <PdfStyled className="modal-card-body" ref={ref}>
                    <h1>interns name</h1>
                    <p>{form.student.displayName}</p>
                    <h2>Description:</h2>
                    <p>{form.description}</p>
                </PdfStyled>

            </div>
            <FooterModal className="modal-card-foot">
                <Pdf targetRef={ref}>
                    {({ toPdf, targetRef }) => {
                        return <button onClick={toPdf} className="button is-primary">Print</button>
                    }}
                </Pdf>
                <button className="button is-danger" onClick={() => setActive(false)}>Close</button>
            </FooterModal>
        </div>
    )
}

const BatchComponent = ({ type }) => {
    const { slug } = useParams()
    const { globalId, globalIdSql, me, langChoice } = useContext(MyContext);
    const [api, setApi] = useState(true);
    const [shareableUsers, setShareableUsers] = useState([])
    const [jobTitle, setJobTitle] = useState("")
    const [Internship, setInternship] = useState(null)
    const [company, setCompany] = useState(null)
    const [select, setSelect] = useState([])
    const [isSign, setSign] = useState(null)
    const [isUser, setUser] = useState(null)
    const [mode, setMode] = useState("comment")
    const [text, setText] = useState("")
    const [fileDestination, setFileDestination] = useState(`/my_internship_attachments/${slug}/${globalId}/`)
    const { t } = useTranslation()
    const dialog = useDialog()
    const pdfFile = useDialog()
    moment.locale('fr')

    const {data, error, mutate} = useSWR(`https://api.bazookka.com/internshipObjects/batch/${slug}`, url => fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                eid: globalId,
            }
        })
            .then( r=> r.json())
    )

    let {data: comments, error: cerror, mutate: cMutate} = useSWR(`https://api.bazookka.com/internshipObjects/comments/${slug}`,
        url => fetch(url, {method: 'get', headers: {
                'Content-Type': 'application/json',
                eid: globalId
            }})
            .then(r => r.json())
    )

    let {data: notes, error: nerror, mutate: nMutate} = useSWR(`https://api.bazookka.com/internshipObjects/notes/${slug}/user/${globalIdSql}`,
        url => fetch(url, {method: 'get', headers: {
                'Content-Type': 'application/json',
                eid: globalId
            }})
            .then(r => r.json())
    )

    let {data: sharedNotes, error: snerror, mutate: snMutate} = useSWR(`https://api.bazookka.com/internshipObjects/notes/shared/${slug}/user/${globalIdSql}`,
        url => fetch(url, {method: 'get', headers: {
                'Content-Type': 'application/json',
                eid: globalId
            }})
            .then(r => r.json())
    )

    let {data: Attachments, error: eAttachments, mutate: aMutate} = useSWR(`https://api.bazookka.com/internshipObjects/shares/${slug}`,
        url => fetch(url, {method: 'get', headers: {
                'Content-Type': 'application/json',
                eid: globalId
            }})
            .then(r => r.json())
    )

    const setFormat = (value) => {
        console.log('set_format', value)
        if (!value) {
            return;
        }
        return format(new Date(value), 'PPP', { locale: langChoice === 'fr' && fr || null })
    }
    const onChange = (e) => {
        setText(e.target.value)
    }

    const signatureCount = () => {
        let count = 0;
        let required = 0;
        if (Internship.studentSignDoc) {
            required++
        }
        if (Internship.teacherSignDoc) {
            required++
        }
        if (Internship.supervisorSignDoc) {
            required++
        }
    }

    function selectIco(value) {
        switch (value) {
            case 'pdf':
                return 'mdi-file-pdf'
                break;
            case 'doc':
                return 'mdi-file-word'
                break;
            case 'excel':
                return 'mdi-file-excel'
                break;
            case 'pp':
                return 'mdi-file-powerpoint'
                break;
            case 'text':
                return 'mdi-note-text'
                break;
            case 'image':
                return 'mdi-file-image'
                break;
            case 'digital':
                return 'mdi-file-cloud'
                break;
            case 'csv':
                return 'mdi-file-delimited'
                break;
        }
    }

    const openPad = (user) => {
        setSign(true)
        setUser(user)
    }

    async function handleMessageClick(e) {

        const newMsg = {
            message: text,
            type: mode,
        };
        if(text === "") {
            return
        }
        try {
            if(mode === 'comment'){
                let m = await fetch(`https://api.bazookka.com/internshipObjects/comments/${slug}`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        eid: globalId
                    },
                    body: JSON.stringify(newMsg)
                })

                const createUsersActions = firebaseInstance().functions().httpsCallable("createUsersActions")
                createUsersActions({
                    type: UserAction.CONVERSATION_REPLY,
                    postTitle: jobTitle,
                    displayName: me.displayName,
                    globalId
                })

                cMutate();
            }else{
                let commentRequest = await fetch(`https://api.bazookka.com/internshipObjects/notes/${slug}`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        eid: globalId
                    },
                    body: JSON.stringify(newMsg)
                })

                const commentData = await commentRequest.json();

                if(shareableUsers.length > 0 && commentData.id){
                    const shareRequests = select.map(user => {
                        fetch("https://api.bazookka.com/shares/", {
                            headers: {
                                'Content-Type': 'application/json',
                                eid: globalId
                            },
                            method: 'post',
                            body: JSON.stringify({
                                comment_id: commentData.id,
                                user_id: user.value,
                                share_by_id: globalIdSql,
                            })
                        })
                    })
                    await Promise.all(shareRequests);
                }

                nMutate();
            }
            setText("")
        } catch (e) {

        }
    }

    async function handleEditSubmit(data) {
        let toDelete = [];
        let toAdd = [];
        let addRequests = [];
        let deleteRequests = [];

        //Find Shares to delete
        if(data.oldSelect){
            toDelete = data.oldSelect.filter(share => {
                let returnVal = true;
                data.select.forEach(newShare => {
                    if(share.id === newShare.value){
                        returnVal = false;
                    }
                })
                return returnVal;
            })
        }

        //Find shares to Add
        if(data.select && data.oldSelect){
            toAdd = data.select.filter(share => {
                let returnVal = true;
                data.oldSelect.forEach(newShare => {
                    if(share.value === newShare.id){
                        returnVal = false;
                    }
                })
                return returnVal;
            })
        }else if(data.select) {
            toAdd = data.select;
        }

        await fetch(`https://api.bazookka.com/internshipObjects/notes`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                eid: globalId
            },
            body: JSON.stringify({
                id: data.id,
                message: data.text,
            })
        })

        if(toAdd.length > 0){
            addRequests = toAdd.map(share => {
                fetch("https://api.bazookka.com/shares/", {
                    headers: {
                        'Content-Type': 'application/json',
                        eid: globalId
                    },
                    method: 'post',
                    body: JSON.stringify({
                        comment_id: data.id,
                        user_id: share.value,
                        share_by_id: globalIdSql,
                    })
                })
            })
        }

        if(toDelete.length > 0){
            deleteRequests = toDelete.map(share => {
                fetch(`https://api.bazookka.com/shares/${share.share_id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        eid: globalId
                    },
                    method: 'delete',
                })
            })
        }

        await Promise.all(addRequests);
        await Promise.all(deleteRequests);
        nMutate();
    }

    function onPDF() {
        pdfFile.user(Internship)
        pdfFile.data(Internship)
        pdfFile.print()
        pdfFile.node(<PrintableMyInternship internship={Internship}></PrintableMyInternship>)
        pdfFile.open()
    }

    function openDoc(role, ques) {

        dialog.node(<Preview {...ques} checked={true} dialog={dialog} />)
        dialog.call(role)
        dialog.user(role)
        dialog.open()
    }

    function handleUpload(fileRef, metadata) {
        console.log(fileRef)
        fileRef.getDownloadURL().then((url) => {
            const newAttachment = {
                name: metadata.name,
                path: url,
                type: metadata.contentType,
                timestamp: Date.now(),
                creatorId: globalId
            };
            fetch(`https://api.bazookka.com/internshipObjects/shares/${slug}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    eid: globalId
                },
                body: JSON.stringify({
                    meta: newAttachment,
                    title: metadata.name,

                })
            }).then( r=> r.json())
                .then( d => {
                    aMutate()
                    console.log(d)
                })

        });
    }

    useEffect(()=> {
        console.log(data, comments)
        if(data){
            const filteredUsers = data.linked.filter(user => user.id !== globalIdSql);
            setShareableUsers(filteredUsers.map(user => ({name: user.display_name, value: user.id})))
        }
        if(data && comments && Attachments) {
            setApi(false)
        }
    }, [comments, data, Attachments])

    if (api) {
        return <Loader></Loader>
    }

    return (
        <JobPageStyled>
            <div className="image-banner">
                <div className="normal" style={{ background: company?.cover ? `url(${data.org.cover})` : 'url(assets/old/wallpaper.png)' }}>
                    <div className="filter"></div>
                    <div className="in-normal">
                        {data.org?.avatar ?
                            <Avatar src={data.org?.avatar} round={true} style={{ zIndex: 1 }} /> :
                            <Avatar name={data.org?.name} round={true} style={{ zIndex: 1 }}></Avatar>}

                        <div>
                            <h2>{data.title}</h2>
                            <br />
                            <h6>{data.meta.businessName}</h6>
                        </div>

                    </div>

                </div>
            </div>
            <div className="the-content">
                <div className="left">
                    <div className="post">
                        <h2>{t('general.description')}</h2>
                        {data.tiny}
                    </div>
                    <div className="story-post-wrapper">
                        <div className="story-post">
                            <ModeButtonContainer>
                                <button className={`button comments-count ${mode === 'comment' ? "active" : ""}`} onClick={() => setMode("comment")}>
                                    <h3>{t('general.comments')} ({comments && comments.length})</h3>
                                </button>
                                <button className={`button comments-coun ${mode === 'note' ? "active" : ""}`} onClick={() => setMode("note")}>
                                    <h3>{t('general.notes')} ({notes && sharedNotes && (notes.length + sharedNotes.length)})</h3>
                                </button>
                            </ModeButtonContainer>
                            <div className="post-compose">
                                <div className="control">
                                    {mode === "comment" &&
                                        <textarea className="textarea" placeholder={`${t('general.typecomment')}...`} onChange={onChange} value={text}></textarea>
                                    }
                                    {mode === "note" &&
                                        <textarea className="textarea" placeholder={`${t('general.typenote')}...`} onChange={onChange} value={text}></textarea>
                                    }
                                </div>
                                <div className="compose-controls">
                                    {me?.avatar ?
                                        <Avatar src={me?.avatar} round={true} size={40} />
                                        :
                                        <Avatar name={me?.displayName} round={true} size={40} />
                                    }
                                    {mode === "comment" &&
                                        <a className="button is-solid accent-button raised" onClick={handleMessageClick}>{t('general.postcomment')}</a>
                                    }
                                    {mode === "note" &&
                                        <ShareSubmitContainer>
                                            <Multiselect
                                                name="shareId"
                                                options={shareableUsers}
                                                selectedValues={select}
                                                onSelect={(v)=> setSelect(v)}
                                                onRemove={(v)=> setSelect(v)}
                                                displayValue="name"
                                                placeholder={t('customForm.shareWith')+'...'}
                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                            />
                                            <a className="button is-solid accent-button raised" onClick={handleMessageClick}>{t('general.savenote')}</a>
                                        </ShareSubmitContainer>
                                    }

                                </div>
                            </div>
                            {mode === "comment" &&
                                <div className="comments-wrap">
                                    <CommentsContainer>
                                        {comments && comments.map((message, i) => {
                                            return (
                                                <div key={i} className="media is-comment">
                                                    <div className="media-left">
                                                        <div className="avatar-wrap is-smaller">
                                                            {message.user.avatar ? <Avatar src={message.user.avatar} round={true} size={40} /> : <Avatar name={message.user.display_name} round={true} size={40} />}
                                                            <div className="badge">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                     strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                                     className="feather feather-check">
                                                                    <polyline points="20 6 9 17 4 12"></polyline>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="media-content">
                                                        <div className="comment-meta">
                                                            <h4><a>{message.user.displayName}</a> <small> · {moment(message.created_at).format('LLLL')}</small></h4>
                                                            {message.message}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </CommentsContainer>
                                </div> }
                            {mode === "note" &&
                                <div className="comments-wrap">
                                    <NotesContainer>
                                        <h3>{t("general.notes")}</h3>
                                        {notes && notes.map((message, i) => {
                                            return (
                                                <NoteContainer key={i}>
                                                    <EditableNote
                                                        noteId={message.id}
                                                        avatar={message.user.avatar}
                                                        displayName={message.user.display_name}
                                                        message={message.message}
                                                        createdAt={message.created_at}
                                                        sharedWith={message.shared_with}
                                                        editable
                                                        shareableUsers={shareableUsers}
                                                        onSubmit={handleEditSubmit}
                                                    />
                                                </NoteContainer>
                                            )
                                        })}
                                    </NotesContainer>
                                </div> }
                            {mode === "note" &&
                                <div className="comments-wrap">
                                    <NotesContainer>
                                        <h3>{t("documents.userDocuments")}</h3>
                                        {sharedNotes && sharedNotes.map((message, i) => {
                                            return (
                                                <NoteContainer key={i}>
                                                    <EditableNote
                                                        noteId={message.id}
                                                        avatar={message.user.avatar}
                                                        displayName={message.user.display_name}
                                                        message={message.message}
                                                        createdAt={message.created_at}
                                                    />
                                                </NoteContainer>
                                            )
                                        })}
                                    </NotesContainer>
                                </div> }
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="watcher" style={{ marginBottom: '2rem' }}>
                        <AvatarGroup max={5}>
                            {data.linked?.map((user, key) => user.avatar ? <Avatar round={true} size={40} src={user.avatar} key={key} alt={user.display_name} /> : <Avatar round={true} size={40} key={key} alt={user.display_name} name={user.display_name} />)}
                        </AvatarGroup>
                    </div>
                    <div className="bullet">
                        <div className="top">{t('jobs.startDate')}</div>
                        <div className="buttom">{setFormat(data.meta?.startDate ?? new Date())}</div>
                        <div className="round">
                            <div className="circle">
                                <i className="mdi mdi-calendar"></i>
                            </div>
                        </div>
                    </div>
                    <div className="bullet">
                        <div className="top">{t('jobs.endDate')}</div>
                        <div className="buttom">{setFormat(data.meta?.endDate ?? moment(data.meta?.startDate).add(7, 'days'))}</div>
                        <div className="round">
                            <div className="circle">
                                <i className="mdi mdi-calendar"></i>
                            </div>
                        </div>
                    </div>
                    <div className="attachment-container">
                        <Box sx={{display: "flex"}}>
                            <IconButton
                                size="medium"
                                color="primary"
                                alt="add file"
                                style = {{backgroundColor : "var(--bazookka-pink)"}}
                            >
                                <FileUpload dest={fileDestination} onUpload={handleUpload}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                         viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                         strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                         className="feather feather-paperclip">
                                        <path
                                            d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                    </svg>
                                </FileUpload>
                            </IconButton>
                            <div style={{ paddingInline: "10px" }}>{t('general.attachments')}</div>
                            <div className="bottom"><h3>({Attachments && Attachments.length})</h3></div>
                        </Box>

                        <AttachmentList attachments={Attachments} metas={['name', 'date']} />
                    </div>

                    {/*{data.linked
                        // .filter(user => ['student', 'teacher', 'supervisor'].includes(user.internshipRole))
                        // .filter(user => Internship[`${user.internshipRole}SignDoc`])
                        .map((user, key) => {
                            return (
                                <Button
                                    key={key}
                                    fullWidth
                                    endIcon={<CreateIcon />}
                                    variant="contained" color="primary" style={{ margin: `1rem auto` }} onClick={e => {
                                        openDoc(user, Internship[`${user.internshipRole}forms`][0])

                                    }}>
                                    {user.display_name}
                                </Button>

                            )
                        })} */}
                    {/*<Button*/}
                    {/*    fullWidth*/}
                    {/*    endIcon={<EyeIcon />}*/}
                    {/*    variant={'contained'}*/}
                    {/*    color={'primary'}*/}
                    {/*    style={{margin: `1rem auto`}}*/}
                    {/*    onClick={e => onPDF()}*/}
                    {/*>display pdf</Button>*/}
                    {/*<BlobProvider document={<PrintableMyInternship internship={Internship} />}>*/}
                    {/*    {({ blob, url, loading, error }) => {*/}
                    {/*        return (*/}
                    {/*            <PrintButton href={url} target='_blank'>*/}
                    {/*                <span>Print</span>*/}
                    {/*                <i className="mdi mdi-printer"></i>*/}
                    {/*            </PrintButton>*/}
                    {/*        );*/}
                    {/*    }}*/}
                    {/*</BlobProvider>*/}

                    {false && (
                        <>
                            <a className="button is-solid accent-button raised is-fullwidth" style={{ marginBottom: 15 }}>{t('listing.cancelApp')}</a>

                            <a className="button is-solid accent-button raised is-fullwidth" style={{ marginBottom: 15 }}>{t('listing.apply')}</a>
                        </>
                    )}

                </div>
            </div>
        </JobPageStyled>
    )
};


const DisplayPDF = ({ data }) => {
    const { t } = useTranslation()
    return (
        <div style={{ width: 800, margin: "100px auto", padding: '1rem' }}>
            <h1 style={{ textAlign: 'center', fontSize: '2rem', textTransform: 'capitalize' }}>{data.title}</h1>
            <div className="post">
                <h2>{t('general.description')}</h2>
                {!data.jobSelect ? renderDesc(data?.description) : renderDesc(data.job[0].desc)}
            </div>
        </div>
    )
}

export default BatchComponent
