import React, { useContext } from 'react'
import { MyContext } from "../stores";
import useSWR from "swr";
import styled from 'styled-components'
import { useTranslation } from "react-i18next";
import Avatar from "react-avatar";
import { userRoles } from '../stores/enums/userRoles';
import LoadUsers from '../components/Dashbord/LoadUsers';
import LeaderBoard from '../components/Dashbord/LeaderBoard';
import Stats from '../components/Dashbord/Stats';

const Grid2Col = styled.div`
 display: flex;
 gap: 1rem; 
 padding-top: 2rem; 
`

export const SectionHeader = ({ title, category }) => (
    <div className=" mb-10">
        {category && <p className="text text-gray-400">{category}</p>}
        <p className={`${category ? 'text-lg ' : 'text-3xl'} font-extrabold tracking-tight text-slate-900`}>
            {title}
        </p>
    </div>
)

const DashboardComponent = () => {
    const { globalId, me, roleSql, organizationId, globalIdSql } = useContext(MyContext)
    const selectionsettings = { persistSelection: true };
    const { t } = useTranslation()


    const getUserDashboardEndpoint = (role) => {

        switch (role) {
            case userRoles.CMP_ADMIN_BUSINESS:
            case userRoles.SCH_ADMIN_SCHOOL:
                return "https://api.bazookka.com/stats/adminDashboard"
            case userRoles.MTR_ADMIN:
                return "https://api.bazookka.com/stats/dashboard"
            case userRoles.SCH_STUDENT:
            case userRoles.SCH_SEEKER:
                return "https://api.bazookka.com/stats/studentDashboard"
            default:
                return ""
        }
    }

    const topGrid = [
        {
            width: '50',
            headerText: 'Avatar',
            template: function Display(props) {
                return <Avatar name={props.display_name} size={40} round={true} />
            }
        },
        {
            headerText: t("forms.formGrid.nameColumn"),
            field: 'display_name',
            width: '200',
        },
        {
            headerText: t("ratingView.averageColumn"),
            field: 'average',
            width: '200',
            template: function Display(props) {
                return <div>{parseFloat(props.average).toFixed(2)}</div>
            }
        }
    ]

    const bestGrid = [
        {
            width: '50',
            headerText: 'Avatar',
            template: function Display(props) {
                return <Avatar name={props.display_name} size={40} round={true} />
            }
        },
        {
            headerText: t("forms.formGrid.nameColumn"),
            field: 'display_name',
            width: '200',
        },
        {
            headerText: t("dashboard.bestGrid.qualified"),
            field: 'total',
            width: '200',
        }
    ]

    const topJobsGrid = [
        {
            headerText: t("leaderboard.jobTitle"),
            field: 'title',
            width: '200',
        },
        {
            headerText: t("leaderboard.applicationsCount"),
            field: 'count',
            width: '200',
        }
    ]

    const { data: stats } = useSWR(getUserDashboardEndpoint(roleSql), url =>
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'eid': globalId,
                'id': organizationId,
                'studentId': globalIdSql,
                'role': roleSql
            }
        }).then(r => r.json()))

    const { data: users } = useSWR(`https://api.bazookka.com/stats/dashboard-users`, url =>
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'eid': globalId
            }
        }).then(r => r.json()))

    const { data: mentors } = useSWR(roleSql === userRoles.MTR_ADMIN ? `https://api.bazookka.com/stats/mentorats` : null, url =>
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'eid': globalId
            }
        }).then(r => r.json()))

    const { data: count} = useSWR(roleSql === userRoles.MTR_ADMIN ? `https://api.bazookka.com/users/rates/count` : null, url =>
        fetch(url, {
            method: 'get',
            headers: {
                'Content-type': 'application/json',
                'eid': globalId
            }
        }).then(r => r.json()))


    const getAdminsCountByRole = (role) => {
        switch (role) {
            case userRoles.MTR_ADMIN:
                return users ? users.filter(u => u.role === 'mtr.admin').length : 0
            case userRoles.CMP_ADMIN_BUSINESS:
                return stats?.nb_admins
            default:
                return 0
        }
    }


    const getStudentsCountByRole = (role) => {
        switch (role) {
            case userRoles.MTR_ADMIN:
                return users ? users.filter(u => u.role === 'mtr.kid').length : 0
            case userRoles.CMP_ADMIN_BUSINESS:
                return stats?.nb_students
            default:
                return 0
        }
    }

    const getIntershipsCountByRole = (role) => {
        switch (role) {
            case userRoles.MTR_ADMIN:
                return stats ? stats?.stages[0].count : 0
            case userRoles.SCH_ADMIN_SCHOOL:
                return stats ? stats?.nb_internship_objects : 0
            default:
                return 0
        }
    }

    return (
        <div className="mt-24 w-full">
            <SectionHeader title={t('general.dashboard')} />
            {
                <LoadUsers roleSql={roleSql}  count={users && users.length} />
            }

            <Grid2Col>
                <Stats 
                 count={stats?.nb_companies}
                 boxColor={"bg-orange-600"}
                 displayCondition={roleSql === userRoles.SCH_ADMIN_SCHOOL}
                 countColor={"text-white"}
                 title={t("leaderboard.numberOfInvitedCompany")}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-blue-200"}
                 icon={"fa-building"}
                   />

                <Stats 
                 count={stats ? stats.nb_users : 0}
                 boxColor={"bg-orange-600"}
                 displayCondition={ roleSql === userRoles.CMP_ADMIN_BUSINESS || roleSql === userRoles.MTR_ADMIN }
                 countColor={"text-white"}
                 title={t("dashboard.users")}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-orange-400"}
                 icon={"fa-user-plus"}
                   />

                <Stats 
                 count={getStudentsCountByRole(roleSql)}
                 boxColor={"bg-orange-600"}
                 displayCondition={roleSql === userRoles.CMP_ADMIN_BUSINESS || roleSql === userRoles.MTR_ADMIN}
                 countColor={"text-white"}
                 title={t('dashboard.students')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-blue-400"}
                 icon={"fa-users"}
                   />

                <Stats 
                 count={users && users.filter(u => u.role === 'mtr.mentor').length}
                 boxColor={"bg-orange-600"}
                 displayCondition={roleSql === userRoles.MTR_ADMIN}
                 countColor={"text-white"}
                 title={t('dashboard.mentors')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-blue-400"}
                 icon={"fa-user-plus"}
                   />

                <Stats 
                 count={getAdminsCountByRole(roleSql)}
                 boxColor={"bg-orange-600"}
                 displayCondition={roleSql === userRoles.CMP_ADMIN_BUSINESS || roleSql === userRoles.MTR_ADMIN }
                 countColor={"text-white"}
                 title={t('dashboard.admins')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-blue-400"}
                 icon={"fa-server"}
                   />

                <Stats 
                 count={stats?.nb_teachers}
                 boxColor={"bg-orange-600"}
                 displayCondition={  roleSql === userRoles.CMP_ADMIN_BUSINESS || roleSql === userRoles.SCH_ADMIN_SCHOOL }
                 countColor={"text-white"}
                 title={t('dashboard.teachers')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-blue-400"}
                 icon={"fa-users"}
                   />
            </Grid2Col>

            <Grid2Col>
                <Stats 
                 count={stats?.nb_jobs}
                 boxColor={"bg-blue-600"}
                 displayCondition={roleSql === userRoles.CMP_ADMIN_BUSINESS ||  roleSql === userRoles.SCH_ADMIN_SCHOOL}
                 countColor={"text-white"}
                 title={t('dashboard.jobs')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa fa-briefcase fa-briefcase"}
                   />
                <Stats 
                 count={stats ? stats?.nb_applications : 0}
                 boxColor={"bg-blue-600"}
                 displayCondition={roleSql === userRoles.CMP_ADMIN_BUSINESS }
                 countColor={"text-white"}
                 title={t('dashboard.applicationsCount')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa fa-briefcase fa-briefcase"}
                   />
                
                <Stats 
                 count={mentors && mentors.training}
                 boxColor={"bg-blue-600"}
                 displayCondition={roleSql === userRoles.MTR_ADMIN}
                 countColor={"text-white"}
                 title={t('dashboard.availableInternshipLabel')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa fa-briefcase"}
                   />

                <Stats 
                 count={mentors && mentors.spots}
                 boxColor={"bg-blue-600"}
                 displayCondition={roleSql === userRoles.MTR_ADMIN }
                 countColor={"text-white"}
                 title={t('dashboard.availableSpot')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa fa-briefcase"}
                   />

                <Stats 
                 count={getIntershipsCountByRole(roleSql)}
                 boxColor={"bg-blue-600"}
                 displayCondition={roleSql === userRoles.MTR_ADMIN || roleSql === userRoles.SCH_ADMIN_SCHOOL}
                 countColor={"text-white"}
                 title={t('dashboard.internships')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa fa-briefcase"}
                   />
            </Grid2Col>

            <div className="flex flex-wrap mb-2">
                <Stats 
                 count={count && count.count[0].count}
                 boxColor={"bg-red-700"}
                 displayCondition={roleSql === userRoles.MTR_ADMIN}
                 countColor={"text-white"}
                 title={t('dashboard.noMatch')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa-warning"}
                   />
                <Stats 
                 count={stats && stats.count}
                 boxColor={"bg-blue-600"}
                 displayCondition={roleSql === userRoles.MTR_ADMIN}
                 countColor={"text-white"}
                 title={t('dashboard.selectedUsers')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa-check-square"}
                   />
            </div>

            <div className="flex flex-wrap mb-2">
                <Stats 
                 count={stats && stats.nb_internship_in_progess}
                 boxColor={"bg-green-600"}
                 displayCondition={roleSql === userRoles.SCH_STUDENT}
                 countColor={"text-white"}
                 title={t('dashboard.internshipInApplications')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa-warning"}
                   />

                <Stats 
                 count={stats && stats.nb_internship_application}
                 boxColor={"bg-green-600"}
                 displayCondition={roleSql === userRoles.SCH_STUDENT}
                 countColor={"text-white"}
                 title={t('dashboard.internshipInPogress')}
                 symbol={"fa-caret-up"}
                 symbolColor={"text-white"}
                 icon={"fa-check-square"}
                   />
            </div>
            {roleSql === userRoles.MTR_ADMIN && <Grid2Col>
                <LeaderBoard title={t('leaderboard.bestAvgTitle')} category={t('leaderboard.top10Category')} pageCount={5} columnsDefinition={topGrid} selectionSettings={selectionsettings} dataSource={stats && stats.tops} enableHover={false} />
                <LeaderBoard title={t('leaderboard.qualifiedTitle')} category={t('leaderboard.top10Category')} pageCount={5} columnsDefinition={bestGrid} selectionSettings={selectionsettings} dataSource={stats && stats.bestmatch} enableHover={false} />
            </Grid2Col>}

            {roleSql === userRoles.CMP_ADMIN_BUSINESS && <Grid2Col>
                <LeaderBoard title={t('leaderboard.mostAppliedJobs')} category={t('leaderboard.top10Category')} pageCount={5} columnsDefinition={topJobsGrid} selectionSettings={selectionsettings} dataSource={stats && stats?.applicationPerJob} enableHover={false} />

            </Grid2Col>}
        </div>

    )
}

export default DashboardComponent