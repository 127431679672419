import React, { useContext, useEffect, useState } from 'react'
import { MyContext } from "../stores";
import { Field, Form, Formik } from "formik";
import { activeForms, groupsDoc, firebaseInstance, firestore, usersDoc } from "../firestore/firestoreService";
import { ToggleSharingOptions } from "../components/FormManager/ToggleElements";
import { CustomSelect } from "../components/FormElements";
import ModalWrapper from "./index";
import styled from 'styled-components'
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Grid, Tooltip, tooltipClasses } from '@mui/material';
import ReactTooltip from 'react-tooltip';
import useSWR from 'swr';
import bzkFetcher from "../utils/bzkFetcher";

const OverLayStyle = styled.div`
  height: max-content;
  min-height: 400px;
`

export default function FormInviteModal({ template, closeOut, formUsers, options, groups }) {
    const { me, globalId, globalIdSql } = useContext(MyContext);

    const [sharedWithUsersId, setSharedWithUsersId] = useState([])
    const [sharedWithUsers, setSharedWithUsers] = useState([])
    const [notSharedWithUsers,setNotSharedWithUsers] = useState([])
    const [sharedWithGroupsId, setSharedWithGroupsId] = useState([])
    const [sharedWithGroups, setSharedWithGroups] = useState([])
    const [notSharedWithGroups,setNotSharedWithGroups] = useState([])

    const [toggle, setToggle] = useState("user");
    const [api, setApi] = useState(true)
    const { t } = useTranslation()
    const alert = useAlert()

    const useSharedFormUsers = (formId, userId) => {
        //Use Condition for the URL arg to make SWR wait for necessary data to do the request
        const {data, error} = useSWR(
            (formId && userId)
                ? [`https://api.bazookka.com/shares/post/${formId}/users/`, userId]
                : null
            , bzkFetcher
        );

        return {
            sharedFormUsersData: data,
            sharedFormUsersIsLoading: !error && !data,
            sharedFormUsersIsError: error
        }
    }

    const useSharedFormGroups = (formId, userId) => {
        //Use Condition for the URL arg to make SWR wait for necessary data to do the request
        const {data, error} = useSWR(
            (formId && userId)
                ? [`https://api.bazookka.com/shares/post/${formId}/groups/`, userId]
                : null
            , bzkFetcher
        );

        return {
            sharedFormGroupsData: data,
            sharedFormGroupsIsLoading: !error && !data,
            sharedFormGroupsIsError: error
        }
    }

    const saveUserShare = (eid, formId, userId , sharedById) => {
        return fetch("https://api.bazookka.com/shares/", {
            headers: {
                'Content-Type': 'application/json',
                'eid': eid
            },
            method: 'post',
            body: JSON.stringify({
                post_id: formId,  
                user_id: userId,
                share_by_id: sharedById,
            })
        })
    }

    const saveGroupShare = (eid, formId, groupId , sharedById) => {
        return fetch("https://api.bazookka.com/shares/", {
            headers: {
                'Content-Type': 'application/json',
                'eid': eid
            },
            method: 'post',
            body: JSON.stringify({
                post_id: formId,  
                group_id: groupId,
                share_by_id: sharedById,
            })
        })
    }

    useEffect(() => {
        if(options){
            let filteredOptions = options.filter(option => !sharedWithUsersId.includes(option.id))
            setNotSharedWithUsers(filteredOptions)
        }
    },[options, sharedWithUsersId])
    
    useEffect(() => {
        let filteredGroups = groups.filter(option => !sharedWithGroupsId.includes(option.id))
        setNotSharedWithGroups(filteredGroups)
    },[groups, sharedWithGroupsId])

    const { sharedFormUsersData } = useSharedFormUsers(template.id, globalId);
    const { sharedFormGroupsData } = useSharedFormGroups(template.id, globalId);

    useEffect(() => {
        if(sharedFormUsersData){
            const sharedUserIds = sharedFormUsersData.map( user => {
                return user.user_id
            })
            setSharedWithUsersId(sharedUserIds)
            setSharedWithUsers(sharedFormUsersData);
        }
    }, [sharedFormUsersData])

    useEffect(() => {
      if(sharedFormGroupsData){
        const sharedGroupIds = sharedFormGroupsData.map( group => {
            return group.group_id
        })
        setSharedWithGroupsId(sharedGroupIds)
        setSharedWithGroups(sharedFormGroupsData);
      }
    
    }, [sharedFormGroupsData])

    return (
        <ModalWrapper>
            <OverLayStyle>
                <Formik
                    initialValues={{ user: "" }}
                    onSubmit={async (values, { setSubmitting }) => {
                        console.log("SUBMIT VALUES", values);
                        const tempUser = values.user || []
                        const tempGroup = values.group || []
                        const userPromises = tempUser.map(userId => {
                            return saveUserShare(globalId, template.id, userId, globalIdSql);
                        })
                        const groupPromises = tempGroup.map(groupId => {
                            return saveGroupShare(globalId, template.id, groupId, globalIdSql);
                        })

                        Promise.all([...userPromises, ...groupPromises]).then(response => {
                            alert.success(t("customForm.formShared"))
                            console.log("All Saved");
                            closeOut()
                        }).catch(e => {
                            alert.error(t("customForm.formShared"))
                            console.log(e)
                            closeOut()
                        })
                        
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="field">
                                <ToggleSharingOptions
                                    toggle={toggle}
                                    setToggle={setToggle}
                                    translator={t}
                                ></ToggleSharingOptions>
                            </div>
                            <div className="field">
                                {toggle === "user" ? (
                                    <Field
                                        name="user"
                                        options={notSharedWithUsers}
                                        component={CustomSelect}
                                        placeholder={t("general.documents.usersLabel")}
                                        isMulti={true}
                                    />
                                ) : (
                                    <Field
                                        name="group"
                                        options={notSharedWithGroups}
                                        component={CustomSelect}
                                        placeholder={t("general.documents.groupsLabel")}
                                        isMulti={true}
                                    />
                                )}
                            </div>

                            <Grid container sx={{ alignItems:"center", justifyContent:"center"}}>
                                {
                                toggle === "group" && sharedWithGroups && sharedWithGroups.length > 0 && sharedWithGroups.map((group, key) => {
                                    return group && <Grid key={key} item sx={{margin : "0 10px 10px 0"}} >
                                            <Avatar name={group.name} data-tip={group.name} src={group.meta.cover} />
                                            <ReactTooltip />
                                    </Grid>
                                })}
                                {
                                toggle === "user" && sharedWithUsers && sharedWithUsers.length > 0 && sharedWithUsers.map((user, key) => {
                                    return user && <Grid key={key} item sx={{margin : "0 10px 10px 0"}} >
                                            <Avatar name={user.display_name} data-tip={user.display_name} src={user.avatar} />
                                            <ReactTooltip />
                                    </Grid>
                                })}
                            </Grid>

                            <div className="field">
                                <button
                                    type="submit"
                                    className="button is-solid primary-button is-fullwidth"
                                    disabled={isSubmitting}
                                >
                                    {t('general.send')}
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </OverLayStyle>
        </ModalWrapper>
    );
};
