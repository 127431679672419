import React, {useContext} from 'react'
import {MyContext} from "../stores";
import {ScheduleComponent, ResourcesDirective, ResourceDirective, ViewsDirective, ViewDirective, DragAndDrop, Day, Week, Month, Inject, Agenda, Resize} from '@syncfusion/ej2-react-schedule'
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars'
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { Query } from '@syncfusion/ej2-data';
import useSWR from 'swr'
import Loader from "../components/Loader"
import { L10n, loadCldr } from '@syncfusion/ej2-base';

loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/fr/ca-gregorian.json'),
    require('cldr-data/main/fr/numbers.json'),
    require('cldr-data/main/fr/timeZoneNames.json'),
    require('cldr-data/main/en/ca-gregorian.json'),
    require('cldr-data/main/en/numbers.json'),
    require('cldr-data/main/en/timeZoneNames.json')
    );
  

const CalendarPage  = () => {
    const {me, globalId, langChoice} = useContext(MyContext)
    langChoice === 'fr' ?  L10n.load(require('@syncfusion/ej2-locale/src/fr.json')) : L10n.load(require('@syncfusion/ej2-locale/src/en-US.json'))
    const {data, error} = useSWR(`https://api.bazookka.com/calendars`,
            url => fetch(url, {method: 'get', headers: {
                'Content-Type': 'application/json',
                eid: globalId
                }}).then(r =>{
                    return r.json()
            }))

    const {data : users, error: user_error} = useSWR(`http://localhost`)

    if(!data) return <Loader />

    async function popupEvent(props) {

    }
    function editorTemplate(props){
        console.log("my edit", props)
        return (
            <table className="custom-event-editor" style={{ width: '100%' }} cellPadding={5}><tbody>
            <tr><td className="e-textlabel">Titre</td><td colSpan={4}>
                <input id="Summary" className="e-field e-input" type="text" name="Subject" style={{ width: '100%' }} />
            </td></tr>
            <tr><td className="e-textlabel">Participants</td><td colSpan={4}>
                {props?.users?.map(user => {
                    return (
                        <div style={{display: "flex", padding: 2, justifyContent:"space-between", border: "1px solid white"}}>
                            <div>{user.display_name}</div>
                            <a href="" style={{display: "block"}} onClick={e => {
                                e.preventDefault()
                                fetch(`https://api.bazookka.com/calendars/p/${props.id}/${user.id}`, {method: 'delete'})
                                    .then(r => r.json).then(r => console.log(r))
                            }}>X</a>
                        </div>

                    )
                })}
                <MultiSelectComponent
                    id="Users"
                    placeholder="Ajouter des participants"
                    data-name='Users'
                    className="e-field" style={{ width: '100%' }}
                    dataSource={data.select}
                    fields={{text: 'display_name', value: 'id'}}
                    allowFiltering={true}
                    value={Array.isArray(props?.Users) && props.Users.map( v =>{
                        console.log("array =>", v.id)
                        return v.id
                    })}
                >

                </MultiSelectComponent>
                {/*<DropDownListComponent id="EventType" placeholder='Choose status' data-name='EventType' className="e-field" style={{ width: '100%' }}*/}
                {/*                       dataSource={['New', 'Requested', 'Confirmed']}>*/}
                {/*</DropDownListComponent>*/}
            </td></tr>
            <tr><td className="e-textlabel">De</td><td colSpan={4}>
                <DateTimePickerComponent id="StartTime" format='dd/MM/yy hh:mm a' data-name="StartTime" value={new Date(props.startTime || props.StartTime)}
                                         className="e-field"></DateTimePickerComponent>
            </td></tr>
            <tr><td className="e-textlabel">À</td><td colSpan={4}>
                <DateTimePickerComponent id="EndTime" format='dd/MM/yy hh:mm a' data-name="EndTime" value={new Date(props.endTime || props.EndTime)}
                                         className="e-field"></DateTimePickerComponent>
            </td></tr>
            <tr><td className="e-textlabel">Description</td><td colSpan={4}>
        <textarea id="Description" className="e-field e-input" name="Description" rows={3} cols={50}
                  style={{ width: '100%', height: '60px !important', resize: 'vertical' }}></textarea>
            </td></tr></tbody></table >
        )
    }

    return (
        <div className="bg-white p-2 rounded shadow">
            <ScheduleComponent
              locale={langChoice === "fr" ? "fr" : "en-US"}
             actionComplete={ data => {
                 console.log(data)
                 if(data.requestType === "eventCreated") {
                     let created = data.addedRecords[0]
                     fetch('https://api.bazookka.com/calendars', {method: "post",
                         headers: {"Content-Type":"application/json"},
                         body: JSON.stringify({
                             title: created.Subject,
                             content: created.Description,
                             participants: created.Users,
                             meta: {
                                 startDate: created.StartTime,
                                 endDate: created.EndTime
                             },
                             me: globalId
                         })}).then( r => r.json()).then(r => console.log(r))
                 }

                 if(data.requestType === "eventChanged") {
                     let changed = data.changedRecords[0]
                     fetch('https://api.bazookka.com/calendars', {method: "put",
                         headers: {"Content-Type":"application/json"},
                         body: JSON.stringify({
                             title: changed.Subject,
                             id: changed.id,
                             content: changed.Description,
                             participants: changed.Users,
                             meta: {
                                 startDate: changed.StartTime,
                                 endDate: changed.EndTime
                             },
                             me: globalId
                         })}).then( r => r.json()).then(r => console.log(r))
                 }

                 if(data.requestType === 'eventRemoved') {
                     let changed = data.deletedRecords[0]
                     fetch(`https://api.bazookka.com/calendars/${changed.id}`, {method: "delete",
                         headers: {"Content-Type":"application/json", eid: globalId},
                    }).then( r => r.json()).then(r => console.log(r))
                 }

             }}
             height="650px"
             editorTemplate={editorTemplate}
             showQuickInfo={false} popupOpen={popupEvent}
             eventSettings={{
                 dataSource: data.data.filter( d => (d.meta.date !== undefined || d.meta.startDate !== undefined) && d.meta.endDate !== undefined).map( (event, i) => {
                     event.Id = i+1
                     event.Subject = event.title
                     event.StartTime = new Date(event.meta.date || (event.meta.startDate instanceof Object && event.meta.startDate._seconds * 1000) || event.meta.startDate)
                     event.EndTime = new Date(event.meta.endDate)
                     event.Description = event.content
                     event.Users = event.users || []
                    return event;
                 })
             }}
            >

                <Inject services={[Day, Week, Month, Agenda, Resize, DragAndDrop]} />
            </ScheduleComponent>

        </div>
    )
}



export default CalendarPage