import React, {useState, useContext, useEffect} from "react";
import {MyContext} from "../stores";
import {Form, Formik, Field, ErrorMessage} from 'formik'
import {format} from 'date-fns'
import {InputDate, InputText, SwitchBox, TextAreaInput} from "./FormElements";
import {jobPosts, firestore} from "../firestore/firestoreService";
import {Link, useHistory} from 'react-router-dom'
import { useAlert } from "react-alert";


export const FormTemplate = ({data, me}) => {
    const {langChoice, globalId} = useContext(MyContext)
    const alert = useAlert()
    const history = useHistory()
    let initialValues = data && {...data} || {
        title: '',
        needed: 1,
        tiny: '',
        desc: '',
        active: false,
        startDate: new Date(),
        endDate: new Date(),
        category: []
    };

    const onSubmit = (data, {setSubmitting}) => {
        try{
            data.startDate = new Date(data.startDate).getTime()
        }catch(e){}
        try{
            data.endDate = new Date(data.endDate).getTime()
        }catch(e){}
        if(data.id) {
            jobPosts.doc(data.id).update(data).then(r => {
                setSubmitting(false)
                setTimeout(() => {
                    history.goBack()
                }, 500)
            })
        }else{
            data.authorId = firestore.FieldValue.arrayUnion(globalId);
            data.author = {
                id: globalId,
                displayName: me.displayName
            }
            data.businessId = firestore.FieldValue.arrayUnion(me.select.id)
            jobPosts.add(data).then( r => {
                setSubmitting(false)
                setTimeout(() => {
                    history.goBack()
                }, 500)
            }).catch( e => {
                alert.error(e.message)
            })
        }
    }

    const returnBack = (e, cleanForm) => {
        e.preventDefault()
        cleanForm()
        history.goBack()
    }

    useEffect(()=> {}, [])

    return (
        <div className="container">
            <div style={{
                textAlign: 'center',
                backgroundColor: 'white',
                padding: 15,
                borderRadius: 10,
                border: '1px solid #f4f4f4'
            }}>
                <ul>
                    <li>Edit Job</li>
                </ul>

            </div>
            <div style={{
                backgroundColor: 'white',
                padding: 15,
                borderRadius: 10,
                border: '1px solid #f4f4f4'
            }}>
                <Formik  initialValues={{...initialValues}} onSubmit={onSubmit}>
                    {({values, isSubmitting, resetForm}) => {
                        return (
                            <Form>
                                <div className="settings-form-wrapper">
                                    <div className="settings-form">
                                        <div style={{width: '95%'}}>
                                            <div className="field">
                                                <InputText
                                                    label={ langChoice === 'fr'? 'titre': 'title'}
                                                    value={values.title}
                                                    name="title" />
                                            </div>
                                            <div className="field">
                                                <InputText
                                                    label={ langChoice === 'fr'? 'Petit résumé': 'Little summary'}
                                                    name="tiny" />
                                            </div>
                                            <div className="field">
                                                <InputDate
                                                    label={langChoice === 'fr'? 'Date de début':'Start date'}
                                                    value={format(new Date(values.startDate), 'dd/MM/yyyy')}
                                                    name="startDate"
                                                />
                                            </div>
                                            <div className="field">
                                                <InputDate
                                                    label={langChoice === 'fr'? 'Date de fin':'End date'}
                                                    value={format(new Date(values.endDate), 'dd/MM/yyyy')}
                                                    name="endDate"
                                                />
                                            </div>
                                            <div className="field">
                                                <SwitchBox
                                                    label={langChoice === 'fr' ? 'Actif': 'Active'}
                                                    name="active"
                                                />
                                            </div>
                                            <div className="field">
                                                <TextAreaInput
                                                    label={ 'description'}
                                                    name="desc"
                                                />
                                            </div>
                                            <div className="field" style={{display:'flex', justifyContent: 'space-between'}}>
                                                <button type="submit" className="button is-primary" disabled={isSubmitting}>{langChoice === 'fr' ? 'sauvegarder': 'save'}</button>
                                                <button onClick={(e) => returnBack(e, resetForm)} className="button is-danger">{langChoice === 'fr' ? 'ne pas sauvegarder': 'do not save'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>

        </div>

    )
}


export default FormTemplate
