import React, {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import {useHistory} from "react-router-dom"
import {contactsDocs, postsDoc} from "../firestore/firestoreService";
import {MyContext} from "../stores";
import styled from "styled-components";
import Swal from 'sweetalert2';
import {usePromise} from "../helper";


const OverviewContent = styled.div`
    .columns {
      display: flex;
    }
    .flex-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 8px;
      border: 1px solid black;
      margin: 8px 0px;
      grid-gap: 8px;
      align-items: center;
    }
    .go-button {
      display: flex;
      align-items: center;
    }
`

export const LineBreak = styled.div`
    width:100%;
    clear:both;
  `

export const Heading = styled.div`
    flex-wrap: wrap;
    transition: all 0.35s ease-out;
`

export const FormContainer = styled.div`
    width:100%;
    margin-top:30px;
    padding:5px;
`

const JobContainer = styled.div`
    height: 58px;
    margin: 1rem 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #e8e8e8;
    background: white;
    cursor: pointer;

    &:hover, &.selected{
        background-color:#344258;
        color:white;
    }
`

const ApplicationListContainer = styled.div`
    padding-top:30px;
`

const CrudUserCard = styled.div`
  display: flex;
  align-items: center;
  transition: all .3s;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: white;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
  .flex-block-meta {
    margin-left: 10px;
  }
  .go-button {
    background-color: var(--bazookka-blue);
    width: 42px;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    margin-left: 1rem;
    margin-right: 1rem;
    box-shadow: 0 14px 26px -12px rgb(85 150 230 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(85 150 230 / 20%) !important;

  }
  :hover .go-button {
    opacity: 1;
    transform: rotate(360deg);
    transition: all .3s;
  }
`

export default function Applications() {
    const {me, dispatchModal} = useContext(MyContext)
    const {t} = useTranslation()
    const [jobs, errorJobs, loadJobs] = usePromise(async ()=> {
        let doc;
        let [jbs, intr] = await Promise.all([
            postsDoc.where('businessId', 'array-contains', me.select.id)
                .where('type', '==', 'jobs').get(),
            postsDoc.where('businessId', 'array-contains', me.select.id)
                .where('type', '==', 'internships').get()

        ])
        doc = jbs.empty ?  [...[]] : [...jbs.docs]
        doc = intr.empty ?  [...doc, ...[]] : [...doc, ...intr.docs]
        return doc.map( d => Object.assign({id: d.id, ...d.data()}))

    }, [])
    const [applicants, setApplicants] = useState([])
    const [apicall, setApicall] = useState(true)
    const [selectedJob, setSelectedJob] = useState(null)

    const seeList = async job => {
        setSelectedJob(job.id);

        let documents = await postsDoc.doc(job.id).collection('applications')
            .get()

        setApplicants(documents.docs.map( doc => Object.assign({id: doc.id, ...doc.data()})))
        dispatchModal({
            type: 'OPEN_MODAL',
            payload: {
                modalType: 'UsersListModal',
                modalProps: {
                    title: t('general.applicationList'),
                    ico: <i className="mdi mdi-account" />,
                    data: documents.docs.map( doc => Object.assign({id: doc.id, ...doc.data()})),
                    closeOut: ()=> dispatchModal({type: 'CLOSE_MODAL'}),
                    empty: documents.empty,
                    message: t("general.noapplicants"),
                    Applicants: Applicants

                }
            }
        })
    }

 

    return (
        <OverviewContent id="overview-content" className="content-section is-active">
            <h2 style={{fontSize: '2rem'}}>{t('general.joblist')}</h2>
            <div className="columns">
                <div className="column">
                {jobs && jobs.map( (job, key) => {
                    const selectedClass = selectedJob === job.id ? 'selected' : '';
                    return (
                            <JobContainer className={selectedClass} key={key} onClick={() => seeList(job)}>
                                {/*<img src="assets/old/wallpaper.png" data-demo-src="assets/img/icons/logos/slicer.svg"*/}
                                {/*     alt="" data-page-popover="4"/>*/}
                                <div className="flex-block-meta">
                                    <span>{job.title}</span>

                                </div>
                            </JobContainer>

                    )
                })}
                </div>
                {/*{selectedJob && */}
                {/*    <ApplicationListContainer class="column">*/}
                {/*        <div class="about-summary">*/}
                {/*            <div class="content">*/}
                {/*                <h3>{t('general.applicationList')}</h3>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        {applicants.length ? */}
                {/*            applicants.map((appl, key) => {*/}
                {/*                return <Applicants key={key} appl={appl} skey={true}></Applicants>*/}
                {/*            })*/}
                {/*            :*/}
                {/*            <span>{t("general.noapplicants")}</span>*/}
                {/*        }*/}
                {/*    </ApplicationListContainer>*/}
                {/*}*/}
            </div>
        </OverviewContent>
    )
}

export const Applicants = ({appl, viewmore, type, skey}) => {
    const {dispatchModal, me, setMaincontact, globalId} = useContext(MyContext)
    let url = type === 'user' ? `/user/`:`/settings/applications/`
    let history = useHistory()

    const openChat = async (user) => {
        let contact = [globalId, user.id].sort().join("_")
        const doExist = await contactsDocs
            .where('contact', '==', contact)
            .get()
        if(doExist.empty) {
            await contactsDocs.add({
                type: 'users',
                users: [
                    {id: globalId, ...me},
                    user
                ],
                usersIds: [
                    globalId,
                    user.id
                ],
                contact
            })
        } else {

        }
        setMaincontact(user)
        let info = await contactsDocs.where('contact', '==', contact).limit(1).get()
        if(!info.empty) {
            history.push(`/chat/${info.docs[0].id}`)
        }
        dispatchModal({type: "CLOSE_MODAL"})
    }

    const onSelect = (user, job) => {

    }

    const editUserRole = user => {
        dispatchModal({
            type: 'OPEN_MODAL',
            payload: {
                modalType: 'EditUserRoleModal',
                modalProps:{
                    backgroundColor: 'white',
                    closeColor: 'black',
                    closed: ()=> dispatchModal({type: 'CLOSE_MODAL'}),
                    user: user
                }
            }
        })
    }

    const onSee = () => {
        history.push(`${url}${appl.id}`)
        dispatchModal({type: "CLOSE_MODAL"})
    }

    const seeUser = user => {
        dispatchModal({
            type: 'OPEN_MODAL',
            payload: {
                modalType: 'PreviewUser',
                modalProps:{
                    backgroundColor: 'white',
                    closeColor: 'black',
                    closed: ()=> dispatchModal({type: 'CLOSE_MODAL'}),
                    user: user
                }
            }
        })
    }
    return (
        <CrudUserCard className="flex-block" >
            <img src="assets/old/wallpaper.png" data-demo-src="assets/img/icons/logos/slicer.svg"
                 alt="" data-page-popover="4"/>
            <div className="flex-block-meta">
                <span>{appl.displayName}</span>
                { viewmore && <a className="action-link">View list</a> }

            </div>
            {type === 'invite' &&  <div className="go-button" onClick={ ()=> openChat(appl)}>
                <i className="mdi mdi-send" style={{color: 'white'}}></i>
            </div>}
            {type==='apply' &&  <div className="go-button" onClick={ ()=> onSelect(appl)}>
                <i className="mdi mdi-plus" style={{color: 'white'}}></i>
            </div>}
            {type !== 'invite' &&
            (
                <React.Fragment>
                    <div className="go-button" onClick={ ()=> openChat(appl)}>
                        <i className="mdi mdi-chat" style={{color: 'white'}}></i>
                    </div>

                    <div className="go-button" onClick={onSee}>
                        <i className="mdi mdi-eye" style={{color: 'white'}}></i>
                    </div>
                    {!skey &&
                        <div className="go-button" onClick={() => editUserRole(appl)}>
                            <i className="mdi mdi-key-outline" style={{color: 'white'}}></i>
                        </div>
                    }
                </React.Fragment>
            )
            }

        </CrudUserCard>
    )
}

const StyleJobCrud = styled.div`
  margin: 1rem 0px;
  align-items: center;
  transition: all .3s;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: white;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;

  }
  .template {
    width: 100%;
    display: grid;
    grid-template-columns: 50px auto 110px;
    align-items: center;
  }
  .flex-block-meta {
    margin-left: 10px;
  }
  .div-button {
    background-color: var(--bazookka-green);
    cursor: pointer;
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
  }
  .titletext {
    display: flex;
    padding-left: 1rem;
    align-items: center;
  }
  .go-button {
    cursor: pointer;
    background-color: var(--bazookka-blue);
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 14px 26px -12px rgb(85 150 230 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(85 150 230 / 20%) !important;

  }
  :hover .go-button {
    opacity: 1;
    transform: rotate(360deg);
    transition: all .3s;
  }`
export const JobIntershipCrud = ({post, type}) => {
    const [create, setCreate] = useState(false)
    const {dispatchModal} = useContext(MyContext)
    const onDelete = ( elem ) => {
        Swal.fire({
            title: 'Do you want to save the changes?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Save`,
            denyButtonText: `Don't save`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                postsDoc.doc(elem.id).delete().then( d => {
                    Swal.fire('Saved!', '', 'success')
                })

            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    return (
        <StyleJobCrud>
            <div className="template">
                <div>
                    <div className="div-button">
                        <i className="mdi mdi-briefcase-edit"></i>
                    </div>

                </div>
                <div className="titletext">{post.title}</div>
                <div>
                    <div className="go-button float-left" onClick={ e => {
                        e.preventDefault()
                        dispatchModal({
                            type: 'OPEN_MODAL',
                            payload: {
                                modalType: 'EditJobModal',
                                modalProps: {
                                    edit: true,
                                    job: post,
                                    type
                                }
                            }
                        })
                    }}>
                        <i className="mdi mdi-pencil"></i>
                    </div>
                    <div className="go-button float-left" onClick={()=> {
                        onDelete(post)
                    }}>
                        <i className="mdi mdi-delete"></i>
                    </div>
                </div>
            </div>
        </StyleJobCrud>
    )
}

export const InviteListItems = ({inv, key, values}) => {
    const remove = () => {
        values.invites = values.invites.filter( v => v !== inv)
    }
    return (
        <StyleJobCrud>
            <div className="template">
                <div>
                    <div className="div-button">
                        <i className="mdi mdi-briefcase-edit"></i>
                    </div>

                </div>
                <div className="titletext">{inv}</div>
                <div>
                    <div className="go-button float-left" onClick={() => remove()}>
                        <i className="mdi mdi-delete"></i>
                    </div>
                </div>
            </div>
        </StyleJobCrud>
    )
}
