import { Form, Formik, withFormik } from "formik";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import {Save as SaveIcon, KeyboardReturn} from "@mui/icons-material";
import {Button} from "@mui/material";
import styled from "styled-components";
import SignaturePad from 'react-signature-pad-wrapper'
import { InputText } from "../components/FormElements";
import {
    activeForms,
    formResponses,
    firestore, formTemplates
} from "../firestore/firestoreService";
import { MyContext } from "../stores";
import {ResponseForm, QuestionStyles} from "./formGenerator/Preview";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";
import i18n from 'i18next';
import useSWR from 'swr';
import bzkFetcher from "../utils/bzkFetcher";


const FormStyled = styled.div`
  display: grid;
  width: 100%;
  max-width: 800px;
  margin: auto;
  flex-direction: column;
  min-height: 400px;
  justify-content: left;
  h2 {
    font-size: 2rem;
    text-align: center;
  }
  .return {
    display: flex;
    cursor: pointer;
    margin: 20px auto;
    width: 200px;
    color: white;
    background: var(--bazookka-green);
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    font-size: 1.5rem;
    
    &:hover {
      filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.3));
      width: 205px;
      transition: all 100ms;
    }
  }
`;

export const FormPageSimple = ( {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                }) => {
  const { globalId, globalIdSql, me } = useContext(MyContext);
  const { slug } = useParams();
  const signatureRef = useRef()
  const history = useHistory()
  const [form, setForm] = useState();
  const [answered, setAnswered] = useState();
  const [formAnswers, setFormAnswers] = useState([]);
  const [status, setStatus] = useState('load')
  const {t} = useTranslation()
  const translation = t;
  const onClose = () => {
      history.goBack()
  }

const useForm = (formId, userId) => {
    //Use Condition for the URL arg to make SWR wait for necessary data to do the request
    const {data, error} = useSWR(
        (formId && userId)
            ? [`https://api.bazookka.com/forms/${formId}`, userId]
            : null
        , bzkFetcher
);

    return {
        formData: data,
        formIsLoading: !error && !data,
        formIsError: error
    }
}

const useUserAnswers = (formId, userIdSql, userId) => {
    //Use Condition for the URL arg to make SWR wait for necessary data to do the request
    const {data, error} = useSWR(
        (formId && userId)
            ? [`https://api.bazookka.com/forms/answers/${formId}/user/${userIdSql}`, userId]
            : null
        , bzkFetcher
    );
    console.log("USE USER ANSWERS", data);

    return {
        userAnswerData: data,
        userAnswerIsLoading: !error && !data,
        userAnswerIsError: error
    }
}

const { formData } = useForm(slug, globalId);

const { userAnswerData } = useUserAnswers(slug, globalIdSql, globalId);

//Load le formulaire ici
useEffect(() => {
    setForm(formData);
  }, [formData]);

  useEffect(() => {
    values.slug = slug;
    values.globalId = globalId;
    values.globalIdSql = globalIdSql;
    values.formId = form?.id;
    values.users = {id: globalId, displayName: me?.displayName, avatar: me?.avatar}
    values.answers = {}
    values.createdAt = firestore.FieldValue.serverTimestamp()
  }, [form, globalId]);

  //Slug == idFormulaire
  useEffect(()=> {
    if(userAnswerData){
        setStatus('completed');
    }else{
        setStatus('form');
    }
  }, [userAnswerData])

  switch(status){
      case 'load':
          return <Loader />
      break;
      case 'form':
          return (
              <div className="view-wrapper">
                  <FormStyled>
                      <form onSubmit={handleSubmit}>
                          <h2>{form && form.name}</h2>
                          {form && form.content.map( (ques, i) => {
                              return (
                                  <QuestionStyles key={i} className="form-questions">
                                      <h3>{i+1}. {ques.questionText}</h3>
                                      <ResponseForm type={ques.questionType} content={ques.content} options={ques.options} questions={ques.questionText} answers={values.answers} setFieldValue={setFieldValue} />

                                  </QuestionStyles>
                              )
                          })}
                          {form?.signature && (
                              <SignaturePad
                                  ref={signatureRef}
                                  penColor="black"
                                  style={{width: 500, height: 300, border: "1px solid black"}}
                              ></SignaturePad>
                          ) }
                          <Button type="submit" variant="outlined" startIcon={<SaveIcon />} disabled={isSubmitting}>
                              {t("general.savebasic")}
                          </Button>
                      </form>
                  </FormStyled>
              </div>
          )
      break;
      case 'completed':
          return (
              <div className="view-wrapper">
                  <FormStyled>
                      <h2>Vous avez déjà rempli ce formulaire</h2>
                      <Button>
                          <KeyboardReturn
                              onClick={onClose}
                          ></KeyboardReturn>
                      </Button>
                  </FormStyled>
              </div>
          )
      break;
      default:
          return <Loader />
  }
};


export const FormPage = withFormik({


    handleSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true)

        if(!values.users.avatar) {
            delete values.users.avatar
        }
        try {
            const {c, error} = fetch("https://api.bazookka.com/forms/answers/", {
                headers: {
                    'Content-Type': 'application/json',
                    'eid': values.globalId
                },
                method: 'post',
                body: JSON.stringify({
                    post_id:values.slug,  
                    user_id: values.globalIdSql,
                    answers: values.answers,
                })
            }).then(r => r.json());
            alert(i18n.t('general.answersSaved'))
            window.location.reload()

        }catch(e) {
            alert(`${i18n.t("general.error")}: ${e.message}`)
        }

        setSubmitting(false)
    },
})(FormPageSimple)

