import React, { useState, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from "formik";
import styled from 'styled-components'
import { MyContext } from "../../stores";
import {  InputText, TextAreaInput, SwitchBox } from "../../components/FormElements";
import { Multiselect } from "multiselect-react-dropdown";
import { useCatReducer } from "../../stores/categories";
import { db, firebaseInstance } from "../../firestore/firestoreService";
import { useDeBounce, useQuery } from "../../helper";
import ReactSelect from 'react-select'
import { isNoDataNeedDeepInfo } from "../../helper/acl";
import { ProfileBusinessForm } from "../Settings/Settings";
import * as Yup from 'yup'
import axios from 'axios';
import { useAlert } from 'react-alert';

export const FormSignUpStyled = styled.div`
  border-radius: 1rem;
  background: white;
  display: flex;
  justify-content: center;
  width: 800px;
  
  border: 1px solid #e0e0e0;
  height: auto;
  margin: 2rem auto;
  padding: 3rem 0;
  h2 {
    font-size: 2rem;
    text-align: center;
  }
  form {
    width: 100%;
    padding: 2rem;
  }
  .column {
    width: 100%;
  }
  .split {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

export default function Registration({ token, t,tokenInfo, data, id }) {
    const { me, langChoice } = useContext(MyContext)
    const [categories, dispatchAll] = useCatReducer();
    const history = useHistory()
    const addressRef = useRef()
    const [select, setSelect] = useState(null)
    const [created, setCreated] = useState(null)
    const [save, setSave] = useState(null)
    const [options, setOptions] = useState([])
    const [needed, setNeeded] = useState(null)
    const [waitingAddResponse, setWaitingAddResponse] = useState(false)
    const [lockedMode, setLockedMode] = useState(false)
    const [type, setType] = useState('password')
    const alert = useAlert()

    let googleLL = firebaseInstance().functions().httpsCallable('googleFindLongLat')
    const regexEmail = /^([\w\.\-]+)@([\w\-]+)((\.(\w){2,25})+)$/;

    const createNewAccount = async values => {
        let res = {}

        let CreateUsersTemp = firebaseInstance().functions().httpsCallable('CreateUsersTemp')
        console.log("CreateUsersTemp ", CreateUsersTemp)
        try {
            console.log(22,values)
            res = await CreateUsersTemp(values);
        } catch (err) {
            setCreated(2); // User not created
            console.log('Error: ' + err.toString());
            alert.info(t(err.toString()))
        }
        return res.data
    };

    const onSubmit = async (v, { setSubmitting }) => {
        setSubmitting(true)
        if(save) {
            v.address = save;
            let response = await googleLL({value: save})
            v.location = response.data.results[0].geometry.location
        }

        v.role = tokenInfo.role
        v.organisation_id = tokenInfo.organisation_id 
        v.groups = tokenInfo.groups


        const exists = await axios.get(`https://api.bazookka.com/users/u/exists/${v.email}`)
        console.log("exists ", exists)
        
        if(!exists.data){
            try{
                const userId = await createNewAccount(v);
                console.log("userId ",userId)
                if(userId){
                    v.meta =  {...v, eid : userId}
                    await axios.put(`https://api.bazookka.com/users/u/token/${token}`, v)
        
                if(tokenInfo.type !== 'group'){
                    await axios.put(`https://api.bazookka.com/users/u/expireToken/${token}`, v)
                }
        
                alert.success(t("register.success"))
                setTimeout(function() {
                    history.push("/")
                  }, 5000);
                }else{
                    console.log(t("registration.error.user_exists"))
                }
            }catch(e){
                console.error("e ", e)
            }            

        setSubmitting(false)
        }else{
            alert.info(t("registration.error.user_exists"))
        }
    }
    const returnCat = (cat, list, values) => {
        return values[cat] && values[cat].map(k => {
            return list.find(l => l.key === k)
        }) || []
    }

    const onCancel = () => {
        history.push('/')
    }

    useEffect(() => {

        dispatchAll({
            type: langChoice === 'fr' ? 'ALL_SET_FR' : 'ALL_SET_EN'
        })

        if (data && data.invitedBy) {
            if (data.invitedBy === 'ZohoSyncScript') {
                setLockedMode(true);
            }
        }

        isNoDataNeedDeepInfo(data.role).then(info => setNeeded(info))
        if (me) {
            history.push('/me')
        }
    }, [me, data])

    const updateRequest = async (value) => {
        let addressGetter = firebaseInstance().functions().httpsCallable('googleAddress')
        // let response = await axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${urlEncode(value)}&types=geocode&key=AIzaSyDU-7VwiLZiZwAZALo-0dqQlUAZQKXga1Y`)
        console.log("value ",value)
        if (value.length > 7) {
            setWaitingAddResponse(true)
            let response = await addressGetter({ value })

            setOptions(response.data.response.predictions.map((d, index) => {
                return { value: d.description, label: d.description }
            }))
            setWaitingAddResponse(false)
        }
    }

    const handleChange = (v) => {
        setSelect(v)
        setSave(addressRef?.current?.state?.value?.label)
    }

    useDeBounce(() => updateRequest(select), 2000, [select])

    const validate = Yup.object().shape({
        password: Yup.string().min(5).required(),
        cpassword: Yup.string().min(5).oneOf([Yup.ref('password'), null], 'password must match').required(),
        displayName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required(t('forms.errors.displayNameRequired')),
        email: Yup.string().required(t('forms.errors.emailRequired')).matches(regexEmail, t('forms.errors.emailFormatInvalid')),
    })

    return (
        <FormSignUpStyled>
            <Formik validationSchema={validate} initialValues={data} onSubmit={onSubmit} enableReinitialize={true}>
                {({ values, isSubmitting, resetForm, setSubmitting, errors, touched }) => {
                    return (
                        <Form autoComplete="off">
                            <h2>{t('general.registerform')}</h2>
                            <div className="column">
                                <InputText
                                    label={t('listing.name')}
                                    value={values.displayName}
                                    name="displayName" disabled={lockedMode} />
                                <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.displayName}</div>} name="displayName" />
                                <InputText
                                    label={t('general.role')}
                                    disabled
                                    value={t(tokenInfo ? tokenInfo.role : "sch.student")}
                                    name="role" />
                                <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.role}</div>} name="role" />
                                <InputText
                                    label={t('general.emailaddress')}
                                    value={values.email}
                                    name="email" disabled={lockedMode} />
                                    <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.email}</div>} name="email" />
                                {t('general.location')}:  {values.address}
                                <ReactSelect
                                    defaultValue={values.address || ''}
                                    options={options}
                                    onInputChange={handleChange}
                                    ref={addressRef}
                                    loadingMessage={() => t("forms.addressSearchingLoadingMsg")}
                                    noOptionsMessage={() => t("forms.noAddressFoundMsg")}
                                    isLoading={waitingAddResponse}
                                    disabled={lockedMode}
                                />
                                <InputText label={t('settings.phone')} name="tel" disabled={lockedMode} />
                                <ErrorMessage render={error => <div style={{ color: 'red' }}>{errors.tel}</div>} name="tel" />
                                <TextAreaInput label={t('settings.intro')} value={values.intro || ''} name="intro" disabled={lockedMode} />
                                {needed && categories && categories.map((cat, i) => {
                                    return (
                                        <div className="field" key={i}>
                                            <label>{t(cat[0])}
                                                <div className="control">
                                                    <Multiselect
                                                        options={cat[1] || []}
                                                        displayValue="value"
                                                        placeholder={t('overlay.select')}
                                                        selectedValues={returnCat(cat[0], cat[1], values)}
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        onSelect={(v) => {
                                                            values[cat[0]] = v.map(k => k.key)
                                                        }}
                                                        onRemove={(v) => {
                                                            values[cat[0]] = v.map(k => k.key)
                                                        }}

                                                    >

                                                    </Multiselect>
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                                <InputText
                                    label={t('general.pwd')}
                                    name="password"
                                    value={values.password}
                                    type={type}
                                />
                                <InputText
                                    label={t('general.rpwd')}
                                    name="cpassword"
                                    value={values.cpassword}
                                    type={type}
                                />
                                {errors.cpassword && touched.cpassword ? (
                                    <div>{errors.cpassword}</div>
                                ) : null}
                                <SwitchBox
                                    label={t('form.revealPassword')}
                                    name="display"
                                    onClick={() => values.display ? setType('password') : setType('text')}
                                ></SwitchBox>

                                <div className="split">
                                    <button type="submit" className="button" disabled={isSubmitting}
                                    >{t('general.register')}</button>
                                    <button type="button" className="button" onClick={onCancel}>{t('general.cancel')}</button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </FormSignUpStyled>
    )
}

export function YouveRegister({ t }) {
    const history = useHistory()
    useEffect(() => {
        setTimeout(() => {
            history.push('/')
        }, 5000)
    })
    return (
        <FormSignUpStyled>
            {t('message.account_creation')}
        </FormSignUpStyled>
    )
}

export function CompanyForm() {
    const { me, globalId } = useContext(MyContext)
    return (
        <ProfileBusinessForm me={me} globalId={globalId}></ProfileBusinessForm>
    )
}
