import React, {useState, useContext, useEffect} from 'react'
import {ButtonGroup, Button, IconButton} from "@mui/material";
import {Google, GitHub, LinkedIn, Facebook, Window, CorporateFare, Hail} from "@mui/icons-material";
import { Link, useHistory, Route } from 'react-router-dom'
import {firebaseInstance, users, usersDoc} from '../firestore/firestoreService'
import firebase from '../config/firebase'
import {MyContext} from "../stores"
import { Trans, useTranslation } from "react-i18next";
import { useAlert } from 'react-alert'
import {useLocalStorage} from "../helper";
import styled from "styled-components"
import axios from 'axios';

const GroupSocial = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;
  :nth-child(1){
    background-color: #F25022 !important;
  }
  :nth-child(3){
    background-color: green !important;
  }
`

const Connection = () => {
    const { langChoice, setMe, verifyAuth, me, updateStateLanguage, setScope } = useContext(MyContext);
    const { t } = useTranslation();
    const alert = useAlert();
    let selected = langChoice;
    let history = useHistory();
    let [clicked, clickedUpdate] = useState(false);
    let [message, setMessage] = useState(t("general.credentials"));
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");

    const UpdateUserRole = firebaseInstance().functions().httpsCallable('UpdateUserRole')

    useEffect(() => {
        if (me) {
            if (me.select) {
                history.push(`/me`);
            } else {
                history.push(`/select`);
            }
        }
    }, [me]);

    const emailChange = (evt) => {
        setEmail(evt.target.value);
    };

    const pwdChange = (evt) => {
        setPassword(evt.target.value);
    };
    const onClick = async (evt) => {
        evt.preventDefault();
        //setMessage('Please check your mail box')
        try {
            let result = await firebase
                .auth()
                .signInWithEmailAndPassword(email, password);
            let user = await users.doc(result.user.uid).get();
            console.log("login user ",user.data())
            setMe(user.data());
            verifyAuth();
            clickedUpdate(true);
        }catch(e) {
            alert.error(e.message)
        }
    };

    const EnterToSubmit = (e) => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 13) {
            e.preventDefault();
            onClick(e);
        }

    }

    const signInWindow = async function(){
        var provider = new firebase.auth.OAuthProvider('microsoft.com');

        provider.setCustomParameters({
            // Force re-consent.
            prompt: 'consent',
            tenant: "common",
            // Target specific email with login hint.
            login_hint: 'Type'
        });
        provider.addScope('mail.read');
        provider.addScope('calendars.read');
        let result = await firebase.auth().signInWithPopup(provider)
        let credential = result.credential;
        let user;
        const check = await usersDoc.doc(result.user.uid).get()
        if(check.exists) {
            setMe(check.data())
            verifyAuth();
            clickedUpdate(true);
        } else {
            await UpdateUserRole({
                selectId: '0uuQdR7ieoV3xJOSmc7w',
                newRole: 'bzk.seeker',
                uid: result.user.uid,
                defaultRole: 'bzk.seeker'
            })
            await usersDoc.doc(result.user.uid).set({
                displayName: result.user.displayName,
                avatar: result.user.photoURL,
                email: result.user.email,
                provider: result.user.providerId,
                groupsId: ['all'],
                role: 'bzk.seeker',
                schoolsId: ['0uuQdR7ieoV3xJOSmc7w'],
                select: {
                    id: '0uuQdR7ieoV3xJOSmc7w',
                    name: "Bazookka Inc."
                }

            })
            user = await usersDoc.doc(result.user.uid).get()
            setMe(user.data())
            verifyAuth();
            clickedUpdate(true);
        }

        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = credential.accessToken;

    }

    const signInGoogle = async function(){

        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        let result = await firebase.auth().signInWithPopup(provider)
        let credential = result.credential;
        let user;
        const check = await usersDoc.doc(result.user.uid).get()
        if(check.exists) {
            setMe(check.data())
            verifyAuth();
            clickedUpdate(true);
        } else {
            await UpdateUserRole({
                selectId: '0uuQdR7ieoV3xJOSmc7w',
                newRole: 'bzk.seeker',
                uid: result.user.uid,
                defaultRole: 'bzk.seeker'
            })
            await usersDoc.doc(result.user.uid).set({
                displayName: result.user.displayName,
                avatar: result.user.photoURL,
                email: result.user.email,
                provider: result.user.providerId,
                groupsId: ['all'],
                role: 'bzk.seeker',
                schoolsId: ['0uuQdR7ieoV3xJOSmc7w'],
                select: {
                    id: '0uuQdR7ieoV3xJOSmc7w',
                    name: "Bazookka Inc."
                }

            })
            user = await usersDoc.doc(result.user.uid).get()
            setMe(user.data())
            verifyAuth();
            clickedUpdate(true);
        }




        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = credential.accessToken;
    }

    const signInLinkedIn = async function(){
        setScope("linkedin")
        setTimeout(()=>{
            window.open('popup', 'name', 'height=585,width=400');
        }, 500)


    }

    const signInFacebook = async function(){
        const provider = new firebase.auth.FacebookAuthProvider()
        let result, user;
        try {
            result = await firebase.auth().signInWithPopup(provider)
        }catch(e){
            alert.error(t("socialRegistration.accountExists"))
            return;
        }

        const check = await usersDoc.doc(result.user.uid).get()
        if(check.exists) {
            setMe(check.data())
            verifyAuth();
            clickedUpdate(true);
        } else {
            await UpdateUserRole({
                selectId: '0uuQdR7ieoV3xJOSmc7w',
                newRole: 'bzk.seeker',
                uid: result.user.uid,
                defaultRole: 'bzk.seeker'
            })
            await usersDoc.doc(result.user.uid).set({
                displayName: result.user.displayName,
                avatar: result.user.photoURL,
                email: result.user.email,
                provider: result.user.providerId,
                groupsId: ['all'],
                role: 'bzk.seeker',
                schoolsId: ['0uuQdR7ieoV3xJOSmc7w'],
                select: {
                    id: '0uuQdR7ieoV3xJOSmc7w',
                    name: "Bazookka Inc."
                }

            })
            user = await usersDoc.doc(result.user.uid).get()
            setMe(user.data())
            verifyAuth();
            clickedUpdate(true);
        }
    }

    const signInGithub = async function(){
        const provider = new firebase.auth.GithubAuthProvider()
        let result = await firebase.auth().signInWithPopup(provider)
        let user;
        const check = await usersDoc.doc(result.user.uid).get()
        if(check.exists) {
            setMe(check.data())
            verifyAuth();
            clickedUpdate(true);
        } else {
            await UpdateUserRole({
                selectId: '0uuQdR7ieoV3xJOSmc7w',
                newRole: 'bzk.seeker',
                uid: result.user.uid,
                defaultRole: 'bzk.seeker'
            })
            await usersDoc.doc(result.user.uid).set({
                displayName: result.user.displayName,
                avatar: result.user.photoURL,
                email: result.user.email,
                provider: result.user.providerId,
                groupsId: ['all'],
                role: 'bzk.seeker',
                schoolsId: ['0uuQdR7ieoV3xJOSmc7w'],
                select: {
                    id: '0uuQdR7ieoV3xJOSmc7w',
                    name: "Bazookka Inc."
                }

            })
            user = await usersDoc.doc(result.user.uid).get()
            setMe(user.data())
            verifyAuth();
            clickedUpdate(true);
        }

    }

    return (
        <div className="signup-wrapper">
            <div className="fake-nav" style={{ display: "none" }}>
                <a>
                    <img
                        src="assets/img/logo/friendkit-bold.svg"
                        width="112"
                        height="28"
                        alt=""
                    />
                </a>
            </div>

            <div className="container">
                <div className="login-container">
                    <div className="columns is-vcentered">
                        <div className="column is-6 image-column">
                            <img
                                className="login-image"
                                src="assets/img/intern2.png"
                                alt=""
                                style={{ width: 450 }}
                            />
                        </div>
                        <div className="column is-6">
                            <img src="assets/old/ico.png" width={100} />
                            <h2 className="form-title">
                                <Trans i18nKey="general.welcome">Welcome back</Trans>
                            </h2>
                            <h3 className="form-subtitle">
                                <Trans i18nKey="general.credentials">
                                    Enter your credentials to sign in.
                                </Trans>
                            </h3>

                            <div className="login-form">
                                <div className="form-panel">
                                    <div
                                        className="field"
                                        style={{ display: !clicked ? "block" : "none" }}
                                    >
                                        <label>{t("settings.email")}</label>
                                        <div className="control">
                                            <input
                                                type="text"
                                                className="input"
                                                placeholder={t("general.missingemail")}
                                                onChange={emailChange}
                                                onKeyDown={EnterToSubmit}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="field"
                                        style={{ display: !clicked ? "block" : "none" }}
                                    >
                                        <label>
                                            <Trans i18nKey="general.pwd">Password</Trans>
                                        </label>
                                        <div className="control">
                                            <input
                                                type="password"
                                                className="input"
                                                placeholder={t("general.pwd")}
                                                onChange={pwdChange}
                                                onKeyDown={EnterToSubmit}
                                            />
                                        </div>
                                    </div>
                                    <div className="field is-flex">
                                        {/* Code was commented but will be using that in the future */}
                                        {/*<div className="switch-block">*/}
                                        {/*    <label className="f-switch">*/}
                                        {/*        <input type="checkbox" className="is-switch" />*/}
                                        {/*        <i></i>*/}
                                        {/*    </label>*/}
                                        {/*    <div className="meta">*/}
                                        {/*        <p>*/}
                                        {/*            <Trans i18nKey="general.remember">Remember me</Trans>*/}
                                        {/*        </p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <Link to={"/reset"}>
                                            <Trans i18nKey="general.passwordforgotten">
                                                Forgot Password ?
                                            </Trans>
                                        </Link>
                                    </div>
                                </div>

                                <div className="buttons">
                                    <Button variant={'contained'} style={{width: '100%', backgroundColor: 'var(--bazookka-orange)'}}
                                        onClick={onClick}
                                    >
                                        <Trans i18nKey="general.login">Login</Trans>
                                    </Button>
                                </div>

                                <div className="buttons" style={{display: 'block'}}>
                                    <Link to="/chooseYourAccountType" className="button is-solid alert-success is-fullwidth raised">{t('general.signup')}</Link>
                                </div>

                                <hr style={{backgroundColor: "var(--bazookka-dim-grey)"}}/>
                                <span style={{textAlign: "center", width: "100%", display: "block"}}>{t('general.otheroptions')}</span>
                                <hr style={{backgroundColor: "var(--bazookka-dim-grey)"}}/>
                                <GroupSocial>

                                        <IconButton
                                            variant={'contained'} style={{ color: '#00A4EF'}}
                                            onClick={signInWindow}
                                        >
                                            <svg style={{width:24,height:24}} viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M2,3H11V12H2V3M11,22H2V13H11V22M21,3V12H12V3H21M21,22H12V13H21V22Z" />
                                            </svg>
                                        </IconButton>


                                        <IconButton
                                            variant={'contained'} style={{ color: '#2867B2'}}
                                            onClick={signInLinkedIn}
                                        >
                                            <LinkedIn />
                                        </IconButton>

                                        <IconButton

                                            variant={'contained'} style={{color: '#4267B2'}}
                                            onClick={signInFacebook}
                                        >
                                            <Facebook />
                                        </IconButton>

                                        <IconButton
                                            variant={'contained'} style={{color: '#DB4437'}}
                                            onClick={signInGoogle}
                                        >
                                            <Google />
                                        </IconButton>

                                        <IconButton

                                            variant={'contained'} style={{ color: 'black'}}
                                            onClick={signInGithub}
                                        >
                                            <GitHub />
                                        </IconButton>

                                </GroupSocial>


                                <div className="buttons">
                                    <ButtonGroup style={{width: '100%'}}>
                                        <Button style={{width: '50%'}}
                                         onClick={v => updateStateLanguage('fr')}
                                        >{t('languages.french')}</Button>
                                        <Button style={{width: '50%'}}
                                         onClick={v => updateStateLanguage('en')}
                                        >{t('languages.english')}</Button>
                                    </ButtonGroup>
                                </div>
                                <div className="buttons" style={{display: 'none'}}>
                                    <Link to="/userRegistration" className="button is-solid alert-success is-fullwidth raised">{t('general.signup')}</Link>
                                </div>
                                <div className="buttons" style={{display: 'none'}}>
                                    <Link to="/companyRegistration" className="button is-solid alert-success is-fullwidth raised">{t('general.signup')}</Link>
                                </div>

                                <div className="account-link has-text-centered">
                                    {/*<Link to={`/fr/register`}>*/}
                                    {/*    <Trans i18nKey="general.createaccount">*/}
                                    {/*        Don't have an account? Sign Up*/}
                                    {/*    </Trans>*/}
                                    {/*</Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const ResetPassword = () => {
    const {langChoice } = useContext(MyContext)
    let selected = langChoice
    let history = useHistory();
    const { t } = useTranslation();
    let [clicked, clickedUpdate] = useState(false)
    let [email, setEmail] = useState('')
    const alert = useAlert()

    const emailChange = evt => {
        setEmail(evt.target.value)
    }

    const onClick = (e) => {
        e.preventDefault()
        firebase.auth()
            .sendPasswordResetEmail(email)
            .then(()=> {
                alert.success(t("general.mailboxcheck"))
                history.goBack()
            })
    }

    return (
        <div className="login-container">
            <div className="columns is-vcentered">
                <div className="column is-6 image-column">
                    <img className="login-image" src="assets/img/intern2.png" alt="" style={{width: 450}}/>
                </div>
                <div className="column is-6">

                    <h2 className="form-title">
                        <Trans i18nKey={'general.welcome'}>general.welcome</Trans>
                    </h2>
                    <h3 className="form-subtitle">
                        <Trans i18nKey={'general.credentials'}>message</Trans>
                    </h3>


                    <div className="login-form">
                        <div className="form-panel">
                            <div className="field" style={{display: (!clicked)? 'block': 'none'}}>
                                <label>{selected['settings.email']}</label>
                                <div className="control">
                                    <input type="text" className="input" placeholder={t('general.missingemail')} onChange={emailChange} />
                                </div>
                            </div>
                            <div className="field is-flex">
                                {/*    <Link to={`/fr/login/reset`}>{selected['general.passwordforgotten']}</Link>*/}
                            </div>
                        </div>

                        <div className="buttons">
                            {/*<a onClick={onClick} className="button is-solid primary-button is-fullwidth raised">{selected['general.login']}</a>*/}
                            <a onClick={onClick} className="button is-solid primary-button is-fullwidth raised">Reset password</a>
                        </div>


                        {/*<div className="account-link has-text-centered">*/}
                        {/*    <Link to={`/fr/register`}>{selected['general.createaccount']}</Link>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}


const Login = props => {
    const {langChoice, me} = useContext(MyContext)
    const selected = langChoice
    let history = useHistory();
    const [cache, setCache] = useLocalStorage("loc")

    useEffect(()=> {
        if(me) {
            if(me.select) {
                if(cache) {
                    history.push(cache)
                    return
                }
                history.push(`/me`)
            }else {
                history.push(`/select`)
            }
        }

    }, [me])


    return (
        <div className="signup-wrapper">
            <div className="fake-nav" style={{display: 'none'}}>
                <a >
                    <img src="assets/img/logo/friendkit-bold.svg" width="112" height="28" alt="" />
                </a>
            </div>

            <div className="container">
                <Route component={Connection} path="/" exact={true}></Route>
                <Route component={ResetPassword} path="/reset"></Route>
            </div>
        </div>
    )
}

const StyleAccount = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
  }
  .wrapper div {
    height: 60px;
    width: 60px;
    border: 2px solid rgba(255, 255, 255, 0.7);
    border-radius: 50px;
    position: absolute;
    top: 10%;
    left: 10%;
    animation: animate 4s linear infinite;
  }
  div .dot {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 20%;
    right: 20%;
  }
  .wrapper div:nth-child(1) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
    background-color: var(--bazookka-pink);
  }
  .wrapper div:nth-child(2) {
    top: 60%;
    left: 80%;
    animation: animate 10s linear infinite;
    background-color: var(--bazookka-green);
  }
  .wrapper div:nth-child(3) {
    top: 40%;
    left: 40%;
    animation: animate 5s linear infinite;
    background-color: var(--bazookka-blue);
  }
  .wrapper div:nth-child(4) {
    top: 66%;
    left: 30%;
    animation: animate 7s linear infinite;
    background-color: var(--bazookka-yellow);
  }
  .wrapper div:nth-child(5) {
    top: 90%;
    left: 10%;
    animation: animate 9s linear infinite;
    background-color: var(--bazookka-pink);
  }
  .wrapper div:nth-child(6) {
    top: 30%;
    left: 60%;
    animation: animate 5s linear infinite;
    background-color: var(--bazookka-green);
  }
  .wrapper div:nth-child(7) {
    top: 70%;
    left: 20%;
    animation: animate 8s linear infinite;
    background-color: var(--bazookka-yellow);
  }
  .wrapper div:nth-child(8) {
    top: 75%;
    left: 60%;
    animation: animate 10s linear infinite;
    background-color: var(--bazookka-blue);
  }
  .wrapper div:nth-child(9) {
    top: 50%;
    left: 50%;
    animation: animate 6s linear infinite;
    background-color: var(--bazookka-darkblue);
  }
  .wrapper div:nth-child(10) {
    top: 45%;
    left: 20%;
    animation: animate 10s linear infinite;
    background-color: var(--bazookka-pink);
  }
  .wrapper div:nth-child(11) {
    top: 10%;
    left: 90%;
    animation: animate 9s linear infinite;
    background-color: var(--bazookka-yellow);
  }
  .wrapper div:nth-child(12) {
    top: 20%;
    left: 70%;
    animation: animate 7s linear infinite;
    background-color: var(--bazookka-blue);
  }
  .wrapper div:nth-child(13) {
    top: 20%;
    left: 20%;
    animation: animate 8s linear infinite;
    background-color: var(--bazookka-pink);
  }
  .wrapper div:nth-child(14) {
    top: 60%;
    left: 5%;
    animation: animate 6s linear infinite;
    background-color: var(--bazookka-green);
  }
  .wrapper div:nth-child(15) {
    top: 90%;
    left: 80%;
    animation: animate 9s linear infinite;
    background-color: var(--bazookka-pink);
  }
  @keyframes animate {
    0% {
      transform: scale(0) translateY(0) rotate(70deg);
    }
    100% {
      transform: scale(1.3) translateY(-100px) rotate(360deg);
    }
  }
  button {
    margin-bottom: 1rem;
  }
  h2 {
    margin-bottom: 1rem;
    text-align: center;
  }
  .center {
    width: 350px;
    background-size: contain;
  }
`


export const PickTypeAccount = () => {
    const {t} = useTranslation()
    const history = useHistory()
    return (
        <StyleAccount>
            <div className="wrapper">
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
            </div>
            <div className="center">
                <img src={'assets/img/logo-newsletter.png'} style={{display: "block", margin: "auto", width: 200}}/>
                <h2>{t('general.selecttype')}</h2>
                <Button
                    style={{width: '100%', marginBottom: '1rem'}}
                    variant="contained" startIcon={<Hail />} onClick={()=> {
                    history.push("userRegistration")
                }}>
                    {t('general.rsupanelsel')}
                </Button>
                <Button
                    style={{width: '100%', marginBottom: '1rem'}}
                    variant="contained" startIcon={<CorporateFare />} onClick={()=> {
                    history.push("companyFreemiumRegistration")
                }}>
                    {t('general.freemium')}
                </Button>
                <Link to="/">
                    <Button variant={'contained'} style={{width: '100%', marginBottom: '1rem'}}>{t('general.goback')}</Button>
                </Link>
            </div>
        </StyleAccount>
    )
}

export default Login;
