import React, {Fragment, useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom"
import {Button} from "@mui/material"
import InvitationForm from "./SignUp/InvitationForm";
import {MyContext} from "../stores";
import TileComponent from "../components/TileComponent";
import {Search, GridComponent, ColumnDirective, ColumnsDirective, Resize, Sort, Filter, Page, Inject} from "@syncfusion/ej2-react-grids";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import useSWR from 'swr';
import bzkFetcher from "../utils/bzkFetcher";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ButtonsContainer = styled.div`
    margin-bottom:10px;
    display:block;

    button{
        border:1px solid black;
        background-color:white;
        margin-right:10px;
    }
`

const LineBreak = styled.div`
    width:100%;
    clear:both;
`

const ReturnLink = styled.div`
    margin-bottom: 20px;

    i{
        margin-right:3px;
        vertical-align:middle;
    }
`

const PendingUserContainer = styled.div`
    width:100%;
    margin-bottom:1em;
    
    h1{
        margin-bottom:10px;
    }
`

const InviteUsers = props => {
    const {organizationId, globalId, langChoice} = useContext(MyContext)
    const [inviteType, setInviteType] = useState(null)
    const {t} = useTranslation();
    const inviteGrid = [
        {
            width: '50',
            field: 'name',
            headerText: t("listing.name"),
        },
        {
            width: '50',
            field: 'status',
            headerText: 'Status',
        },
        {
            width: '50',
            field: 'type',
            headerText: 'Type',
        },
        {
            width: '50',
            field: 'role',
            headerText: t("general.role"),
            template: function Display(props){
                const roleString = t(props.role);
                return <React.Fragment>{roleString}</React.Fragment>
            }
        },
        {
            headerText: 'URL',
            width: '50',
            template: function Display(props) {
                const tokenUrl = `http://api.bazookka.com/signup/${props.token}`;
                return (
                    <React.Fragment>
                        <CopyToClipboard text={tokenUrl}>
                            <a title={tokenUrl}><i className="mdi mdi-content-copy"></i></a>
                        </CopyToClipboard>
                    </React.Fragment>
                )
            },
        },
        {
            headerText: t("general.delete"),
            width: '50',
            template: function Display(props) {
                return (<button className="deleteBtn"><i class="mdi mdi-24px mdi-trash-can"></i></button>)
            },
        },
        {
            width: '50',
            field: 'createdAt',
            headerText: 'Date',
        },
    ]

    const deleteInvite = async (id) => {
        const resp = await fetch(`https://api.bazookka.com/invites/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'eid': globalId
            },
            method: 'delete',
        });

        inviteMutate();
    }

    const recordClick = (args) => {
        console.log("args", args);
        if (args.target.classList.contains('mdi-trash-can')) {
            if(window.confirm(t("general.genericconfirm"))){
                deleteInvite(args.rowData.id)
            }
        }
    }

    const useInvitesByOrg = (orgId, userId) => {
        const {data, error, mutate} = useSWR(
            (orgId)
                ? [`https://api.bazookka.com/invites/organisation/${orgId}`, userId]
                : null
            , bzkFetcher
        );

        return {
            inviteData: data,
            inviteIsLoading: !error && !data,
            inviteIsError: error,
            inviteMutate: mutate,
        }
    }

    const { inviteData, inviteMutate } = useInvitesByOrg(organizationId, globalId);


    return (
        <div className="h-full">
            <div className="flex flex-wrap w-[100%]" style={{paddingTop: '1rem', paddingBottom: '1rem'}}>
                <ReturnLink>
                    <Link to="/me/users_view">
                        <button className="button">
                            <i className="mdi mdi-arrow-left"></i><span>{t("general.retourUserView")}</span>
                        </button>
                    </Link>
                </ReturnLink>
                <PendingUserContainer>
                    <TileComponent
                        marginBottom={`1rem`}
                        ico={<i className="mdi mdi-clock"></i>}
                        label={t('general.pendingconfirmation')} />
                    <GridComponent
                        locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                        id="gridcomp"
                        dataSource={inviteData}
                        allowPaging
                        allowSorting
                        pageSettings={{pageSize: 25}}
                        toolbar={["Search"]}
                        recordClick={recordClick}
                        >
                        <ColumnsDirective>
                            {inviteGrid.map((item, index) => {
                            return <ColumnDirective
                                key={index}
                                {...item}
                            />
                            })}
                        </ColumnsDirective>
                        <Inject services={[Resize, Sort, Page, Filter, Search]} />
                    </GridComponent>
                </PendingUserContainer>
                <div className="title-wrap">
                    <TileComponent
                        marginBottom={`1rem`}
                        ico={<i className="mdi mdi-email-plus"></i>}
                        label={t('general.invitetocreateaccount')} />
                </div>
                <LineBreak />
                <ButtonsContainer>
                    <Button {...(inviteType === 'email' ? {color: 'success'} : {})} onClick={() => setInviteType('email')} >{t('general.inviteEmail')}</Button>
                    <Button {...(inviteType === 'link' ? {color: 'success'} : {})} onClick={() => setInviteType('link')} >{t('general.inviteLink')}</Button>
                    <Button {...(inviteType === 'group' ? {color: 'success'} : {})} onClick={() => setInviteType('group')} >{t('general.inviteGroup')}</Button>
                </ButtonsContainer>
                {inviteType && <InvitationForm type={inviteType} mutate={inviteMutate}/>}
            </div>
        </div>
    )
}

export default InviteUsers
