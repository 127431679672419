import React, {useContext, useEffect, useState, useRef} from 'react'
import Avatar from "react-avatar";
import {MyContext} from "../stores";
import {firestore, messagesDocs, contactsDocs} from "../firestore/firestoreService";
import {formatDistance} from 'date-fns'
import {fr, enCA} from "date-fns/locale";
import styled from 'styled-components'
import {useTranslation} from "react-i18next";
import ChatInput from "../components/ChatInput";
import FileUpload from '../components/FileUpload';
import AttachmentList from "../components/AttachmentList";
import Loader from "./Loader";
import {db} from "../firestore/firestoreService";
import { object } from 'joi';
import { stubTrue } from 'lodash';
import {userState} from "../stores/enums/userPresence";
import { useAlert } from 'react-alert';
import { isKid, isMentor } from "../helper/acl";

const ChatNavStyled = styled.div`
  width:  calc(100% - 200px)!important;
  left: 200px !important;

  color: var(--bazookka-white) !important;
  *{
    color: var(--bazookka-white) !important;
  }
  svg {
    stroke:var(--bazookka-white) !important ;
  }

  &.online{
    background: var(--bazookka-green) !important;
  }

  &.offline{
    background: var(--bazookka-blue) !important;
  }
;`

const ChatSideBarStyled = styled.div`
  width: 200px !important;
  .user-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start !important;
    width: 100% !important;
  }
  .avatar-container {
    padding-left: 1em;
  }
  .name {
    margin-left: 3em;
    font-size: 9px;
  }
  img.iMask {
    -webkit-mask-image: url(assets/old/logo-bazookka.png);
    -webkit-mask-size: 200px;
    -webkit-mask-position-y: center;
    -webkit-mask-repeat: no-repeat;
  }
`

const ChatBodyStyled = styled.div`
  width: calc(100% - 600px) !important;
  left: 200px !important;
  z-index:10;

  .chat-action {
    left: 200px !important;
    width: calc(100% - 660px) !important;
  }
  i.mdi.mdi-delete {
    font-size: 1.75rem;
    cursor: pointer;
    
  }
`

const AvatarGroup =styled.div`
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color:var(--bazookka-blue);
    display:grid;

    i{
        color:white;
        margin:auto;
    }
`

const ChatContactFooter = styled.div`
    width:100% !important;
    background-color: var(--bazookka-green);
    position:static !important;

    .add-button{
        background-color:white;
    }
`

const ConversationsList = styled.div`
    overflow-y: scroll;
    height: calc(100% - 160px) !important;
`

const UserStatus = styled.div`
    position: relative;
    top: -43px;
    right: -1px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid #fff;

    &.offline{
        background-color:#999;
    }

    &.online{
        background-color:var(--bazookka-green);
    }
`

const renderAvatar = (contact) => {
    if(contact.users.length > 1){
        return (
            <AvatarGroup>
                <i className="mdi mdi-account-group"></i>
            </AvatarGroup>
        );
    }else{
        return <Avatar name={contact && contact.users[0]?.displayName} round={true} size={40}/>
    }
}

const renderNames = (contact) => {
    if(contact.groupName){
        return <div className="name">{contact && contact.groupName}</div>
    }else if(contact.users.length > 1){
        let returnString = "";
        const nbShownNames = 2;
        const otherCount = contact.users.length - nbShownNames;
        const plural = otherCount > 1 ? "s" : "";
        for (let i = 0; i < nbShownNames; i++) {
            if(i === 0){
                returnString += `${contact.users[i].displayName} `;
            }else{
                returnString += `,${contact.users[i].displayName} `;
            }
        }
        returnString += `and ${otherCount} other${plural}`;
        return <div className="name">{returnString}</div>
    }else{
        return <div className="name">{contact && contact.users[0]?.displayName}</div>
    }
}

const isContactOnline = (contact) => {
    const onlineUsers = contact.users.filter((user) => {
        if(user.status && user.status.state === userState.ONLINE){
            return true
        }else{
            return false
        }
    });
    if(onlineUsers.length > 0){
        return true;
    }else{
        return false;
    }
}

export const ChatTopNav = ({me, history, select}) => {
    const {t} = useTranslation()
    const ChatNavStatusClass = select && isContactOnline(select) ? 'online' : 'offline';
    return (
        <ChatNavStyled className={`chat-nav ${ChatNavStatusClass}`}>
            <div className="nav-start">
                <div className="recipient-block">
                    <div className="avatar-container">
                        {select && renderAvatar(select)}
                    </div>
                    <div className="username">
                        {select && renderNames(select)}
                        {select &&
                            (
                                isContactOnline(select) ?
                                <span><i data-feather="star"></i> <span>| {t("general.online")}</span></span>
                                :
                                <span><i data-feather="star"></i> <span>| {t("general.offline")}</span></span>
                            )

                        }
                    </div>
                </div>
            </div>
            <div className="nav-end">


                {/*<div className="dropdown is-spaced is-neutral is-right dropdown-trigger">*/}
                {/*    <div>*/}
                {/*        <a className="chat-nav-item is-icon">*/}
                {/*            <i data-feather="settings"></i>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="dropdown-menu" role="menu">*/}
                {/*        <div className="dropdown-content">*/}
                {/*            <a >*/}
                {/*                <div className="media">*/}
                {/*                    <i data-feather="message-square"></i>*/}
                {/*                    <div className="media-content">*/}
                {/*                        <h3>Details</h3>*/}
                {/*                        <small>View this conversation's details.</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </a>*/}
                {/*            <a className="dropdown-item">*/}
                {/*                <div className="media">*/}
                {/*                    <i data-feather="settings"></i>*/}
                {/*                    <div className="media-content">*/}
                {/*                        <h3>Preferences</h3>*/}
                {/*                        <small>Define your preferences.</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </a>*/}
                {/*            <hr className="dropdown-divider" />*/}
                {/*                <a className="dropdown-item">*/}
                {/*                    <div className="media">*/}
                {/*                        <i data-feather="bell"></i>*/}
                {/*                        <div className="media-content">*/}
                {/*                            <h3>Notifications</h3>*/}
                {/*                            <small>Set notifications settings.</small>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*                <a className="dropdown-item">*/}
                {/*                    <div className="media">*/}
                {/*                        <i data-feather="bell-off"></i>*/}
                {/*                        <div className="media-content">*/}
                {/*                            <h3>Silence</h3>*/}
                {/*                            <small>Disable notifications.</small>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*                <hr className="dropdown-divider" />*/}
                {/*                    <a className="dropdown-item">*/}
                {/*                        <div className="media">*/}
                {/*                            <i data-feather="box"></i>*/}
                {/*                            <div className="media-content">*/}
                {/*                                <h3>Archive</h3>*/}
                {/*                                <small>Archive this conversation.</small>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*                    <a className="dropdown-item">*/}
                {/*                        <div className="media">*/}
                {/*                            <i data-feather="trash-2"></i>*/}
                {/*                            <div className="media-content">*/}
                {/*                                <h3>Delete</h3>*/}
                {/*                                <small>Delete this conversation.</small>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* <div className="chat-search">
                    <div className="control has-icon">
                        <input type="text" className="input" placeholder={t('general.search')} />
                            <div className="form-icon">
                                <i data-feather="search"></i>
                            </div>
                    </div>
                </div> */}
                {/*<a className="chat-nav-item is-icon is-hidden-mobile">*/}
                {/*    <i data-feather="at-sign"></i>*/}
                {/*</a>*/}
                {/*<a className="chat-nav-item is-icon is-hidden-mobile">*/}
                {/*    <i data-feather="star"></i>*/}
                {/*</a>*/}


                {/*<div className="dropdown is-spaced is-neutral is-right dropdown-trigger">*/}
                {/*    <div>*/}
                {/*        <a className="chat-nav-item is-icon no-margin">*/}
                {/*            <i data-feather="more-vertical"></i>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*    <div className="dropdown-menu" role="menu">*/}
                {/*        <div className="dropdown-content">*/}
                {/*            <a >*/}
                {/*                <div className="media">*/}
                {/*                    <i data-feather="file-text"></i>*/}
                {/*                    <div className="media-content">*/}
                {/*                        <h3>Files</h3>*/}
                {/*                        <small>View all your files.</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </a>*/}
                {/*            <a className="dropdown-item">*/}
                {/*                <div className="media">*/}
                {/*                    <i data-feather="users"></i>*/}
                {/*                    <div className="media-content">*/}
                {/*                        <h3>Users</h3>*/}
                {/*                        <small>View all users you're talking to.</small>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </a>*/}
                {/*            <hr className="dropdown-divider" />*/}
                {/*                <a className="dropdown-item">*/}
                {/*                    <div className="media">*/}
                {/*                        <i data-feather="gift"></i>*/}
                {/*                        <div className="media-content">*/}
                {/*                            <h3>Daily bonus</h3>*/}
                {/*                            <small>Get your daily bonus.</small>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*                <a className="dropdown-item">*/}
                {/*                    <div className="media">*/}
                {/*                        <i data-feather="download-cloud"></i>*/}
                {/*                        <div className="media-content">*/}
                {/*                            <h3>Downloads</h3>*/}
                {/*                            <small>See all your downloads.</small>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </a>*/}
                {/*                <hr className="dropdown-divider" />*/}
                {/*                    <a className="dropdown-item">*/}
                {/*                        <div className="media">*/}
                {/*                            <i data-feather="life-buoy"></i>*/}
                {/*                            <div className="media-content">*/}
                {/*                                <h3>Support</h3>*/}
                {/*                                <small>Reach our support team.</small>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <a className="chat-nav-item is-icon" onClick={(evt)=>{
                    evt.preventDefault();
                    history.goBack()

                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </a>
            </div>
        </ChatNavStyled>
    )
}
/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const UserItems = ({contact, setSelect}) => {
    const onSelect = (e) => {
        setSelect(e)
    };
    return (
        <div className="user-item" onClick={()=> onSelect(contact)}>
            <div className="avatar-container">
                {contact && renderAvatar(contact)}
                {contact && isContactOnline(contact) ?
                    <UserStatus className="online" />
                    :
                    <UserStatus className="offline" />
                }
            </div>
                {contact && renderNames(contact)}
        </div>
    )
}

export const ChatSideBar = ({contacts, setSelect}) => {
    const {dispatchModal, me, globalId} = useContext(MyContext);
    const [kid, setKid] = useState(true);
    const [mentor, setMentor] = useState(true);
    const {t} = useTranslation()
    let sortedContacts = [];

    useEffect(() => {
        if(me && me.select){
            isKid(me.select.id).then(v => setKid(v))
            isMentor(me.select.id).then(v => setMentor(v))
        }
    }, [me])

    if(contacts){
        sortedContacts = contacts.sort((a, b) => {
            if(isContactOnline(a) && isContactOnline(b)){
                return 0
            }else if(isContactOnline(a) && !isContactOnline(b)){
                return -1
            }else{
                return 1
            }
        })
    }


    const unorderedArrayCompare = (arr1, arr2) => {
        //This is only to compare arrays of primitive
        arr1.sort();
        arr2.sort();
        return JSON.stringify(arr1) === JSON.stringify(arr2)
    }

    const handleNewConversation = async (users, usersIds) => {
        const arrayUsersIds = [globalId, ...usersIds];
        let existingContact = false;

        contacts.forEach(contact => {
            if(unorderedArrayCompare(arrayUsersIds, contact.usersIds)){
                setSelect(contact);
                existingContact = true;
            }
        });

        if(!existingContact){
            const fullUsers = users.map(fUser => {
                return {id: fUser.id, displayName: fUser.displayName}
            });
            const chatType = fullUsers.length > 1 ? 'multi-users' : 'users';
            await contactsDocs.add({
                type: chatType,
                users: [
                    {id: globalId, displayName: me.displayName},
                    ...fullUsers
                ],
                usersIds:arrayUsersIds,
            })
        }
    }

    const openModal = () => {
        dispatchModal({
            type: "OPEN_MODAL",
            payload: {
                modalType: "StartConversationModal",
                modalProps:{
                    title: t("general.startconversationModalTitle"),
                    me,
                    ico: <i className="mdi mdi-chat"></i>,
                    onNewConversation: handleNewConversation,
                    closeOut: () => dispatchModal({type: 'CLOSE_MODAL'}),
                }
            }})

    }

    
    return (
        <ChatSideBarStyled id="chat-sidebar" className="users-sidebar">

            <a >
                <img className="iMask" src="assets/old/wallpaper.png" alt="" />
            </a>

            <ConversationsList className="conversations-list has-slimscroll-xs">
                {
                    sortedContacts.length > 0 && sortedContacts.map( (contact, k) => <UserItems key={k} contact={contact} setSelect={setSelect}/>)
                }
            </ConversationsList>
            {!kid && !mentor &&
                <ChatContactFooter className="footer-item">
                    <div className="add-button modal-trigger" data-modal="add-conversation-modal" onClick={openModal}>
                        <i
                            className="mdi mdi-account-plus"
                        ></i>
                    </div>
                </ChatContactFooter>
            }
        </ChatSideBarStyled>
    )
}

const MessageContainer = styled.div`
  width:100%;
  min-width:70px;
  top:-25px !important;

  .chat-textbox{
      padding-left:45px;
      padding-right:45px;
      position:absolute;
      bottom:-40px;
  }
`

const AttachmentUploadContainer = styled.div`
    position:absolute;
    right:10px;
    bottom:-40px;
    z-index:10;
`

const MessageTextArea = styled.textarea`
    padding-right: 45px !important;
    z-index:1;
`

const SendButtonContainer = styled.div`
    z-index:10;
    position:absolute;
`

const AttachmentListContainer = styled.div`
    position: absolute;
    bottom: -40px;
    border: 1px solid #dbdbdb;
    background-color: white;
    left:101%;
    border-radius: 4px;

    .name{
        max-width:200px;
    }
`

const ChatMessageText = styled.div`
    width:fit-content !important;
`

const ChatEnd = styled.div`
`

const FlexBreak = styled.div`
	flex-basis: 100%;
	width: 0px; 
	height: 10px; 
	overflow: hidden;
`

const UserDetailList = styled.ul`
    display:block;
    width:70%;
    margin:auto;

    li{
        margin-bottom:15px;
        
        .sb-avatar{
            margin-right:10px;
        }
    }
`

const CallButton = styled.div`
    background-color: var(--bazookka-green) !important;

    &.offline{
        background-color:#999 !important;
    }
`

export const ChatBody = ({select}) => {
    const {globalId, me, langChoice} = useContext(MyContext)
    const [text, setText] = useState('')
    const [messages, setMessages] = useState([])
    const [messageAttachments, setMessageAttachments] = useState([]);
    const [fileUploadStatus, setFileUploadStatus] = useState('idle');
    const [fileDestination, setFileDestination] = useState('');
    const [createdDoc, setCreatedDoc] = useState('');
    const messagesEndRef = useRef(null)
    const [showDeleteButton,setShowDeleteButton] =  useState(false);
    const [selectedReplyIndex,setSelectedReplyIndex] =  useState(-1);
    const [messageToDelete,setMessageToDelete] = useState({})
    const msgTimeSpacing = 20 //minutes
    const messageComponentRef = useRef(null)
    const alert = useAlert()
    const t = useTranslation()

    const onSend = (data) => {
        let id;
        if(text.length === 0) {
            return alert(t("general.missingtext"))
        }
        contactsDocs.doc(select.id).collection('messages').add({
            text,
            timestamp: new Date().getTime(),
            displayName: me.displayName,
            isDeleted: false,
            myId: globalId
        }).then((docref) => {
            setText('');
            setCreatedDoc(docref.id);
            setFileDestination(`chat_attachments/${select.id}/${globalId}/`);
            setFileUploadStatus('uploading');
        })


    }

    const messageTimeSpace = (messages, i) => {
        //If it's the first message or if it was posted by a different person or the same person but with more than 20 minutes show the name and avatar
        return (
            i === 0 ||
            (i > 0 && messages[i].myId !== messages[i-1].myId) ||
            (((messages[i].timestamp - messages[i-1].timestamp) / 1000) / 60 > msgTimeSpacing)
        );
    }

    const handleMessageUpload = (fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            const newAttachment = {
                name: metadata.name,
                path: url,
                type: metadata.contentType,
                timestamp: Date.now(),
            }
            contactsDocs.doc(select.id).collection('messages').doc(createdDoc).update({
                attachments: firestore.FieldValue.arrayUnion(newAttachment)
                }).catch((error) => {
                console.error("Error writing document: ", error);
            });
        });
      }

      const handleDelete = (toDelete) => {
        setMessageAttachments(prevState => {
            const newState = prevState.filter(function(att) {
            return att !== toDelete;
            });
            return newState;
        });
      }

      const handleQueueComplete = () => {
        //Reset all the states and Close the modal
        setFileUploadStatus('idle');
        setMessageAttachments([]);
        setFileDestination('');
        setCreatedDoc('');
    }

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(()=>{
        if(select) {
            contactsDocs.doc(`${select.id}`).collection('messages')
                .orderBy('timestamp', 'asc').onSnapshot(snaps => {
                const messagesWithIds = snaps.docs.map(
                    (d) => Object.assign({}, {id: d.id, ...d.data()})
                ).filter(msg =>  msg.isDeleted !== true);
                setMessages(messagesWithIds)
            })
        }
    }, [globalId, select])

    useEffect(() => {
        scrollToBottom();
    }, [messages])

    function shouldDisplayDeleteIcon(showDeleteButton, selectedReplyIndex, i, message, globalId) {
        return showDeleteButton && selectedReplyIndex === i && message.myId === globalId;
    }

    return (
        <ChatBodyStyled id="chat-body" className="chat-body is-opened">

            <div className="chat-body-inner has-slimscroll">
                {/*<div className="date-divider">*/}
                {/*    <hr className="date-divider-line" />*/}
                {/*        <span className="date-divider-text">Today</span>*/}
                {/*</div>*/}
                {messages && Array.isArray(messages) && messages.map( (message, i) => {
                    const messageBlockStreakClass = messageTimeSpace(messages, i) ? "" : " streak";
                    return (
                        <div key={i} className={`chat-message ${message.myId !== globalId ? `is-sent` : `is-received`} ${messageBlockStreakClass}` }>
                            {messageTimeSpace(messages, i) &&
                                <React.Fragment>
                                    <Avatar name={message.displayName} round={true} size={40}/>
                                    <div className="message-metadata">
                                        <span>{message.displayName}</span>
                                        <span>
                                            {
                                        formatDistance(new Date(message.timestamp), new Date(),
                                            { addSuffix: true, locale: langChoice === 'fr'?fr: null })
                                            }</span>
                                    </div>
                                    <FlexBreak/>
                                </React.Fragment>
                            }

                            <div className="message-block">
                                <ChatMessageText ref={messageComponentRef} className="message-text" onClick={() => {
                                    setSelectedReplyIndex(i);
                                    setShowDeleteButton(true)
                                    setMessageToDelete(message)

                                }  }>
                                    { message.text }
                                {message.attachments && message.attachments.length > 0 &&
                                    <AttachmentList attachments={message.attachments} metas={['name']} loading={fileUploadStatus === 'uploading'}/>
                                }
                                </ChatMessageText>
                            </div>
                            {
shouldDisplayDeleteIcon(showDeleteButton, selectedReplyIndex, i, message, globalId) ?
                                         <i className="mdi mdi-delete" onClick={(i) => {
                                            var confirmDelete = window.confirm("Do you want to delete this comment");
                                            if(confirmDelete){
                                               contactsDocs.doc(`${select.id}`).collection('messages').doc(messageToDelete.id).update(
                                                   { isDeleted : true})
                                               alert.remove(t("general.deletedcomment"))
                                            }
                                    }}> </i> : ""
                                }
                        </div>
                    )
                })}
                <ChatEnd ref={messagesEndRef}></ChatEnd>
            </div>

            <div className="chat-action">
                <div className="chat-action-inner">
                    <div className="control">
                        {/*<div className="dropdown compose-dropdown is-spaced is-accent is-up dropdown-trigger">*/}
                        {/*    <div>*/}
                        {/*        <div className="add-button">*/}
                        {/*            <div className="button-inner">*/}
                        {/*                <i data-feather="plus"></i>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="dropdown-menu" role="menu">*/}
                        {/*        <div className="dropdown-content">*/}
                        {/*            <a >*/}
                        {/*                <div className="media">*/}
                        {/*                    <i data-feather="code"></i>*/}
                        {/*                    <div className="media-content">*/}
                        {/*                        <h3>Code snippet</h3>*/}
                        {/*                        <small>Add and paste a code snippet.</small>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </a>*/}
                        {/*            <a className="dropdown-item">*/}
                        {/*                <div className="media">*/}
                        {/*                    <i data-feather="file-text"></i>*/}
                        {/*                    <div className="media-content">*/}
                        {/*                        <h3>Note</h3>*/}
                        {/*                        <small>Add and paste a note.</small>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            </a>*/}
                        {/*            <hr className="dropdown-divider" />*/}
                        {/*                <a className="dropdown-item">*/}
                        {/*                    <div className="media">*/}
                        {/*                        <i data-feather="server"></i>*/}
                        {/*                        <div className="media-content">*/}
                        {/*                            <h3>Remote file</h3>*/}
                        {/*                            <small>Add a file from a remote drive.</small>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </a>*/}
                        {/*                <a className="dropdown-item">*/}
                        {/*                    <div className="media">*/}
                        {/*                        <i data-feather="monitor"></i>*/}
                        {/*                        <div className="media-content">*/}
                        {/*                            <h3>Local file</h3>*/}
                        {/*                            <small>Add a file from your computer.</small>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </a>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <MessageContainer className="dropdown compose-dropdown is-spaced is-accent is-up dropdown-trigger">
                            {select && (
                                <>
                                    <ChatInput className="chat-textbox" handleKeyUp={setText} onSubmit={onSend}/>
                                    <SendButtonContainer>
                                        <a className="add-button" onClick={onSend}>
                                            <i className="mdi mdi-send"></i>
                                        </a>
                                    </SendButtonContainer>
                                    {messageAttachments.length > 0 &&
                                    <AttachmentListContainer>
                                        {fileUploadStatus === 'uploading' &&
                                        <Loader/>
                                        }
                                        <AttachmentList attachments={messageAttachments} metas={['name']} onDelete={handleDelete}/>
                                    </AttachmentListContainer>
                                    }
                                    <AttachmentUploadContainer>
                                        <FileUpload dest={fileDestination} onUpload={handleMessageUpload} queueStatus={fileUploadStatus} queue={messageAttachments} setQueue={setMessageAttachments} onQueueComplete={handleQueueComplete}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                 viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                 strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                                 className="feather feather-paperclip">
                                                <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                                            </svg>
                                        </FileUpload>
                                    </AttachmentUploadContainer>
                                </>
                            )}


                        </MessageContainer>
                    </div>
                </div>
            </div>

        </ChatBodyStyled>
    )
}

export const ChatRightPanel = ({select}) => {
    const {t} = useTranslation()
    useEffect(()=> {}, [select])

    return (
        <div id="chat-panel" className="chat-panel is-opened">
            <div className="panel-inner">
                <div className="panel-header">
                    <h3>{t("privacySettings.ContactDetails")}</h3>
                    <div className="panel-close">
                        <i data-feather="x"></i>
                    </div>
                </div>

                <div id="dan-details" className="panel-body is-user-details">
                    <div className="panel-body-inner">
                        <div className="subheader">
                            <div
                                className="dropdown details-dropdown is-spaced is-neutral is-right dropdown-trigger ml-auto">
                                <div className="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        <a>
                                            <div className="media">
                                                <i data-feather="user"></i>
                                                <div className="media-content">
                                                    <h3>View profile</h3>
                                                    <small>View this user's profile.</small>
                                                </div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item">
                                            <div className="media">
                                                <i data-feather="mail"></i>
                                                <div className="media-content">
                                                    <h3>Send message</h3>
                                                    <small>Send a message to the user's inbox.</small>
                                                </div>
                                            </div>
                                        </a>
                                        <hr className="dropdown-divider" />
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <i data-feather="share-2"></i>
                                                    <div className="media-content">
                                                        <h3>Share profile</h3>
                                                        <small>Share this user's profile.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <i data-feather="x"></i>
                                                    <div className="media-content">
                                                        <h3>Block user</h3>
                                                        <small>Block this user permanently.</small>
                                                    </div>
                                                </div>
                                            </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                        {select && select.users.length < 2 &&
                            <div className="details-avatar">
                                <Avatar name={select && select.users[0] && select.users[0].displayName} size={110} round={true}/>
                                <div className="user-meta has-text-centered">{select.users[0] && select.users[0].displayName}</div>
                                    {/*{select && isContactOnline(select) ?*/}
                                    {/*    <CallButton className="call-me">*/}
                                    {/*        <i className="mdi mdi-phone"></i>*/}
                                    {/*    </CallButton>*/}
                                    {/*    :*/}
                                    {/*    <CallButton className="call-me offline">*/}
                                    {/*        <i className="mdi mdi-phone"></i>*/}
                                    {/*    </CallButton>*/}
                                    {/*}*/}
                            </div>
                        }

                        {select && select.users.length > 1 &&
                            <div className="user-meta">
                                <UserDetailList>
                                    {select && select.users.map((user, key) => {
                                        return (
                                            <li key={key}>
                                                <Avatar name={user.displayName} size={40} round={true}/>
                                                <span className="name">{user.displayName}</span>
                                                {user.status && user.status.state === userState.ONLINE ?
                                                    <UserStatus className="online" />
                                                    :
                                                    <UserStatus className="offline" />
                                                }
                                            </li>);
                                    })}
                                </UserDetailList>
                            </div>
                        }

                        {/* <div className="user-badges" >
                            <div className="hexagon is-red">
                                <div>
                                    <i className="mdi mdi-heart"></i>
                                </div>
                            </div>
                            <div className="hexagon is-green">
                                <div>
                                    <i className="mdi mdi-dog"></i>
                                </div>
                            </div>
                            <div className="hexagon is-accent">
                                <div>
                                    <i className="mdi mdi-flash"></i>
                                </div>
                            </div>
                            <div className="hexagon is-blue">
                                <div>
                                    <i className="mdi mdi-briefcase"></i>
                                </div>
                            </div>
                        </div> */}

                        <div className="user-about" style={{display: 'none'}}>
                            <label>About Me</label>
                            <div className="about-block">
                                <i className="mdi mdi-domain"></i>
                                <div className="about-text">
                                    <span>Works at</span>
                                    <span><a className="is-inverted" >WebSmash Inc.</a></span>
                                </div>
                            </div>
                            <div className="about-block">
                                <i className="mdi mdi-school"></i>
                                <div className="about-text">
                                    <span>Studied at</span>
                                    <span><a className="is-inverted" >Riverdale University</a></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="stella-details" className="panel-body is-user-details is-hidden">
                    <div className="panel-body-inner">

                        <div className="subheader">
                            <div className="action-icon">
                                <i className="mdi mdi-video"></i>
                            </div>
                            <div className="action-icon">
                                <i className="mdi mdi-camera"></i>
                            </div>
                            <div className="action-icon">
                                <i className="mdi mdi-microphone"></i>
                            </div>
                            <div
                                className="dropdown details-dropdown is-spaced is-neutral is-right dropdown-trigger ml-auto">
                                <div>
                                    <div className="action-icon">
                                        <i className="mdi mdi-dots-vertical"></i>
                                    </div>
                                </div>
                                <div className="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        <a >
                                            <div className="media">
                                                <i data-feather="user"></i>
                                                <div className="media-content">
                                                    <h3>View profile</h3>
                                                    <small>View this user's profile.</small>
                                                </div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item">
                                            <div className="media">
                                                <i data-feather="mail"></i>
                                                <div className="media-content">
                                                    <h3>Send message</h3>
                                                    <small>Send a message to the user's inbox.</small>
                                                </div>
                                            </div>
                                        </a>
                                        <hr className="dropdown-divider" />
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <i data-feather="share-2"></i>
                                                    <div className="media-content">
                                                        <h3>Share profile</h3>
                                                        <small>Share this user's profile.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <i data-feather="x"></i>
                                                    <div className="media-content">
                                                        <h3>Block user</h3>
                                                        <small>Block this user permanently.</small>
                                                    </div>
                                                </div>
                                            </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="details-avatar">
                            <img src="https://via.placeholder.com/300x300" data-demo-src="assets/img/avatars/stella.jpg"
                                 alt="" />
                                <div className="call-me">
                                    <i className="mdi mdi-phone"></i>
                                </div>
                        </div>

                        <div className="user-meta has-text-centered">
                            <h3>Stella Bergmann</h3>
                            <h4>Shop Owner</h4>
                        </div>

                        <div className="user-badges">
                            <div className="hexagon is-purple">
                                <div>
                                    <i className="mdi mdi-bomb"></i>
                                </div>
                            </div>
                            <div className="hexagon is-red">
                                <div>
                                    <i className="mdi mdi-check-decagram"></i>
                                </div>
                            </div>
                            <div className="hexagon is-accent">
                                <div>
                                    <i className="mdi mdi-flash"></i>
                                </div>
                            </div>
                        </div>

                        <div className="user-about">
                            <label>About Me</label>
                            <div className="about-block">
                                <i className="mdi mdi-domain"></i>
                                <div className="about-text">
                                    <span>Works at</span>
                                    <span><a className="is-inverted" >Trending Fashions</a></span>
                                </div>
                            </div>
                            <div className="about-block">
                                <i className="mdi mdi-map-marker"></i>
                                <div className="about-text">
                                    <span>From</span>
                                    <span><a className="is-inverted" >Oklahoma City</a></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="daniel-details" className="panel-body is-user-details is-hidden">
                    <div className="panel-body-inner">

                        <div className="subheader">
                            <div className="action-icon">
                                <i className="mdi mdi-video"></i>
                            </div>
                            <div className="action-icon">
                                <i className="mdi mdi-camera"></i>
                            </div>
                            <div className="action-icon">
                                <i className="mdi mdi-microphone"></i>
                            </div>
                            <div
                                className="dropdown details-dropdown is-spaced is-neutral is-right dropdown-trigger ml-auto">
                                <div>
                                    <div className="action-icon">
                                        <i className="mdi mdi-dots-vertical"></i>
                                    </div>
                                </div>
                                <div className="dropdown-menu" role="menu">
                                    <div className="dropdown-content">
                                        <a >
                                            <div className="media">
                                                <i data-feather="user"></i>
                                                <div className="media-content">
                                                    <h3>View profile</h3>
                                                    <small>View this user's profile.</small>
                                                </div>
                                            </div>
                                        </a>
                                        <a className="dropdown-item">
                                            <div className="media">
                                                <i data-feather="mail"></i>
                                                <div className="media-content">
                                                    <h3>Send message</h3>
                                                    <small>Send a message to the user's inbox.</small>
                                                </div>
                                            </div>
                                        </a>
                                        <hr className="dropdown-divider" />
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <i data-feather="share-2"></i>
                                                    <div className="media-content">
                                                        <h3>Share profile</h3>
                                                        <small>Share this user's profile.</small>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item">
                                                <div className="media">
                                                    <i data-feather="x"></i>
                                                    <div className="media-content">
                                                        <h3>Block user</h3>
                                                        <small>Block this user permanently.</small>
                                                    </div>
                                                </div>
                                            </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="details-avatar">
                            <img src="https://via.placeholder.com/300x300" data-demo-src="assets/img/avatars/daniel.jpg"
                                 alt="" />
                                <div className="call-me">
                                    <i className="mdi mdi-phone"></i>
                                </div>
                        </div>

                        <div className="user-meta has-text-centered">
                            <h3>Daniel Wellington</h3>
                            <h4>Senior Executive</h4>
                        </div>

                        <div className="user-badges">
                            <div className="hexagon is-accent">
                                <div>
                                    <i className="mdi mdi-google-cardboard"></i>
                                </div>
                            </div>
                            <div className="hexagon is-blue">
                                <div>
                                    <i className="mdi mdi-pizza"></i>
                                </div>
                            </div>
                            <div className="hexagon is-accent">
                                <div>
                                    <i className="mdi mdi-linux"></i>
                                </div>
                            </div>
                            <div className="hexagon is-red">
                                <div>
                                    <i className="mdi mdi-puzzle"></i>
                                </div>
                            </div>
                        </div>

                        <div className="user-about">
                            <label>About Me</label>
                            <div className="about-block">
                                <i className="mdi mdi-domain"></i>
                                <div className="about-text">
                                    <span>Works at</span>
                                    <span><a className="is-inverted" >Peelman & Sons</a></span>
                                </div>
                            </div>
                            <div className="about-block">
                                <i className="mdi mdi-map-marker"></i>
                                <div className="about-text">
                                    <span>From</span>
                                    <span><a className="is-inverted" >Los Angeles</a></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>

)
}


export const MessengerPanel = props => {
    return (
        <div className="chat-wrapper is-standalone" style={{display: `${props.showMessenger? 'block': 'none'}`}}>
            <div className="chat-inner">
                <ChatTopNav setMessenger={props.setMessenger}/>
                <ChatSideBar />
                <ChatBody />
                <ChatRightPanel />
            </div>
        </div>
    )
}
export default MessengerPanel

