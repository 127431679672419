import { useTranslation } from "react-i18next"
import { AiOutlineSync } from "react-icons/ai"
import { userRoles } from "../../stores/enums/userRoles"

const LoadUsers = ({roleSql , count}) => {
    const {t} = useTranslation()
    return (
        roleSql === userRoles.CMP_ADMIN_BUSINESS ||  roleSql === userRoles.MTR_ADMIN ?  <div className="flex flex-wrap lg:flex-nowrap justify-items-start ">
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
                    <div className="flex justify-between items-start">
                        <div>
                            <p className="font-bold text-gray-400">{t("dashboard.loadUsers")}</p>
                            <p className="text-2xl">{count}</p>
                        </div>
                        <button
                            type="button"
                            className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4 bg-green-600"
                        >
                            <AiOutlineSync />
                        </button>
                    </div>
                </div>
            </div> : ""
    )
}

export default LoadUsers