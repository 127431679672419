import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import _, { now } from 'lodash';
import { Multiselect } from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import styled from "styled-components";
import * as Yup from 'yup';
import AttachmentList from "../components/AttachmentList";
import FileUpload from '../components/FileUpload';
import { InputText, SwitchBox } from "../components/FormElements";
import { activeJobs, firebaseInstance, firestore, formTemplates, postsDoc, usersDoc } from "../firestore/firestoreService";
import { MyContext } from "../stores";
import { useCatReducer } from '../stores/categories';
import { UserAction } from '../stores/enums/UserAction';
import arraySortByField from '../utils/arraySortByField';
import Loader from '../components/Loader'

const validationSchema = Yup.object({
    title: Yup.string('Enter a title').required('This field is required'),
    endDate: Yup.number().required('This field is required'),
    startDate: Yup.number().required('This field is required'),
})

const FormikWrapper = styled.div`
    padding:15px;
    margin-top:15px;
`

const FormWrapper = styled.div`
    margin-bottom:15px;

    label{
        font-size: .9rem;
        font-weight: 500;
        color: #393a4f;
    }
    
    .field {
      margin-bottom: 16px;
    } 

    .richTextContainer{
        border:1px solid #e8e8e8;
        margin:10px 0;
    }

    .toolbar{
        height:30px;
        margin:0;
        padding:1px 0px 17px 5px;
    }

    .richInput{
        padding:10px;
    }

    .toolButton{
        height:24px;
    }

    .crop-button{
        vertical-align:middle;
    }
   

    .react-datepicker-wrapper{
        input{
            border: 1px solid #cccccc;
            border-radius: 4px;
            padding: 5px;
            height: 36px;
        }
    }
`

export const EditInternshipObjectFormPG = ({job}) =>{

    const {slug} = useParams()
    const history = useHistory()
    const alert = useAlert()
    const location = useLocation();
    const {me, globalId, globalIdSql, organizationId, langChoice} = useContext(MyContext)
    const [data, setData] = useState({});
    const [currentJob, setCurrentJob] = useState(null)
    const [users, setUsers] = useState([])
    const [students, setStudents] = useState([])
    const [teachers, setTeachers] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [forms, setForms] = useState([])
    const [studentforms, setStudentforms] = useState(null)
    const [teacherforms, setTeacherforms] = useState(null)
    const [supervisorforms, setSupervisorforms] = useState(null)
    const [chosenStudents, setChosenStudents] = useState([])
    const [chosenTeachers, setChosenTeachers] = useState([])
    const [chosenSupervisors, setChosenSupervisors] = useState([])
    const [chosenWatchers, setChosenWatchers] = useState([])
    const [chosenCategories, setChosenCategories] = useState([])
    const [chosenJobs, setChosenJobs] = useState([])
    const [categories, dispatch] = useCatReducer([])
    const [uploadedAttachments, setUploadedAttachments] = useState([])
    const [attachments, setAttachments] = useState([])
    const [fileUploadStatus, setFileUploadStatus] = useState("idle");
    const [fileDestination, setFileDestination] = useState("")
    const [createdDoc, setCreatedDoc] = useState("")
    const [activeJob, setActiveJob] = useState({})
    const [jobs, setJobs] = useState([])
    const [groupMode, setGroupMode] = useState(false)
    const [createMode, setCreateMode] = useState(false)
    const {t} = useTranslation()
    const addUsers = firebaseInstance().functions().httpsCallable('relatedUsersInternships')

    useEffect(() => {
        dispatch({type: langChoice === 'fr' ? 'SET_FR': 'SET_EN', payload: 'field.'})
    }, [langChoice])

    useEffect(() => {
        if(location && location.pathname.includes("create")){
            setCreateMode(true);
        }
    }, [location])

    useEffect(() => {
        if(!createMode && slug && globalId){
            let controller = new AbortController();
            const fetchData = async () => {
                let data = await fetch(`https://api.bazookka.com/internshipObjects/edit/${slug}`, {method: 'get', headers: {'Content-Type': 'application/json', eid: globalId}, signal: controller.signal})
                const json = await data.json();
                setData(json);
            }

            const fetchAttachments = async () => {
                let data = await fetch(`https://api.bazookka.com/internshipObjects/shares/${slug}`, {method: 'get', headers: {'Content-Type': 'application/json', eid: globalId}, signal: controller.signal})
                const json = await data.json();
                setUploadedAttachments(json);
            }

            fetchData().catch(e => console.log("ERROR",e));
            fetchAttachments().catch(e => console.log("ERROR",e));
            return () => controller?.abort();
        }
    }, [createMode, slug, globalId])

    useEffect(() => {
        if(createMode && globalId){
            let controller = new AbortController();
            const fetchData = async () => {
                let data = await fetch(`https://api.bazookka.com/internshipObjects/create`, {method: 'get', headers: {'Content-Type': 'application/json', eid: globalId}, signal: controller.signal})
                const json = await data.json();
                setData({users: json});
            }

            fetchData().catch(e => console.log("ERROR",e));
            return () => controller?.abort();
        }
    }, [globalId, createMode]);

    useEffect(()=> {
        if(data){
                setCurrentJob(data)
                const usersByRoles = arraySortByField(data?.participants ?? [], 'role');
                // if(doc.data().categories){
                //     setChosenCategories(doc.data().categories);
                // }
                setChosenStudents(usersByRoles?.student ?? []);
                setChosenTeachers(usersByRoles?.teacher ?? []);
                setChosenSupervisors(usersByRoles?.supervisor ?? []);
                setChosenWatchers(usersByRoles?.watcher ?? []);
                setUsers(data?.users ?? [])
                //setChosenJobs(doc.data().job || null)
        }
    }, [data])

    const onSubmit = async (data, {setSubmitting}) => {
        //Adding the internship role
        const tempChosenStudents = chosenStudents.map(
            user => ({id: user.id, role: 'student'})
        );
        const tempChosenTeachers = chosenTeachers.map(
            user => ({id: user.id, role: 'teacher'})
        );
        const tempChosenSupervisors = chosenSupervisors.map(
            user => ({id: user.id, role: 'supervisor'})
        );

        const tempChosenWatchers = chosenWatchers.map(
            user => ({id: user.id, role: 'watcher'})
        );

        setGroupMode(tempChosenStudents.length > 1);
        const internalGroupMode = tempChosenStudents.length > 1;

        //arr.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        data.participants = [...tempChosenStudents, ...tempChosenTeachers, ...tempChosenSupervisors, ...tempChosenWatchers]
            .filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
        // data.categories = chosenCategories.map(cat => (cat));
        
        console.log("submit dATA", data);
        console.log("SUBMIT GROUP MODE", groupMode);

        if(currentJob && !createMode) {
            //EDIT
            let m = await fetch(`https://api.bazookka.com/internshipObjects/`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    eid: globalId
                },
                body: JSON.stringify({
                     id: data.id,
                     title: data.title,
                     tiny: data.tiny,
                     type: 'activeJob',
                     author_id: globalIdSql,
                     state: data.active,
                     meta: data.meta,
                     organisation_id: organizationId,
                     participants: data.participants
                })
            }).catch( e => {
                console.log("There was an error editing the post: ", e)
            })

            if(attachments.length > 0){
                setCreatedDoc(slug);
                setFileDestination(`my_internship_attachments/${slug}/${globalId}/`);
                setFileUploadStatus("uploading");
            }else{
                history.goBack();
            }
        }else{
            //CREATE
            let apiUrl = '';
            if(internalGroupMode){
                apiUrl = "https://api.bazookka.com/internshipObjects/group/";
            }else{
                apiUrl = "https://api.bazookka.com/internshipObjects/";
            }

            console.log("CREATE DATA", data);

            let m = await fetch(apiUrl, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    eid: globalId
                },
                body: JSON.stringify({
                    title: data.title,
                    tiny: data.tiny,
                    type: 'activeJob',
                    author_id: globalIdSql,
                    state: data.active,
                    meta: data.meta,
                    organisation_id: organizationId,
                    participants: data.participants
                })
            }).catch( e => {
                console.log("There was an error editing the post: ", e)
            })
            const createdPosts = await m.json();

            if(attachments.length > 0){
                setCreatedDoc(createdPosts.posts);
                setFileDestination(`my_internship_attachments/${createdPosts.mentor}/${globalId}/`);
                setFileUploadStatus("uploading");
            }else{
                // history.goBack();
            }
        }
    }

    const onSelect = (value) =>{
        setChosenCategories(value)
    }

    const onRemove = (value) => {
        setChosenCategories(value)
    }

    const handleUpload = (fileRef, metadata) => {
        const createShare = async (slug, newAttachment, title) => {
            fetch(`https://api.bazookka.com/internshipObjects/shares/${slug}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    eid: globalId
                },
                body: JSON.stringify({
                    meta: newAttachment,
                    title: title,
                })
            })
        }

        console.log(fileRef)

        fileRef.getDownloadURL().then((url) => {
            const newAttachment = {
                name: metadata.name,
                path: url,
                type: metadata.contentType,
                timestamp: Date.now(),
                creatorId: globalId
            };

            if(groupMode){
                createdDoc.forEach(id => {
                    createShare(id, newAttachment, metadata.name)    
                })
            }else{
                createShare(slug, newAttachment, metadata.name)
            }
        });
    }

    const handleDelete = (toDelete) => {
        setAttachments((prevState) => {
            const newState = prevState.filter(function (att) {
                return att !== toDelete;
            });
            return newState;
        });
    };

    const handleQueueComplete = () => {
        //Reset all the states and Close the modal
        setFileUploadStatus("idle");
        setAttachments([]);
        setFileDestination("");
        setCreatedDoc("");
        history.goBack();
    };

    if(!data) return <Loader></Loader>

    return (
        <React.Fragment>
            <Link to={`/me/io`}>
                <button className="button"><i className="mdi mdi-keyboard-backspace"></i>{t('general.goback')}</button>
            </Link>
            <FormikWrapper className="card">
                <Formik initialValues={{active: true,title: "", tiny: "",jobSelect: "",meta: {businessName: "", startDate: Date.now(), endDate: Date.now()},...data}} onSubmit={onSubmit} enableReinitialize={!createMode}>
                    {({values, setFieldValue, handleSubmit, isSubmitting})=> {
                        return (
                            <Form>
                                <FormWrapper>
                                    <div className="field">
                                        <SwitchBox
                                            label={t('jobs.active')}
                                            value={values.state}
                                            name="active"
                                        />
                                    </div>
                                    <div className="field">
                                        <InputText
                                            label={ t('jobs.title')}
                                            value={values.title}
                                            name="title" />
                                    </div>
                                    <div className="field">
                                        <InputText
                                            label={ langChoice === 'fr'? 'Petit résumé': 'Tiny description'}
                                            value={values.tiny}
                                            name="tiny" />
                                    </div>
                                    {/* <div className="field">
                                        <SwitchBox
                                            label={langChoice === 'fr' ? 'Sélectionné': 'Selected'}
                                            name="jobSelect"
                                        />
                                    </div> */}

                                    {/* {values.jobSelect ? (
                                        <div className="field">
                                            <label>{t('jobs')}</label>
                                            <Multiselect
                                                options={jobs.map( job => {
                                                    return {key: job.id, value: job.title, ...job}
                                                })}
                                                singleSelect={true}
                                                selectedValues={values.job}
                                                no
                                                displayValue={'value'}
                                                onSelect={ v => {
                                                    setFieldValue('job', v)
                                                    setFieldValue('businessName', v[0].title)
                                                }}
                                                placeholder={t('overlay.select')}
                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                            ></Multiselect>
                                        </div>
                                    ): ( */}
                                            <div className="field">
                                                <InputText
                                                    label={ langChoice === 'fr'? 'Nom de compagnie': 'Business Name'}
                                                    value={values.meta.businessName}
                                                    name="meta.businessName" />
                                            </div>
                                            {/* <div className="field">
                                                <label>{t('general.description')}</label>
                                                <WriteRichText
                                                   text={values.description}
                                                    name={`description`} />

                                            </div> */}
                                    {/* )} */}

                                    {/* <div className="field">
                                        <label>{t('general.category')}</label>
                                        <Multiselect
                                            options={categories}
                                            selectedValues={chosenCategories}
                                            displayValue={'value'}
                                            onSelect={ onSelect }
                                            onRemove={ onRemove }
                                            placeholder={t('overlay.select')}
                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                        ></Multiselect>
                                    </div> */}
                                    <div className="field">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name={'meta.startDate'}
                                                label={ t('jobs.startDate') }
                                                onChange={(v) => {
                                                    setFieldValue('meta.startDate', new Date(v).getTime())
                                                }}
                                                value={values.meta.startDate}
                                                renderInput={params => <TextField {...params} />} 
                                                />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="field">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name={'meta.endDate'}
                                                label={ t('jobs.endDate') }
                                                onChange={(v) => {
                                                    setFieldValue('meta.endDate', new Date(v).getTime())
                                                }}
                                                minDate={values.meta.endDate}
                                                value={values.meta.endDate}
                                                renderInput={params => <TextField {...params} />} 
                                                />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="field">
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={6}>
                                                <label>{t('general.selectStudents')}</label>
                                                <div className="control">
                                                    <Multiselect
                                                        options={users}
                                                        keepSearchTerm={true}
                                                        selectedValues={chosenStudents}
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        sselectionLimit={1}
                                                        onSelect={(v)=> setChosenStudents(v)}
                                                        onRemove={(v)=> setChosenStudents(v)}
                                                        displayValue="display_name"
                                                        placeholder={t('overlay.select')}
                                                        // disable={groupMode}
                                                    />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={4} md={6}>
                                                <SwitchBox
                                                    label={t('general.studentdocsign')}
                                                    value={values.studentSignDoc}
                                                    name={'studentSignDoc'}
                                                ></SwitchBox>
                                            </Grid>
                                            {values.studentSignDoc && (
                                                <Grid item xs={12} md={12}>
                                                    <div className="control">
                                                        <Multiselect
                                                            options={forms}
                                                            selectedValues={values.studentforms}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            singleSelect={true}
                                                            onSelect={(v)=> {
                                                                setFieldValue('studentforms', v)
                                                            }}
                                                            displayValue="name"
                                                            placeholder={t('overlay.select')}
                                                        />
                                                    </div>
                                                </Grid>
                                            )} */}
                                        </Grid>

                                    </div>
                                    <div className="field">
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={6}>
                                                <label>{t('general.selectTeachers')}</label>
                                                <div className="control">
                                                    <Multiselect
                                                        options={users}
                                                        keepSearchTerm={true}
                                                        selectedValues={chosenTeachers}
                                                        selectionLimit={1}
                                                        onSelect={(v)=> setChosenTeachers(v)}
                                                        onRemove={(v)=> setChosenTeachers(v)}
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        displayValue="display_name"
                                                        placeholder={t('overlay.select')}
                                                    />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={4} md={6}>
                                                <SwitchBox
                                                    label={t('general.teacherdocsign')}
                                                    value={values.teacherSignDoc}
                                                    name={'teacherSignDoc'}
                                                ></SwitchBox>
                                            </Grid>
                                            {values.teacherSignDoc && (
                                                <Grid item xs={12} md={12}>
                                                    <div className="control">
                                                        <Multiselect
                                                            options={forms}
                                                            selectedValues={values.teacherforms}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            singleSelect={true}
                                                            onSelect={(v)=> {
                                                                setFieldValue('teacherforms', v)
                                                            }}
                                                            displayValue="name"
                                                            placeholder={t('overlay.select')}
                                                        />
                                                    </div>
                                                </Grid>
                                            )} */}

                                        </Grid>

                                    </div>
                                    <div className="field">
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={6}>
                                                <label>{t('general.selectSupervisors')}</label>
                                                <div className="control">
                                                    <Multiselect
                                                        keepSearchTerm={true}
                                                        options={users}
                                                        selectionLimit={1}
                                                        selectedValues={chosenSupervisors}
                                                        onSelect={(v)=> setChosenSupervisors(v)}
                                                        onRemove={(v)=> setChosenSupervisors(v)}
                                                        displayValue="display_name"
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        placeholder={t('overlay.select')}
                                                    />
                                                </div>
                                            </Grid>
                                            {/* <Grid item xs={4} md={6}>
                                                <SwitchBox
                                                    label={t('general.supervisordocsign')}
                                                    value={values.supervisorSignDoc}
                                                    name={'supervisorSignDoc'}
                                                ></SwitchBox>
                                            </Grid>
                                            {values.supervisorSignDoc && (
                                                <Grid item xs={12} md={12}>
                                                    <div className="control">
                                                        <Multiselect
                                                            options={forms}
                                                            selectedValues={values.supervisorforms}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            singleSelect={true}
                                                            onSelect={(v)=> {
                                                                setFieldValue('supervisorforms', v)
                                                            }}
                                                            onRemove={(v)=> setTeacherforms(v)}
                                                            displayValue="name"
                                                            placeholder={t('overlay.select')}
                                                        />
                                                    </div>
                                                </Grid>
                                            )} */}
                                        </Grid>

                                    </div>
                                    <div className="field">
                                        <label>{t('general.selectwatcher')}</label>
                                        <div className="control">
                                            <Multiselect
                                                keepSearchTerm={true}
                                                options={users}
                                                selectedValues={chosenWatchers}
                                                onSelect={(v)=> setChosenWatchers(v)}
                                                onRemove={(v)=> setChosenWatchers(v)}
                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                displayValue="display_name"
                                                placeholder={t('overlay.select')}
                                            />
                                        </div>
                                    </div>
                                    <div className="compose-option is-centered">
                                        <label>{t('general.attachmentsToAdd')}</label>
                                        <FileUpload
                                            dest={fileDestination}
                                            onUpload={handleUpload}
                                            queueStatus={fileUploadStatus}
                                            queue={attachments}
                                            setQueue={setAttachments}
                                            onQueueComplete={handleQueueComplete}
                                        >
                                            <div className="button is-fullwidth"><i className="mdi mdi-plus"></i>{t('general.uploadFile')}</div>
                                        </FileUpload>
                                        <div>
                                            <AttachmentList
                                                attachments={attachments}
                                                metas={["name"]}
                                                onDelete={handleDelete}
                                                isHorizontal
                                                toUpload
                                            />
                                        </div>
                                    </div>
                                    {uploadedAttachments.length > 0 &&
                                        <div className="compose-option is-centered">
                                            <label>{t('general.attachments')}</label>
                                            <AttachmentList
                                                attachments={uploadedAttachments}
                                                metas={["name"]}
                                                isHorizontal
                                            />
                                        </div>
                                    }
                                </FormWrapper>
                                <Button color="primary" variant="contained" fullWidth type="submit" disabled={isSubmitting}>
                                    {t('general.save')}
                                </Button>
                            </Form>
                        )
                    }}
                </Formik>
            </FormikWrapper>
        </React.Fragment>
    )
};