import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useAlert } from "react-alert";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { InputText } from "../../components/FormElements";
import { MyContext } from "../../stores";
import axios from "axios"
import ReactSelect from "react-select";
import * as Yup from 'yup'
import { ErrorContainer } from "../../components/FormManager/ErrorContainer";

const Cover = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      width: 900px;
      h1 {
        text-align: center;
        color: white;
        font-size: 2rem;
      }
    } 
    
`

const BoxFormStyled = styled.div`
  border: 1px solid #e8e8e8;
  background: white;
  padding: 2rem;
  border-radius: 5px;
  .groups-form {
    display: grid;
    grid-template-columns: 1fr 300px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e8e8e8;
    .sidebar {
      border-left: 1px solid #e8e8e8;
      .field {
        width: 80%;
        margin: auto;
      }
    }
  }
`

const UserStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  width: 100%;
  margin-top: 20px;
  .users {
    display: grid;
    grid-template-columns: 56px 50px 1fr;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    margin: auto;
    &.right {
      width: 90%;
    }
    .round .rdelete.toDelete{
        display: grid;
        opacity: 1;
        pointer-events: auto;
        transition: opacity 300ms ease-in;
    }
    :hover {
      background-color: var(--bazookka-blue);
      color: white;
      .button-control a {
        color: white;
      }
      .round{
        .rdelete {
          display: grid;
          opacity: 1;
          pointer-events: auto;
          transition: opacity 300ms ease-in;
        }
      }
      
    }
    .round {
      padding: 4px;
      border: 3px solid var(--bazookka-orange);
      width: fit-content;
      border-radius: 50%;
      position: relative;
      .rdelete {
        display: grid;
        align-items: center;
        justify-content: center;
        color: white;
        position: absolute;
        width: 54px;
        height: 54px;
        min-width: 54px;
        min-height: 54px;
        top:-3px;
        left: -3px;
        opacity: 0;
        pointer-events: none;
        border-radius: 50%;
        background-color: rgba(10,10,10,0.7);
        transition: opacity 300ms ease-in;
      }
      
    }
    .button-control {
      display: flex;
      justify-content: right;
      flex-direction: column;
    }
    .button-control a {
      display: block;
      text-align: end;
      font-size: 1rem;
    }
  }

`
const UserList = ({ onRemove, users, right }) => {

    const { aremove, uremove} = useContext(MyContext)

    return (
        <UserStyled>
            {users && users.length > 0 && users.map((user, key) => {
                let toDeleteClass;
                if (right) {
                    if (aremove.find(u => u.id === user.id)) {
                        toDeleteClass = "toDelete";
                    } else {
                        toDeleteClass = "";
                    }
                } else {
                    if (uremove.includes(user.id)) {
                        toDeleteClass = "toDelete";
                    } else {
                        toDeleteClass = "";
                    }
                }
                return (
                    <div className={`users ${right || ''}`} key={key}>
                        <div className="round">
                            <div className={`rdelete ${toDeleteClass}`} onClick={() => { onRemove(user.id) }}>
                                <i className="mdi mdi-delete"></i>
                            </div>
                            {user.avatar ?
                                <Avatar src={user.avatar} round={true} size={40} /> :
                                <Avatar round={true} size={40} name={user.displayName} />}
                        </div>
                        {user.displayName}
                        <div className="button-control">
                            <Link to={`/user/${user.eid}`} ><i className="mdi mdi-eye-outline"></i></Link>
                            <a><i className="mdi mdi-chat"></i></a>
                        </div>
                    </div>
                )
            })}

        </UserStyled>
    )
}
export default function EditGroupsMigration() {
    const { id } = useParams()
    const history = useHistory()
    const { t } = useTranslation()
    const { organizationId, aremove, uremove, setaRemove, setuRemove } = useContext(MyContext)
    const [apicall, setApicall] = useState(true)
    const [groupSql, setGroupSql] = useState([])
    const [usersInGroup, setUsersInGroup] = useState([])
    const [adminsInGroup, setAdminsInGroup] = useState([])
    const alert = useAlert()
    const [orgUsers, setOrgUsers] = useState([])
    const [orgAdmins, setOrgAdmins] = useState([])
    const [userToAdd, setUserToAdd] = useState()
    const [adminToAdd, setAdminToAdd] = useState()

    const selectRef = useRef()
    const selectRefAdmin = useRef()

    const handleDelete = (id) => {
        const removed = usersInGroup.find(u => u.user.id == id)
        const updatedUsers = usersInGroup.filter(u => u.user.id != id)
        const updatedAdmins = adminsInGroup.filter(u => u.user.id != id)
        setAdminsInGroup(updatedAdmins)
        orgUsers.push(removed.user)
        setOrgUsers(orgUsers)
        orgAdmins.push(removed.user)
        setOrgAdmins(orgAdmins)

        groupSql.users = updatedUsers
        setGroupSql(groupSql)
        setUsersInGroup(updatedUsers)
    }

    const handleDeleteAdmin = (id) => {
        const removed = adminsInGroup.find(u => u.user.id == id)
        const updatedAdmins = adminsInGroup.length > 0 && adminsInGroup.user ? adminsInGroup.user.filter(u => u.user.id != id) : []
        setAdminsInGroup(updatedAdmins)
        orgAdmins.push(removed.user)
        setOrgAdmins(orgAdmins)
            const found = usersInGroup.findIndex(uig => {
                return uig.user.id === removed.user.id
            })
            usersInGroup[found].roles = ["user"]
    }

    const getGroupSql = async () => {
        if (id !== 'create') {
            const { data } = await axios.get(`https://api.bazookka.com/groups/${id}`)
            setGroupSql(data)
        }
    }

    useEffect(() => {
        setUsersInGroup(groupSql.users)
        groupSql && groupSql.users && setAdminsInGroup(groupSql.users.filter(u => u && u.roles && u.roles.includes("admin")))
    }, [groupSql])

    useEffect(() => {

        getGroupSql()
    }, [id, organizationId])


    //get users of the logged organization
    useEffect(() => {
        const getOrgUsersAsync = async () => {
            const { data } = await axios.get(`https://api.bazookka.com/organizations/${organizationId}/group/${id}/users`)
            usersInGroup && setOrgUsers(data.filter(u => usersInGroup.findIndex(uig => uig.user.id === u.id) === -1))
            usersInGroup && setOrgAdmins(data.filter(u => adminsInGroup.findIndex(uig => uig.user.id === u.id) === -1))
        }
        if (organizationId) {
            getOrgUsersAsync()
        }
    }, [id, apicall, organizationId, adminToAdd])


    const [initialValues, setValue] = useState(id == 'create' ? {
        name: "",
        school: "",
        description: "",
        chat: "false",
        users: []

    } : groupSql
    )

    const onAbort = () => {
        history.goBack()
    }

    const onSubmitSql = async (values) => {
        // set user roles as user and admin
        adminsInGroup.forEach(item => {
            if (usersInGroup.findIndex(uig => uig.user.id === item.user.id) != -1) {
                usersInGroup[usersInGroup.findIndex(uig => uig.user.id === item.user.id)].roles = ['user', 'admin']
                setUsersInGroup(usersInGroup)
            }
        }
        )

        if (id != 'create') {
            await axios.put(`https://api.bazookka.com/groups/${id}`, values)
            await axios.put(`https://api.bazookka.com/groups/${id}/user`, usersInGroup)
            alert.success(t("general.updatedgroup"))
            history.push("/me/migration/settings/groups")

        } else {
            values.users = usersInGroup
            axios.post(`https://api.bazookka.com/groups/${id}/school/${organizationId}`, values).then(() => history.push("/me/migration/settings/groups"))
            alert.success(t("general.createdgroup"))
            history.push("/me/migration/settings/groups")
        }

    }

    const onUpdateUsers = async (selectRef) => {
        if(!userToAdd) {
            alert.info(t("settings.groups.misclickAddUserError")) 
        }
        if (usersInGroup && usersInGroup.length > 0 && userToAdd) {
            setUsersInGroup([...usersInGroup, { roles: ["user"], user: userToAdd }])
        } else {
            setUsersInGroup([{ roles: ["user"], user: userToAdd }])
        }

        if(userToAdd){
            setOrgUsers(orgUsers.filter(ou => ou.id != userToAdd.id))
        }
        selectRef.current.select.clearValue();
    }

    const onUpdateAdmins = async (selectRefAdmin) => {
        if(!adminToAdd){
            alert.info(t("settings.groups.misclickAddAdminError")) 
        } 
        if(usersInGroup && usersInGroup.length > 0){
            if (usersInGroup.findIndex(uig => uig.user.id === adminToAdd.id) != -1) {

                if (adminsInGroup && adminsInGroup.length > 0) {
                    setAdminsInGroup([...adminsInGroup, { roles: ["user", "admin"], user: adminToAdd }])
                } else {
                    setAdminsInGroup([{ roles: ["user", "admin"], user: adminToAdd }])
                }
            } else {
                orgAdmins.push(adminToAdd)
                setOrgAdmins(orgAdmins)
                alert.show(t("settings.groups.addAdminError"))
            }
    
            setOrgAdmins(orgAdmins.filter(ou => ou.id != adminToAdd.id))
            selectRefAdmin.current.select.clearValue();
        }
    }


    return (
        <div className="event-page-wrapper">
            <Cover className="event-cover" style={{ background: `url(assets/old/wallpaper.png)` }}>
                <div className="title">
                    <h1>{groupSql && groupSql.name}</h1>
                </div>
            </Cover>
            <BoxFormStyled className="event-content">
                <Formik
                    initialValues={id == "create" ? {
                        name: "",
                        school: "",
                        description: "",
                        chat: "false"
                    } : groupSql}
                    onSubmit={onSubmitSql}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        name: Yup.string().required(t('validation.required')),
                        description : Yup.string().required(t('validation.required')),
                    })}
                >
                    {({ values }) => {
                        return (
                            <Form>
                                <div className="groups-form">
                                    <div style={{ width: '95%' }}>
                                        <div className="field">
                                            <InputText
                                                label={t('listing.name')}
                                                value={values ? values.name : ""}
                                                name="name"
                                                disabled={values && values.noDelete}
                                            />
                                        </div>
                                        <ErrorContainer component="div" name={`name`} />
                                        <div className="field">
                                            <InputText
                                                label={t('general.description')}
                                                value={values && values.description}
                                                name="description" />
                                        </div>
                                        <ErrorContainer component="description" name={`name`} />
                                        <div className="field">
                                            <label>{t('general.includeGroupChat')}</label>
                                            <label className="material-radio">
                                                <Field type="radio" name="chat" value="true" />
                                                <span className="dot"></span>
                                                <span className="radio-label">{t('general.yes')}</span>
                                            </label>
                                            <label className="material-radio">
                                                <Field type="radio" name="chat" value="false" />
                                                <span className="dot"></span>
                                                <span className="radio-label">{t('general.no')}</span>
                                            </label>
                                        </div>
                                        <div className="field">
                                            <label>{t('general.selectStudents')}</label>
                                            <div className="control">
                                                <ReactSelect
                                                    ref={selectRef}
                                                    getOptionLabel={option => option.displayName}
                                                    getOptionValue={option => option}
                                                    options={orgUsers}
                                                    value={orgUsers[-1]}
                                                    placeholder={t('setting.groups.selectUser')}
                                                    noOptionsMessage={({ inputValue }) => !inputValue && t("multiselect.noOptionAvailable")}
                                                    onChange={setUserToAdd}

                                                />
                                            </div>
                                        </div>
                                        <a className="button" onClick={() => onUpdateUsers(selectRef)}>{t('setting.groups.updateUsers')}</a>
                                        <button type="submit" className="button">{t('general.save')}</button>
                                        <a className="button" onClick={onAbort}>{t('general.backToGroupsButtonLabel')}</a>
                                    </div>
                                    <div className="sidebar">
                                        <div className="field">
                                            <label>{t('settings.groups.addAdmin')}</label>
                                            <div className="control">
                                                <ReactSelect
                                                    ref={selectRefAdmin}
                                                    getOptionLabel={option => option.displayName}
                                                    getOptionValue={option => option}
                                                    options={orgAdmins}
                                                    value={orgAdmins[-1]}
                                                    placeholder={t('setting.groups.selectAdmin')}
                                                    noOptionsMessage={({ inputValue }) => !inputValue && t("multiselect.noOptionAvailable")}
                                                    onChange={setAdminToAdd}

                                                />
                                            </div>
                                        </div>
                                        <a className="button" onClick={() => onUpdateAdmins(selectRefAdmin)}>{t('setting.groups.updateAdmins')}</a>
                                        <UserList onRemove={handleDeleteAdmin} users={adminsInGroup && adminsInGroup.map(g => {
                                            return { id: g.user.id, displayName: g.user.displayName, avatar: g.user.avatar }
                                        })} right="right" value={values} list={aremove} remove={setaRemove} />

                                    </div>
                                </div>
                                <UserList onRemove={handleDelete} users={usersInGroup && usersInGroup.length > 0 && usersInGroup.map(g => {
                                    return { id: g.user.id, displayName: g.user.displayName, avatar: g.user.avatar }
                                })} value={values} list={uremove} remove={setuRemove}></UserList>

                            </Form>
                        )
                    }}
                </Formik>
            </BoxFormStyled>
        </div>
    )
}
