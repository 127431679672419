export default function bzkFetcher(url, userId){
    return fetch(url, 
        {
            headers: {
                'Content-Type': 'application/json',
                'eid': userId
            },
            method: 'get'
        }
    )
    .then(r => r.json())
    .catch(error => error)
}