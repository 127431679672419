import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import _ from 'lodash';
import { Multiselect } from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import styled from "styled-components";
import * as Yup from 'yup';
import AttachmentList from "../components/AttachmentList";
import FileUpload from '../components/FileUpload';
import { InputText, SwitchBox } from "../components/FormElements";
import { activeJobs, firebaseInstance, firestore, formTemplates, postsDoc, usersDoc } from "../firestore/firestoreService";
import { MyContext } from "../stores";
import { useCatReducer } from '../stores/categories';
import { UserAction } from '../stores/enums/UserAction';
import arraySortByField from '../utils/arraySortByField';
import useSWR from "swr";
import Loader from '../components/Loader'

const validationSchema = Yup.object({
    title: Yup.string('Enter a title').required('This field is required'),
    endDate: Yup.number().required('This field is required'),
    startDate: Yup.number().required('This field is required'),
})

const FormikWrapper = styled.div`
    padding:15px;
    margin-top:15px;
`

const FormWrapper = styled.div`
    margin-bottom:15px;

    label{
        font-size: .9rem;
        font-weight: 500;
        color: #393a4f;
    }
    
    .field {
      margin-bottom: 16px;
    } 

    .richTextContainer{
        border:1px solid #e8e8e8;
        margin:10px 0;
    }

    .toolbar{
        height:30px;
        margin:0;
        padding:1px 0px 17px 5px;
    }

    .richInput{
        padding:10px;
    }

    .toolButton{
        height:24px;
    }

    .crop-button{
        vertical-align:middle;
    }
   

    .react-datepicker-wrapper{
        input{
            border: 1px solid #cccccc;
            border-radius: 4px;
            padding: 5px;
            height: 36px;
        }
    }
`


const EditInternshipObjectForm = ({job}) =>{

    const {slug} = useParams()
    const history = useHistory()
    const alert = useAlert()
    const location = useLocation();
    const {me, globalId, langChoice} = useContext(MyContext)
    const [currentJob, setCurrentJob] = useState(null)
    const [users, setUsers] = useState([])
    const [students, setStudents] = useState([])
    const [teachers, setTeachers] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [forms, setForms] = useState([])
    const [studentforms, setStudentforms] = useState(null)
    const [teacherforms, setTeacherforms] = useState(null)
    const [supervisorforms, setSupervisorforms] = useState(null)
    const [chosenStudents, setChosenStudents] = useState([])
    const [chosenTeachers, setChosenTeachers] = useState([])
    const [chosenSupervisors, setChosenSupervisors] = useState([])
    const [chosenWatchers, setChosenWatchers] = useState([])
    const [chosenCategories, setChosenCategories] = useState([])
    const [chosenJobs, setChosenJobs] = useState([])
    const [categories, dispatch] = useCatReducer([])
    const [attachments, setAttachments] = useState([])
    const [fileUploadStatus, setFileUploadStatus] = useState("idle");
    const [fileDestination, setFileDestination] = useState("")
    const [createdDoc, setCreatedDoc] = useState("")
    const [activeJob, setActiveJob] = useState({})
    const [jobs, setJobs] = useState([])
    const [groupMode, setGroupMode] = useState(false)
    const {t} = useTranslation()
    const addUsers = firebaseInstance().functions().httpsCallable('relatedUsersInternships')

    useEffect(()=> {
        if(slug){
            const activejobSnap = activeJobs.doc(slug).onSnapshot(doc => {
                setCurrentJob(doc.data())
                const usersByRoles = arraySortByField(doc.data().relatedUsers, 'internshipRole');
                if(doc.data().categories){
                    setChosenCategories(doc.data().categories);
                }
                setChosenStudents(usersByRoles['student'] || []);
                setChosenTeachers(usersByRoles['teacher'] || []);
                setChosenSupervisors(usersByRoles['supervisor'] || []);
                setChosenWatchers(usersByRoles['watcher'] || []);
                setChosenWatchers(_.uniq(doc.data().relatedUsers, 'id'))
                setChosenJobs(doc.data().job || null)
            });

            formTemplates.where('type', '==', 'form.internship').get().then( snaps => {
                setForms(snaps.docs.map( doc => Object.assign({id: doc.id, ...doc.data()})))
            })
            return ()=> activejobSnap()
        }

    }, [slug])

    useEffect(()=> {
        dispatch({type: langChoice === 'fr' ? 'SET_FR': 'SET_EN', payload: 'field.'})

        if(job){
            setCurrentJob(job);
            setChosenCategories(job.categories);
            const usersByRoles = arraySortByField(job.relatedUsers, 'internshipRole');
            setChosenStudents(usersByRoles['student']);
            setChosenTeachers(usersByRoles['teacher']);
            setChosenSupervisors(usersByRoles['supervisor']);
        }
        if(me){
            postsDoc.where('types', 'array-contains-any', ['jobs', 'internships']).get().then(docs => {
       
                setJobs(docs.docs.map( doc => {
                    return {id: doc.id, ...doc.data()}
                }))
            })
            const usersDocContainer = usersDoc.where('schoolId', 'array-contains', me.select.id)
            .onSnapshot( doc => {
                const userArray = doc.docs.map( d => {
                    return {id: d.id, ...d.data()}
                })
                setUsers(userArray)
                const sortedUsers = arraySortByField(userArray, 'role');
                //TODO replace role string by enum values
                setStudents(sortedUsers['student']);
                setTeachers(sortedUsers['teacher']);
                setSupervisors(sortedUsers['admin_school']);

            })

            return () => {
                usersDocContainer()

            }
        }
    }, [slug])

    useEffect(() => {
        if(location && location.pathname.includes("edit-group")){
            setGroupMode(true);
        }
    }, [location])
    

    const onSubmit = async (data, {setSubmitting}) => {
        try{
            data.startDate = new Date(data.startDate).getTime()
        }catch(e){}
        try{
            data.endDate = new Date(data.endDate).getTime()
        }catch(e){}
        data.relatedPeople = [...chosenStudents, ...chosenTeachers, ...chosenSupervisors, ...chosenWatchers].map(user => (user.id));
        if(!data.relatedPeople.includes(globalId)){
            data.relatedPeople.push(globalId);
        }

        data.relatedPeoples = [...chosenStudents, ...chosenTeachers, ...chosenSupervisors,...chosenWatchers ].reduce((prev, curr)=> {
            prev[curr.id] = true
            return prev;
        }, {})

        //Adding the internship role
        const tempChosenStudents = chosenStudents.map(
            user => ({id: user.id, internshipRole: 'student', displayName: user.displayName, avatar: user.avatar || null})
        );
        const tempChosenTeachers = chosenTeachers.map(
            user => ({id: user.id, internshipRole: 'teacher', displayName: user.displayName, avatar: user.avatar || null})
        );
        const tempChosenSupervisors = chosenSupervisors.map(
            user => ({id: user.id, internshipRole: 'supervisor', displayName: user.displayName, avatar: user.avatar || null})
        );

        const tempChosenWatchers = chosenWatchers.map(
            user => ({id: user.id, internshipRole: 'watcher', displayName: user.displayName, avatar: user.avatar || null})
        );

        //arr.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
        data.relatedUsers = [...tempChosenStudents, ...tempChosenTeachers, ...tempChosenSupervisors, ...tempChosenWatchers]
            .filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i);
        data.categories = chosenCategories.map(cat => (cat));

    
        if(currentJob) {

            activeJobs.doc(slug).update(data).then( () => {
                alert.success(t('general.updatedactivejob'));
                addUsers({id: slug, relatedUsers: data.relatedUsers}).then(()=> {
                        const createUsersActions = firebaseInstance().functions().httpsCallable("createUsersActions")
                        createUsersActions({
                            type : UserAction.ACTIVE_JOB_ADDED,
                            title: t("logs.type.activeJobAdded"),
                            action : t("logs.type.activeJobAdded"),
                            postTitle : currentJob.title,
                            postType : currentJob.type,
                            displayName : me.displayName,
                            globalId
                            })
                    }
                        
                    )
                if(attachments.length > 0){
                    setCreatedDoc(slug);
                    setFileDestination(`my_internship_attachments/${slug}/${globalId}/`);
                    setFileUploadStatus("uploading");
                }else{
                    history.goBack();
                }
            }).catch(e => {
                alert.error(e.message)
            })
        }else{
            data.author = {
                id: globalId,
                displayName: me.displayName,
                avatar: me.avatar
            }
            data.businessId = me.select.id ? firestore.FieldValue.arrayUnion(me.select.id) : [] ;
            activeJobs.add(data).then( r => {
                alert.success(t("general.addedactivejob"));
                addUsers({id: r.id, relatedUsers: data.relatedUsers}).then(()=> console.log('done'))
                setSubmitting(false)
                if(attachments.length > 0){
                    setCreatedDoc(r.id);
                    setFileDestination(`my_internship_attachments/${slug}/${globalId}/`);
                    setFileUploadStatus("uploading");
                }else{
                    history.goBack();
                }
            }).catch(e => {
                alert.error(e.message)
            })
        }
    }

    const onSelect = (value) =>{
        setChosenCategories(value)
    }

    const onRemove = (value) => {
        setChosenCategories(value)
    }

    const handleUpload = (fileRef, metadata) => {
        fileRef.getDownloadURL().then((url) => {
            const newAttachment = {
                name: metadata.name,
                path: url,
                type: metadata.contentType,
                timestamp: Date.now(),
            };

            activeJobs.doc(createdDoc || slug || job).collection('/attachments/').doc().set(newAttachment).catch((error) => {
                console.error("Error writing document: ", error);
            });
        });
    }

    const handleDelete = (toDelete) => {
        setAttachments((prevState) => {
            const newState = prevState.filter(function (att) {
                return att !== toDelete;
            });
            return newState;
        });
    };

    const handleQueueComplete = () => {
        //Reset all the states and Close the modal
        setFileUploadStatus("idle");
        setAttachments([]);
        setFileDestination("");
        setCreatedDoc("");
        history.goBack();
    };

    return (
        <React.Fragment>
            <Link to={`/me/my-internships`}>
                <button className="button"><i className="mdi mdi-keyboard-backspace"></i>{t('general.goback')}</button>
            </Link>
            <FormikWrapper className="card">
                <Formik initialValues={{active: true,title: "", tiny: "",jobSelect: "",businessName: "",...currentJob}} onSubmit={onSubmit} enableReinitialize={true}>
                    {({values, setFieldValue, handleSubmit, isSubmitting})=> {
                        return (
                            <Form>
                                <FormWrapper>
                                    <div className="field">
                                        <SwitchBox
                                            label={t('jobs.active')}
                                            name="active"
                                        />
                                    </div>
                                    <div className="field">
                                        <InputText
                                            label={ t('jobs.title')}
                                            value={values.title}
                                            name="title" />
                                    </div>
                                    <div className="field">
                                        <InputText
                                            label={ langChoice === 'fr'? 'Petit résumé': 'Tiny description'}
                                            name="tiny" />
                                    </div>
                                    <div className="field">
                                        <SwitchBox
                                            label={langChoice === 'fr' ? 'Sélectionné': 'Selected'}
                                            name="jobSelect"
                                        />
                                    </div>
                                   
                                    {values.jobSelect ? (
                                        <div className="field">
                                            <label>{t('jobs')}</label>
                                            <Multiselect
                                                options={jobs.map( job => {
                                                    return {key: job.id, value: job.title, ...job}
                                                })}
                                                singleSelect={true}
                                                selectedValues={values.job}
                                                no
                                                displayValue={'value'}
                                                onSelect={ v => {
                                                    setFieldValue('job', v)
                                                    setFieldValue('businessName', v[0].title)
                                                }}
                                                placeholder={t('overlay.select')}
                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                            ></Multiselect>
                                        </div>
                                    ): (
                                        <>
                                            <div className="field">
                                                <InputText
                                                    label={ langChoice === 'fr'? 'Nom de compagnie': 'Business Name'}
                                                    value={values.businessName}
                                                    name="businessName" />
                                            </div>
                                            {/* <div className="field">
                                                <label>{t('general.description')}</label>
                                                <WriteRichText
                                                   text={values.description}
                                                    name={`description`} />

                                            </div> */}
                                        </>
                                    )}

                                    <div className="field">
                                        <label>{t('general.category')}</label>
                                        <Multiselect
                                            options={categories}
                                            selectedValues={chosenCategories}
                                            displayValue={'value'}
                                            onSelect={ onSelect }
                                            onRemove={ onRemove }
                                            placeholder={t('overlay.select')}
                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                        ></Multiselect>
                                    </div>
                                    <div className="field">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name={'startDate'}
                                                label={ t('jobs.startDate') }
                                                onChange={(v) => {
                                                    setFieldValue('startDate',  v)
                                                }}
                                                value={values.startDate}
                                                renderInput={params => <TextField {...params} />} />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="field">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                name={'endDate'}
                                                label={ t('jobs.endDate') }
                                                onChange={(v) => {
                                                    setFieldValue('endDate',  v)
                                                }}
                                                minDate={values.startDate}
                                                value={values.endDate}
                                                renderInput={params => <TextField {...params} />} />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="field">
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={6}>
                                                <label>{t('general.selectStudents')}</label>
                                                <div className="control">
                                                    <Multiselect
                                                        options={users}
                                                        selectedValues={chosenStudents}
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        singleSelect={true}
                                                        onSelect={(v)=> setChosenStudents(v)}
                                                        onRemove={(v)=> setChosenStudents(v)}
                                                        displayValue="displayName"
                                                        placeholder={t('overlay.select')}
                                                        disable={groupMode}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} md={6}>
                                                <SwitchBox
                                                    label={t('general.studentdocsign')}
                                                  value={values.studentSignDoc}
                                                  name={'studentSignDoc'}
                                                ></SwitchBox>
                                            </Grid>
                                            {values.studentSignDoc && (
                                                <Grid item xs={12} md={12}>
                                                    <div className="control">
                                                        <Multiselect
                                                            options={forms}
                                                            selectedValues={values.studentforms}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            singleSelect={true}
                                                            onSelect={(v)=> {
                                                                setFieldValue('studentforms', v)
                                                            }}
                                                            displayValue="name"
                                                            placeholder={t('overlay.select')}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>

                                    </div>
                                    <div className="field">
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={6}>
                                                <label>{t('general.selectTeachers')}</label>
                                                <div className="control">
                                                    <Multiselect
                                                        options={users}
                                                        selectedValues={chosenTeachers}
                                                        singleSelect={true}
                                                        onSelect={(v)=> setChosenTeachers(v)}
                                                        onRemove={(v)=> setChosenTeachers(v)}
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        displayValue="displayName"
                                                        placeholder={t('overlay.select')}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} md={6}>
                                                <SwitchBox
                                                    label={t('general.teacherdocsign')}
                                                    value={values.teacherSignDoc}
                                                    name={'teacherSignDoc'}
                                                ></SwitchBox>
                                            </Grid>
                                            {values.teacherSignDoc && (
                                                <Grid item xs={12} md={12}>
                                                    <div className="control">
                                                        <Multiselect
                                                            options={forms}
                                                            selectedValues={values.teacherforms}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            singleSelect={true}
                                                            onSelect={(v)=> {
                                                                setFieldValue('teacherforms', v)
                                                            }}
                                                            displayValue="name"
                                                            placeholder={t('overlay.select')}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}

                                        </Grid>

                                    </div>
                                    <div className="field">
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={6}>
                                                <label>{t('general.selectSupervisors')}</label>
                                                <div className="control">
                                                    <Multiselect
                                                        options={users}
                                                        singleSelect={true}
                                                        selectedValues={chosenSupervisors}
                                                        onSelect={(v)=> setChosenSupervisors(v)}
                                                        onRemove={(v)=> setChosenSupervisors(v)}
                                                        displayValue="displayName"
                                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                        placeholder={t('overlay.select')}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={4} md={6}>
                                                <SwitchBox
                                                    label={t('general.supervisordocsign')}
                                                    value={values.supervisorSignDoc}
                                                    name={'supervisorSignDoc'}
                                                ></SwitchBox>
                                            </Grid>
                                            {values.supervisorSignDoc && (
                                                <Grid item xs={12} md={12}>
                                                    <div className="control">
                                                        <Multiselect
                                                            options={forms}
                                                            selectedValues={values.supervisorforms}
                                                            emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                            singleSelect={true}
                                                            onSelect={(v)=> {

                                                                setFieldValue('supervisorforms', v)
                                                            }}
                                                            onRemove={(v)=> setTeacherforms(v)}
                                                            displayValue="name"
                                                            placeholder={t('overlay.select')}
                                                        />
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>

                                    </div>
                                    <div className="field">
                                        <label>{t('general.selectwatcher')}</label>
                                        <div className="control">
                                            <Multiselect
                                                options={users}
                                                selectedValues={chosenWatchers}
                                                onSelect={(v)=> setChosenWatchers(v)}
                                                onRemove={(v)=> setChosenWatchers(v)}
                                                emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                                displayValue="displayName"
                                                placeholder={t('overlay.select')}
                                            />
                                        </div>
                                    </div>
                                    <div className="compose-option is-centered">
                                    <FileUpload
                                        dest={fileDestination}
                                        onUpload={handleUpload}
                                        queueStatus={fileUploadStatus}
                                        queue={attachments}
                                        setQueue={setAttachments}
                                        onQueueComplete={handleQueueComplete}
                                    >
                                        <div className="button is-fullwidth"><i className="mdi mdi-plus"></i>{t('general.uploadFile')}</div>
                                    </FileUpload>
                                    <div>
                                        <AttachmentList
                                        attachments={attachments}
                                        metas={["name"]}
                                        onDelete={handleDelete}
                                        isHorizontal
                                        />
                                    </div>
                                </div>
                                </FormWrapper>
                                <Button color="primary" variant="contained" fullWidth type="submit" disabled={isSubmitting}>
                                    {t('general.save')}
                                </Button>
                            </Form>
                        )
                    }}
                </Formik>
            </FormikWrapper>
        </React.Fragment>
    )
};

export default EditInternshipObjectForm
