import React, { useState, useEffect, useContext } from 'react'
import { MyContext } from "../stores";
import { useTranslation } from "react-i18next";
import EditDocumentForm from "../forms/EditDocumentForm";
import styled, { ThemeProvider } from 'styled-components';
import { useAlert } from 'react-alert';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, InputBase } from '@mui/material';
import { Box } from '@mui/system';
import accents from 'remove-accents';
import formatFileSize from '../utils/fileSizeFormatter';
import useSWR from 'swr';
import axios from 'axios';
import Swal from "sweetalert2";


const DocumentsContainer = styled.div`
    
`

const DocumentWrapper = styled.div`
  margin: 1rem 0px;
  align-items: center;
  transition: all .3s;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e8e8e8;
  background: white;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }
  .template {
    width: 100%;
    display: grid;
    grid-template-columns: auto 110px;
    gap:15px;
    align-items: center;
  }
  .flex-block-meta {
    margin-left: 10px;
  }
  .div-button {
    background-color: var(--bazookka-green);
    cursor: pointer;
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
  }
  .titletext {
    display: flex;
    padding-left: 1rem;
    align-items: center;
  }
  .go-button {
    cursor: pointer;
    background-color: var(--bazookka-blue);
    width: 42px;
    padding: 0px 5px !important;
    margin: 0px 5px !important;
    float: left;
    height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 14px 26px -12px rgb(85 150 230 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(85 150 230 / 20%) !important;

  }
  :hover .go-button {
    opacity: 1;
    transform: rotate(360deg);
    transition: all .3s;
  }`

const DocumentHeading = styled.div`
  a{
    display:grid;
    grid-template-columns:50px auto;
    cursor:pointer;
    color:#344258;
  }
`

const DocumentGroup = styled.div`
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:10px;
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 468px) {
    grid-template-columns: repeat(1, 1fr);
    }
`

const DocumentTitle = styled.div`
    padding-left:5px;
    margin:auto 0;
`
const theme = createTheme();

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    color: "black",
    backgroundColor: "#d0fefe",
    '&:hover': {
        backgroundColor: "white",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginTop: "30px",
    marginBottom: "30px",
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: '50vh',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const DocumentSearchBar = ({ setSearchQuery }) => {
    const { t } = useTranslation()
    const onChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div style={{ margin: "auto", width: "50%" }}>
            <Box sx={{ display: "flex" }}>
                <Box>
                    <ThemeProvider theme={theme}>
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                onChange={onChange}
                                placeholder={t('general.docsearch')}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    </ThemeProvider>
                </Box>
            </Box>

        </div>


    )
}

const DocumentCard = ({ mutate, document, groups, options, creating = false, authored = false, handleCreate = null }) => {

    const [editing, setEditing] = useState(false);
    const { t } = useTranslation();

    const creatingClass = creating ? 'create' : '';

    const handleCreateAndClose = () => {
        
        typeof handleCreate  === 'function' && handleCreate();
        setEditing(false);
    }

    return (
        <DocumentWrapper className={creatingClass}>
            <div className="template">
                <DocumentHeading onClick={(e) => {
                    if (creating) {
                        e.preventDefault()
                        setEditing(!editing);
                    }
                }}
                >

                    <a href={document && document.file && document.file.url} rel="noreferrer" target="_blank">
                        <div className="div-button">
                            {creating ? <i className="mdi mdi-plus"></i> : <i className="mdi mdi-file-document"></i>}
                        </div>
                        {creating ?
                            <DocumentTitle>{t('general.adddocument')}</DocumentTitle>
                            :
                            <DocumentTitle>{document.file.name}</DocumentTitle>
                        }
                    </a>
                    {!creating && <h1 style={{ paddingLeft: "5px", margin: "auto 0" }}>{document && document.file.fileSize ? formatFileSize(document.file.fileSize ) : "0kb"}</h1>}
                </DocumentHeading>
                {!creating && authored &&
                    <div>
                        {editing ?
                            <div className="go-button float-left" onClick={(e) => {
                                setEditing(false)
                            }}>
                                <i className="mdi mdi-close"></i>
                            </div> :
                            <div className="go-button float-left" onClick={(e) => {
                                e.preventDefault()
                                setEditing(!editing);
                            }}>
                                <i className="mdi mdi-pencil"></i>
                            </div>
                        }

                        <div className="go-button float-left" onClick={() => {
                                    Swal.fire({
                                        title: t("general.document.delete"),
                                        showDenyButton: true,

                                        confirmButtonText: t("general.delete"),
                                        denyButtonText: t("general.cancel"),
                                    }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                            document.file && axios.delete(`https://api.bazookka.com/posts/documents/${document.file.post_id}`).then(res => mutate())
                                            mutate()
                                        } else if (result.isDenied) {
                                            Swal.fire(t("general.document.deleteDecline"))
                                        }
                                    })

                          
                        }}>
                            <i className="mdi mdi-delete"></i>
                        </div>
                    </div>
                }
            </div>
            {editing &&
                <EditDocumentForm   mutate={mutate} document={document} groups={groups} options={options} creating={creating} onCreate={handleCreateAndClose} />
            }
        </DocumentWrapper>
    )
}

const Documents = props => {
    const { organizationId, globalIdSql } = useContext(MyContext);
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState("")
    const [totalSize, setTotalSize] = useState([]);

    const { data: options, error: optionError } = useSWR(`https://api.bazookka.com/organizations/${organizationId}/users`,
        url => fetch(url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
            .then(r => r.json())

    )

    const { data: groups, error: groupsError } = useSWR(`https://api.bazookka.com/organizations/${organizationId}/groups`,
        url => fetch(url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
            .then(r => r.json())

    )

    const searchCriteria = (ad, searchQuery) => {
        if(!searchQuery) return  ad
        return accents.remove(ad.file.name.toLocaleLowerCase()).includes(accents.remove(searchQuery.toLocaleLowerCase()));
    }


    const { data, error, mutate: mutateMyFiles } = useSWR(`https://api.bazookka.com/posts/document/org/${organizationId}/user/${globalIdSql}`,
        url => fetch(url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
            .then(r => r.json())

    )

    return (

            <div style={{ "paddingTop": '30px' }} className="view-wrapper">
                <div className="navbar-ghost is-hidden-mobile"></div>
                <div className="container is-custom">
                    <h1>{t('overlay.files')}</h1>
                    <DocumentCard key="createForm" mutate={mutateMyFiles} groups={groups} document={null} options={options} creating={true} />
                    <DocumentsContainer>
                        {data && data.my_files.length > 0 &&
                            <React.Fragment>
                                <h1>{t('settings.myDocuments')} : {data.my_files.length} documents</h1>
                                <DocumentSearchBar setSearchQuery={setSearchQuery} />
                                <DocumentGroup>
                                    {data.my_files && data.my_files.length > 0 && data.my_files.filter(ad => searchCriteria(ad, searchQuery)).map((document, key) => {
                                        return <DocumentCard mutate={mutateMyFiles} key={key} document={document} groups={groups} options={options} authored={true} />

                                    })}
                                </DocumentGroup>
                                {totalSize.length > 0 && <h2>{t('documents.totalSize')} : {formatFileSize(totalSize.reduce((a, b) => +a + +b.size, 0))}</h2>}
                                <br />
                            </React.Fragment>
                        }

                        {data && data.shared && data.shared.length > 0 && data.shared.filter(d => searchCriteria(d, searchQuery)).length > 0 &&
                            <React.Fragment>
                                <h1>{t('documents.userDocuments')} : {data.shared.filter(ad => ad.file.user_id !== null).length} documents </h1>
                                {data.shared.filter(ad => ad.file.user_id !== null).length > 0 ?
                                    <DocumentGroup>
                                        {data && data.shared && data.shared.length > 0 && data.shared.filter(ad => ad.file.user_id !== null).filter(ad => searchCriteria(ad, searchQuery)).map((document, key) => {
                                            return <DocumentCard mutate={mutateMyFiles} key={key} document={document} groups={groups} options={options} />
                                        })}
                                    </DocumentGroup>
                                    : <h1>{t('documents.notFound')}</h1>
                                }
                            </React.Fragment>
                        }

                        {data && data.shared && data.shared.length > 0 && data.shared.filter(d => searchCriteria(d, searchQuery)).length > 0 &&
                            <React.Fragment>
                                <h1>{t('documents.organisationDocuments')} : {data.shared.filter(ad => ad.file.group_id !== null).length} documents </h1>
                                {data.shared.filter(ad => ad.file.group_id !== null).length > 0 ?
                                    <DocumentGroup>
                                        {data && data.shared && data.shared.length > 0 && data.shared.filter(ad => ad.file.group_id !== null).filter(ad => searchCriteria(ad, searchQuery)).map((document, key) => {
                                            return <DocumentCard mutate={mutateMyFiles} key={key} document={document} groups={groups} options={options} />
                                        })}
                                    </DocumentGroup>
                                    : <h1>{t('documents.notFound')}</h1>
                                }
                            </React.Fragment>
                        }

                        {data && data.shared && data.shared.length > 0 && data.shared.filter(d => searchCriteria(d, searchQuery)).length > 0 &&
                            <React.Fragment>
                                <h1>{t('documents.groupDocuments')} : {data.shared.filter(ad => ad.file.group_id !== null).length} documents </h1>
                                {data.shared.filter(ad => ad.file.group_id !== null).length > 0 ?
                                    <DocumentGroup>
                                        {data && data.shared && data.shared.length > 0 && data.shared.filter(ad => ad.file.group_id !== null).filter(ad => searchCriteria(ad, searchQuery)).map((document, key) => {
                                            return <DocumentCard mutate={mutateMyFiles} key={key} document={document} groups={groups} options={options}  />
                                        })}
                                    </DocumentGroup>
                                    : <h1>{t('documents.notFound')}</h1>
                                }
                            </React.Fragment>
                        }
                        <h1>{t("documents.count")} : {data ? data.shared.length + data.my_files.length : 0} </h1>
                    </DocumentsContainer>
                </div>
            </div>
    )
}

export default Documents;

