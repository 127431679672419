import React, { useState, useContext, useEffect, useRef } from "react"
import { MyContext } from "../stores";
import { Route, Switch, useParams, Link, useRouteMatch } from "react-router-dom"
import { L10n } from '@syncfusion/ej2-base'
import { Search, GridComponent, ColumnDirective, ColumnsDirective, Resize, Sort, ContextMenu, Filter, Page, Inject, Toolbar } from "@syncfusion/ej2-react-grids";
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import useSWR from 'swr'
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import styled from 'styled-components'
import Loader from "../components/Loader";
import { useAlert } from "react-alert";
import SearchSelect from 'react-select'
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import "./userDetailDialog.css"

const OverDiv = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fff;
  padding: 1rem;
`

const ButtonStylle = styled.div`
   padding: .5rem 1rem;
   color: white;
   background-color: var(--bazookka-blue);
   margin: .5rem 0;
  border-radius: 4px;
  
`


L10n.load({
    'fr-FR': {
        'grid': {
            'EmptyDataSourceError': 'Erreur de chargement des donnée',
            'EmptyRecord': `Aucun élément á charger`,
            'Item': 'élément',
            'Items': 'éléments',
            'name': 'nom',
            'Search': "Chercher"
        },
        'pager': {
            'currentPageInfo': 'Page {0} de {1}',
            'totalItemsInfo': '({0} éléments)',
            'totalItemInfo': '({0} élément)'
        }
    },
    'en-US': {
        'grid': {
            'EmptyDataSourceError': 'Error loading data',
            'EmptyRecord': `There's no element to load`,
            'Item': 'element',
            'Items': 'elements',
            'name': 'nom',
            'Search': "Search"
        },
        'pager': {
            'currentPageInfo': 'Page {0} of {1}',
            'totalItemsInfo': '({0} elements)',
            'totalItemInfo': '({0} element)'
        }
    }
});


const StatsComponent = () => {
    const {globalId} = useContext(MyContext)
    const {data, error} = useSWR('https://api.bazookka.com/stats/mentorats',
        url => fetch(url, {method: 'get', headers: {eid: globalId, 'Content-Type': 'application/json'}})
            .then( r => r.json()))

    if (!data) return <Loader></Loader>
    return (
        <div className="flex flex-wrap mb-2">
            <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2">
                <div className="bg-blue-600 border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4">
                        </div>
                        <div className="flex-1 text-right">
                            <h5 className="text-white">Stages disponible</h5>
                            <h3 className="text-white text-3xl">{data.training} <span className="text-blue-400"><i
                                className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pr-2 xl:pr-3 xl:pl-1">
                <div className="bg-orange-600 border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4"></div>
                        <div className="flex-1 text-right pr-1">
                            <h5 className="text-white">Place disponible</h5>
                            <h3 className="text-white text-3xl">{data.spots} <span className="text-orange-400"><i
                                className="fas fa-caret-up"></i></span></h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 xl:w-1/3 pt-3 px-3 md:pl-2 xl:pl-3 xl:pr-2">
                <div className="bg-purple-600 border rounded shadow p-2">
                    <div className="flex flex-row items-center">
                        <div className="flex-shrink pl-1 pr-4">
                        </div>
                        <div className="flex-1 text-right">
                            <h5 className="text-white">Nombre de stagiaires</h5>
                            <h3 className="text-white text-3xl">{data.kids}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const CallableLink = (props) => {
    const { path } = useRouteMatch()
    const onSubmit = () => {
        alert(JSON.stringify(props))
    }
    return (
        <Link to={`${path}/edit/${props.id}`} className="bg-blue-700 p-2 rounded-l font-white" onClick={onSubmit}>
            color
        </Link>
    )
}

const BiggerTitle = props => {
    return (<div className="py-1 px-2">{props.title}</div>)
}

const AvatarHeader = (props) => {
    return <Avatar name={props.display_name} size={40} round={true} />
}


// "general.mentorGrid.mentoringColumn": "Mentorat",
const companyGrid = [
    {
        headerText: 'mentorView.mentorGrid.titleColumn',
        field: 'title',
        width: '400',
        template: BiggerTitle
    },
    {
        headerText: 'mentorView.mentorGrid.typeColumn',
        template: function Display(props) {
            const { t } = useTranslation()

            return (
                <div className="flex">
                    <span className="px-1 py-0.5 bg-amber-500 rounded text-white" >{t(`${props.meta.cat && `field.${props.meta?.cat}` || ''}`)}</span>
                </div>
            )
        },
        width: '200',

    },
    {
        headerText: 'mentorView.mentorGrid.weekColumn',
        field: 'meta.available',
        width: '80',
        filter: { type: '' }
    },
    {
        headerText: 'mentorView.mentorGrid.availableColum',
        template: function Display(props) {
            return <div>{props.meta.count - props.participants}/{props.meta.count}</div>
        },
        field: 'meta.count',
        width: '80',
    },
    {
        headerText: 'mentorView.mentorGrid.usersColumn',
        field: 'user_count',
        width: '80',
        textAlign: 'center'
    },
    {
        headerText: 'mentorView.mentorGrid.mentoringColumn',
        field: '',
        width: '200',
        template: function Buttons(props) {
            const { path } = useRouteMatch()
            return <Link to={`${path}/views/${props.id}`} ><i className="mdi mdi-eye-check" style={{ fontSize: '1rem' }}></i></Link>
        }

    }
]

const fetcher = (url) => fetch(url).then((res) => res.json());

function MentorView(){
    const {t} = useTranslation()
    const {path} = useRouteMatch()
    const {me, globalId, langChoice,setI10nLoadContent} = useContext(MyContext)
    const myref = useRef(null)
    let {data, error} = useSWR(`https://api.bazookka.com/users/mentors`,

        url => fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'eid': globalId
            },
            method: 'post',
            body: JSON.stringify({ id: globalId })
        }).then(r => r.json()))


    useEffect(() => {
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun mentor á charger`,
                        'Item': 'mentor',
                        'Items': 'mentors',
                        'name': 'nom',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} mentors)',
                        'totalItemInfo': '({0} mentor)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no mentors to load`,
                        'Item': 'mentor',
                        'Items': 'mentors',
                        'name': 'nom',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} mentors)',
                        'totalItemInfo': '({0} mentor)'
                    }
                }
            }
        )

    }, [])

    if (!data) {
        return <Loader />
    }

    return (
        <div className="mb-2 mt-2 w-full">
            <StatsComponent></StatsComponent>
                <GridComponent
                    locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                    width={`auto`}
                    id="gridcomp"
                    dataSource={data}
                    allowPaging
                    allowSorting
                    pageSettings={{ pageSize: 25 }}
                    contextMenuItems={['AutoFit']}
                    toolbar={langChoice === 'en' ? ["Filter", "Resize"] : ["Filtrer", "Redimensionner"]}
                    allowResizing={true}
                    allowFiltering={true}
                >
                    <ColumnsDirective
                    >
                        {companyGrid.map((item, index) => {
                            return <ColumnDirective
                                key={index}
                                {...item}
                                headerText={t(item.headerText)}
                                maxWidth={800}
                            />
                        })}
                    </ColumnsDirective>
                    <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Search, Toolbar]} />
                </GridComponent>
        </div>


    )
}


const ViewUsers = () => {
    const {t} = useTranslation()
    const {path} = useRouteMatch()
    const alert = useAlert()
    const {id} = useParams()
    const [selected_user, setSelectedUser] = useState()
    const {me, globalId, freeze, setFreeze,setI10nLoadContent, langChoice} = useContext(MyContext)
    const [hideDialog, setHideDialog] = useState(false)
    const [currentUserId, setCurrentUserId] = useState()
    const {data, error, mutate} = useSWR(`https://api.bazookka.com/users/mentors/${id}`,
        (url) => fetch(url, {method: 'post', headers: {id}}).then(r=> r.json()))

    const [searches, setSearches] = useState([])

    console.log("data ",data)

    useEffect(() => {
        fetch(`https://api.bazookka.com/imports/filters?q=a&id=${id}`)
            .then( r => r.json())
            .then(json => setSearches(json.map(({id, display_name})=> {
                return {
                    value: id,
                    label: display_name
                }
            })))
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun stagiaire á charger`,
                        'Item': 'stagiaire',
                        'Items': 'stagiaires',
                        'name': 'nom',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} stagiaires)',
                        'totalItemInfo': '({0} stagiaire)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no intern to load`,
                        'Item': 'intern',
                        'Items': 'interns',
                        'name': 'name',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} interns)',
                        'totalItemInfo': '({0} intern)'
                    }
                }
            }
        )

    }, [])
    useEffect(() => {
        if (data) {
            setFreeze((data.post.meta.count - data.post.selected) > 0 ? true : false)
        }
    }, [data])



    const onOverlayClick = () => {
        setHideDialog(false)
    };
    const dialogClose = () => {
        setHideDialog(false)
    };

    const handleClick = (id) => {
        setCurrentUserId(id)
        setHideDialog(true)
    }




    const users_header = [
        {
            width: '50',
            headerText: 'mentorView.mentoringOverview.avatarColumn',
            template: function AvatarHeader(props) {
                return (
                    <Avatar name={props.user.display_name} size={40} round={true} />
                )
            }
        },
        {
            width: '150',
            headerText: 'MRID',
            field: 'user.meta.MRID'
        },
        {
            headerText: 'mentorView.mentoringOverview.nameColumn',
            field: 'user.display_name',
            width: '200',
            clipMode: 'EllipsisWithTooltip',
            filter: {
                type: 'CheckBox'
            }
        },
        {
            headerText: "mentorView.mentoringOverview.removeColumn",
            width: '100',
            template: function Remove(props) {
                const { path } = useRouteMatch()
                const { t } = useTranslation()

                return (
                    <>
                        {props.in_other > 0 && (
                            <a className="flex justify-center p-2 bg-yellow-600 text-white rounded hover:text-white" href={`me/mentors_view/views/${props.link}`} target="_blank">{t('general.remove')}</a>
                        )}
                    </>
                )
            }
        },
        {
            headerText: 'mentorView.mentoringOverview.distanceColumn',
            field: 'distance',
            width: '80',
            textAlign: 'center'
        },
        {
            headerText: 'mentorView.mentoringOverview.categoryColumn',
            field: 'category',
            width: '80',
            textAlign: 'center'
        },
        {
            headerText: 'general.score',
            field: 'score',
            width: '100',
            textAlign: 'center',
            template: function Display(props){
                return (
                    <div className="">{parseFloat(props.score).toFixed(2)}</div>
                )
            }
        },
        {
            headerText: 'mentorView.mentoringOverview.riasecColumn',
            field: 'riasec',
            width: '100',
            textAlign: 'center',
            template: function Display(props){
                return (
                    <div className="">{parseFloat(props.riasec).toFixed(2)}</div>
                )
            }
        },
        {
            headerText: 'mentorView.mentoringOverview.speedColumn',
            field: 'speed',
            width: '200',
            textAlign: 'center',
            template: function Display(props) {
                return (
                    <div className="">{parseFloat(props.speed).toFixed(2)}</div>
                )
            }
        },
        {
            headerText: 'mentorView.mentoringOverview.selectedColumn',
            width: '100',
            textAlign: 'center',
            field: 'selected',
            template: function Action(props) {
                const { id } = useParams()
                const alert = useAlert()
                const {freeze} = useContext(MyContext)
                const updateStatus =  () => {
                    console.log("in call ", freeze)
                    if(freeze || props.selected > 0) {
                        fetch(`https://api.bazookka.com/users/mentor/toggle`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                user_id: props.user.id,
                                rate_id: props.id,
                                post_id: id,
                                status: props.selected
                            })
                        }).then(r => r.json())
                            .then(() => {
                                mutate()
                            })
                            .catch(e => console.log(e))
                    } else {
                        alert.error("Liste de disponibilité complete")
                    }
                }
                return (
                    <>
                        {props.in_other === 0 && (
                            <div
                                className={`flex justify-center ${props.selected ? 'bg-green-600' : 'bg-red-600'} text-white rounded-3xl`}
                                onClick={updateStatus}
                                style={{ cursor: 'pointer', width: 50 }}>
                                {props.selected > 0 ? <span>oui</span> : <span>non</span>}
                            </div>)}
                    </>


                )
            }
        },
        {
            headerText: 'Details',
            field: '',
            width: '200',
            template: function Buttons(props) {
                const { path } = useRouteMatch()
                const id = props.user.id
                return <button onClick={() => handleClick(id)}  ><i className="mdi mdi-eye-check" style={{ fontSize: '1rem' }}></i> </button>
            }

        },
    ]

    const createConvention = async (post) => {
        let resp = await fetch(`https://api.bazookka.com/internshipObjects/mentor/group`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eid: globalId,
                post_id: id,
                post: post
            })
        })

        let resp_body = await resp.json()
        alert.success('Convention de stage créé')

    }

    if (!data) return <Loader />

    function headerTemplate() {
        const userD = data.data.find(elem => elem.user.id === currentUserId)
        return (userD ? <div className="flex flex-row justify-items-center align-item">
            <Avatar className="mr-2" name={userD.user.display_name} size={40} round={true} />
            <div clas title={userD.user.display_name} className="e-icon-settings dlg-template mb-3">{userD.user.display_name}</div>
        </div> : "");
    }

    const separatorWithTitle = (title) => {
        return (<div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-4 border-gray-400"></div>
            <span class="flex-shrink mx-4 text-gray-400">{title}</span>
            <div class="flex-grow border-t border-4 border-gray-400"></div>
        </div>)
    }

    function bodyTemplate() {
        const user = data.data.find(elem => elem.user.id === currentUserId)
        if (user) {
            return (
                <div className="m-auto" style={{ fontWeight: "normal" }}>
                    {separatorWithTitle(t("mentorView.userView.details.contactInformations"))}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.zohoId")} : </span> {user.user.meta.zohoId}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.address")} : </span> {user.user.meta.address}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.kidEmail")} : </span> {user.user.meta.email}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.kidPhone")} : </span> {user.user.meta.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.schoolLevel")} : </span> {user.user.meta.schoolLevel}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentPhone")} : </span> {user.user.meta.parent.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentEmail")} </span> {user.user.meta.parent.email}</div>
                    {separatorWithTitle("Trait")}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait1")} : </span> {user.user.meta.traits.trait1}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait2")} : </span> {user.user.meta.traits.trait2}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait3")} </span> {user.user.meta.traits.trait3}</div>

                    {separatorWithTitle(t("mentorView.userView.details.interests"))}
                    {
                        <div className="grid grid-cols-1 divide-y-2">
                            {user.user.meta.interests.map((i, index) => {
                                let label1 = `mentorView.userView.details.sector${index + 1}`
                                let label2 = `mentorView.userView.details.sectorPrecision${index + 1}`
                                return <div key={index} className="mb-3" >
                                    <div>
                                        <div className="mb-3" style={{ fontWeight: "bold" }}>{t(label1)}: <span style={{ fontWeight: "normal" }} >{i.field}</span></div>
                                        <div style={{ fontWeight: "bold" }}>{t(label2)}:  <span style={{ fontWeight: "normal" }}>{i.reason}</span></div>
                                    </div>
                                </div>
                            })}
                        </div>
                    }




                </div>
            )
        }
        return ""
    }

    const onFilter = async (v) => {
        console.log(v)
        fetch(`https://api.bazookka.com/imports/filters?q=${v.text}&id=${id}`)
            .then(r => r.json())
            .then(json => setSearches(json))
    }

    const itemTemplate = (user) => {
        return (
            <div style={{display: 'flex', paddingBottom: 3}}>

                <Avatar name={user.display_name} size={40} round></Avatar>
                <div className="ename"> {user.display_name} </div><div className="job"> {user.meta.MRID} </div></div>
        );
    }

    const onInputChange = (query) => {
        console.log()
        fetch(`https://api.bazookka.com/imports/filters?q=${query}&id=${id}`)
            .then(r => r.json())
            .then(json => setSearches(json.map(({id, display_name}) => {
                return {
                    value: id,
                    label: display_name
                }
            })))

    }

    const addUser = () => {
        fetch('https://api.bazookka.com/imports/add', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({user_id: selected_user.value, post_id: id})
        })
            .then(r=> r.json())
            .then( ()=> {
                alert.success('Utilisateur ajouté à la liste')
            })
    }



    return (
        <OverDiv>
            <div>
                <div>
                    {t('mentorView.mentoringOverview.internshipName')} {data.post.title}
                </div>
                <div>
                    {t('mentorView.mentoringOverview.nbInterns')} {data.post.meta.count}
                </div>
                <div>
                    {t('mentorView.mentoringOverview.nbAssignedInterns')} {data.post.selected}
                </div>
                <div>
                    {t('mentorView.mentoringOverview.week')} {data.post.meta.available}
                </div>
                <div className="py-0.5 px-2 bg-pink-700 text-white cursor-pointer flex-grow w-fit rounded" onClick={() => createConvention(data.post)}>
                    {t('mentorView.mentoringOverview.createConvention')}
                </div>

            </div>

            <DialogComponent allowDragging showCloseIcon animationSettings={{ effect: 'Zoom', duration: 400, delay: 0 }} cssClass="e-dlg-content" width='500px' isModal={true} header={headerTemplate} visible={hideDialog} close={dialogClose} overlayClick={onOverlayClick}>
                {bodyTemplate()}

            </DialogComponent>
            <div style={{padding: "1rem 0" ,width: 600, minWidth: '300px', fontSize: 14}} className="flex-row">
                {/*<DropDownListComponent*/}
                {/*    allowFiltering={true}*/}
                {/*    dataSource={searches}*/}
                {/*    popupWidth={400}*/}
                {/*    width={400}*/}
                {/*    itemTemplate={itemTemplate}*/}
                {/*    filtering={onFilter}*/}
                {/*    fields={{text: 'display_name', value: 'id'}} ref={ref}></DropDownListComponent>*/}
                Ajouter un stagiaire non compatible
                <SearchSelect
                    options={searches}
                    onChange={value => setSelectedUser(value)}


                    onInputChange={onInputChange}
                    placeholder={'Recherche par nom ou MRID'}
                />
                <ButtonStylle className="flex justify-center p-2 w-fit" onClick={addUser}>Ajouter</ButtonStylle>
            </div>

            <GridComponent

                locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                // width={`auto`}
                width={'auto'}
                id="gridcomp"
                dataSource={data.data}
                allowPaging
                allowSorting
                pageSettings={{ pageSize: 25 }}
                contextMenuItems={['AutoFit']}
                toolbar={["Search"]}
                rowDrag={true}
                allowResizing={true}
                allowFiltering={true}
                enableStickyHeader={true}
            >
                <ColumnsDirective
                >
                    {users_header.map((item, index) => {
                        return <ColumnDirective
                            key={index}
                            {...item}
                            headerText={t(item.headerText)}
                        />
                    })}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Search, Toolbar, Filter]} />
            </GridComponent>


        </OverDiv>

    )
}

function ViewNoMatch(){

    const {t} = useTranslation()
    const {path} = useRouteMatch()
    const alert = useAlert()
    const {id} = useParams()
    const {me, globalId, freeze, setFreeze,setI10nLoadContent, langChoice} = useContext(MyContext)
    const [hideDialog, setHideDialog] = useState(false)
    const [currentUserId, setCurrentUserId] = useState()
    const {data, error, mutate} = useSWR(`https://api.bazookka.com/imports/no-match`,
        (url) => fetch(url, { method: 'post', body: JSON.stringify({id: id}), headers: {id, 'Content-Type': 'application/json',}}).then(r=> r.json()))

    console.log("data ",data)

    useEffect(() => {
        setI10nLoadContent(
            {
                'fr-FR': {
                    'grid': {
                        'EmptyDataSourceError': 'Erreur de chargement des données',
                        'EmptyRecord': `Aucun stagiaire á charger`,
                        'Item': 'stagiaire',
                        'Items': 'stagiaires',
                        'name': 'nom',
                        'Search': "Chercher"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} de {1}',
                        'totalItemsInfo': '({0} stagiaires)',
                        'totalItemInfo': '({0} stagiaire)'
                    }
                },
                'en-US': {
                    'grid': {
                        'EmptyDataSourceError': 'Error loading data',
                        'EmptyRecord': `There's no intern to load`,
                        'Item': 'intern',
                        'Items': 'interns',
                        'name': 'name',
                        'Search': "Search"
                    },
                    'pager': {
                        'currentPageInfo': 'Page {0} of {1}',
                        'totalItemsInfo': '({0} interns)',
                        'totalItemInfo': '({0} intern)'
                    }
                }
            }
        )

    }, [])


    const onOverlayClick = () => {
        setHideDialog(false)
    };
    const dialogClose = () => {
        setHideDialog(false)
    };

    const handleClick = (id) => {
        setCurrentUserId(id)
        setHideDialog(true)
    }




    const users_header = [
        {
            width: '50',
            headerText: 'mentorView.mentoringOverview.avatarColumn',
            template: function AvatarHeader(props) {
                return (
                    <Avatar name={props.display_name} size={40} round={true} />
                )
            }
        },
        {
            width: '150',
            headerText: 'MRID',
            field: 'meta.MRID'
        },
        {
            headerText: 'mentorView.mentoringOverview.nameColumn',
            field: 'display_name',
            width: '200',
            clipMode: 'EllipsisWithTooltip',
            filter: {
                type: 'CheckBox'
            }
        },
        // {
        //     headerText: "mentorView.mentoringOverview.removeColumn",
        //     width: '100',
        //     template: function Remove(props) {
        //         const { path } = useRouteMatch()
        //         const { t } = useTranslation()
        //
        //         return (
        //             <>
        //                 {props.in_other > 0 && (
        //                     <a className="flex justify-center p-2 bg-yellow-600 text-white rounded hover:text-white" href={`me/mentors_view/views/${props.link}`} target="_blank">{t('general.remove')}</a>
        //                 )}
        //             </>
        //         )
        //     }
        // },
        {
            headerText: 'mentorView.mentoringOverview.distanceColumn',
            field: 'distance',
            width: '80',
            textAlign: 'center'
        },
        {
            headerText: 'mentorView.mentoringOverview.categoryColumn',
            field: 'category',
            width: '80',
            textAlign: 'center'
        },
        // {
        //     headerText: 'general.score',
        //     field: 'score',
        //     width: '100',
        //     textAlign: 'center',
        //     template: function Display(props){
        //         return (
        //             <div className="">{parseFloat(props.score).toFixed(2)}</div>
        //         )
        //     }
        // },
        {
            headerText: 'mentorView.mentoringOverview.riasecColumn',
            field: 'riasec',
            width: '100',
            textAlign: 'center',
            template: function Display(props){
                return (
                    <div className="">{parseFloat(props.riasec).toFixed(2)}</div>
                )
            }
        },
        {
            headerText: 'mentorView.mentoringOverview.speedColumn',
            field: 'speed',
            width: '200',
            textAlign: 'center',
            template: function Display(props) {
                return (
                    <div className="">{parseFloat(props.speed).toFixed(2)}</div>
                )
            }
        },
        // {
        //     headerText: 'mentorView.mentoringOverview.selectedColumn',
        //     width: '100',
        //     textAlign: 'center',
        //     field: 'selected',
        //     template: function Action(props) {
        //         const { id } = useParams()
        //         const alert = useAlert()
        //         const {freeze} = useContext(MyContext)
        //         const updateStatus =  () => {
        //             console.log("in call ", freeze)
        //             if(freeze || props.selected > 0) {
        //                 fetch(`https://api.bazookka.com/users/mentor/toggle`, {
        //                     method: 'POST',
        //                     headers: {
        //                         'Content-Type': 'application/json',
        //                     },
        //                     body: JSON.stringify({
        //                         user_id: props.user.id,
        //                         rate_id: props.id,
        //                         post_id: id,
        //                         status: props.selected
        //                     })
        //                 }).then(r => r.json())
        //                     .then(() => {
        //                         mutate()
        //                     })
        //                     .catch(e => console.log(e))
        //             } else {
        //                 alert.error("Liste de disponibilité complete")
        //             }
        //         }
        //         return (
        //             <>
        //                 {props.in_other === 0 && (
        //                     <div
        //                         className={`flex justify-center ${props.selected ? 'bg-green-600' : 'bg-red-600'} text-white rounded-3xl`}
        //                         onClick={updateStatus}
        //                         style={{ cursor: 'pointer', width: 50 }}>
        //                         {props.selected > 0 ? <span>oui</span> : <span>non</span>}
        //                     </div>)}
        //             </>
        //
        //
        //         )
        //     }
        // },
        {
            headerText: 'Details',
            field: '',
            width: '200',
            template: function Buttons(props) {
                const { path } = useRouteMatch()
                const id = props.id
                return <button onClick={() => handleClick(id)}  ><i className="mdi mdi-eye-check" style={{ fontSize: '1rem' }}></i> </button>
            }

        },
    ]

    const createConvention = async (post) => {
        let resp = await fetch(`https://api.bazookka.com/internshipObjects/mentor/group`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                eid: globalId,
                post_id: id,
                post: post
            })
        })

        let resp_body = await resp.json()
        alert.success('')

    }

    function headerTemplate() {
        const userD = data.find(elem => elem.id === currentUserId)
        return (userD ? <div className="flex flex-row justify-items-center align-item">
            <Avatar className="mr-2" name={userD.display_name} size={40} round={true} />
            <div clas title={userD.display_name} className="e-icon-settings dlg-template mb-3">{userD.display_name}</div>
        </div> : "");
    }

    const separatorWithTitle = (title) => {
        return (<div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-4 border-gray-400"></div>
            <span class="flex-shrink mx-4 text-gray-400">{title}</span>
            <div class="flex-grow border-t border-4 border-gray-400"></div>
        </div>)
    }

    function bodyTemplate() {
        const user = data.find(elem => elem.id === currentUserId)
        if (user) {
            return (
                <div className="m-auto" style={{ fontWeight: "normal" }}>
                    {separatorWithTitle(t("mentorView.userView.details.contactInformations"))}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.zohoId")} : </span> {user.meta.zohoId}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.address")} : </span> {user.meta.address}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.kidEmail")} : </span> {user.meta.email}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.kidPhone")} : </span> {user.meta.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.schoolLevel")} : </span> {user.meta.schoolLevel}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentPhone")} : </span> {user.meta.parent.phone}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }} >{t("mentorView.userView.details.parentEmail")} </span> {user.meta.parent.email}</div>
                    {separatorWithTitle("Trait")}
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait1")} : </span> {user.meta.traits.trait1}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait2")} : </span> {user.meta.traits.trait2}</div>
                    <div className="mb-3" ><span style={{ fontWeight: "bold" }}>{t("mentorView.userView.details.trait3")} </span> {user.meta.traits.trait3}</div>

                    {separatorWithTitle(t("mentorView.userView.details.interests"))}
                    {
                        <div className="grid grid-cols-1 divide-y-2">
                            {user.meta.interests.map((i, index) => {
                                let label1 = `mentorView.userView.details.sector${index + 1}`
                                let label2 = `mentorView.userView.details.sectorPrecision${index + 1}`
                                return <div key={index} className="mb-3" >
                                    <div>
                                        <div className="mb-3" style={{ fontWeight: "bold" }}>{t(label1)}: <span style={{ fontWeight: "normal" }} >{i.field}</span></div>
                                        <div style={{ fontWeight: "bold" }}>{t(label2)}:  <span style={{ fontWeight: "normal" }}>{i.reason}</span></div>
                                    </div>
                                </div>
                            })}
                        </div>
                    }




                </div>
            )
        }
        return ""
    }

    if (!data) return <Loader />

    return (
        <OverDiv>
            <DialogComponent allowDragging showCloseIcon animationSettings={{ effect: 'Zoom', duration: 400, delay: 0 }} cssClass="e-dlg-content" width='500px' isModal={true} header={headerTemplate} visible={hideDialog} close={dialogClose} overlayClick={onOverlayClick}>
                {bodyTemplate()}

            </DialogComponent>
            <DropDownListComponent></DropDownListComponent>
            <GridComponent

                locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                // width={`auto`}
                width={'auto'}
                id="gridcomp"
                dataSource={data}
                allowPaging
                allowSorting
                pageSettings={{ pageSize: 25 }}
                contextMenuItems={['AutoFit']}
                toolbar={["Search"]}
                rowDrag={true}
                allowResizing={true}
                allowFiltering={true}
                enableStickyHeader={true}
            >
                <ColumnsDirective
                >
                    {users_header.map((item, index) => {
                        return <ColumnDirective
                            key={index}
                            {...item}
                            headerText={t(item.headerText)}
                        />
                    })}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Search, Toolbar, Filter]} />
            </GridComponent>


        </OverDiv>
    )
}

export default function MtrViewMentor() {
    const { path } = useRouteMatch()
    return (
        <div className="h-full" style={{ position: "relative" }}>
            <Switch>
                <Route path={`${path}/views/:id`} component={ViewUsers} exact>
                </Route>
                <Route
                 path={`${path}/views/:id/nomatch`}
                 component={ViewNoMatch}
                ></Route>
                <Route path={`/me/mentors_view`} component={MentorView} exact>
                </Route>
            </Switch>
        </div>
    )
}