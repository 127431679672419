import { Form, Formik } from "formik";
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from "yup";
import { NiceInput, NiceSelect } from "../../components/FormElements";
import { MyContext } from "../../stores";
import { is2Object, userRoles } from "../../stores/enums/userRoles";
import useSWR from 'swr';
import bzkFetcher from "../../utils/bzkFetcher";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const options = [
    { value: 'seeker', label: 'Student' },
    { value: 'admin_company', label: 'Business' },
    { value: 'user', label: 'Emloyee' }
];


const InviteContainer = styled.div`
width: 100%;
border-radius: 5px;
padding: 10px;
background-color: var(--bazookka-white);
border: 0.5px solid #e8e8e8;
margin-bottom: 1rem;
button {
  background-color: var(--bazookka-blue);
  color: var(--bazookka-white);
}
.error {
  color: red;
}
`;

const ResetButton = styled.button`
    margin-top:15px;
    background-color: var(--bazookka-pink) !important;
`

const NewInviteContainer = styled.div`
    width:100%;
    text-align:center;

    input{
        vertical-align:middle;
        margin:auto;
        border:1px solid black;
    }

    button{
        vertical-align:middle;
    }
`

const InvitationForm = ({type = 'email', mutate}) => {
    const {t} = useTranslation()
    const [company, setCompany] = useState(false)
    const {me, globalId, rolesValue, organizationId, dispatchRoles, langChoice} = useContext(MyContext)
    const [invitationStatus, setInvitationStatus] = useState(null)
    const [invitationEmail, setInvitationEmail] = useState(null);
    const [sendingEmailStatus, setSendingEmailStatus] = useState(null);
    const [saveRequestStatus, setSaveRequestStatus] = useState(null);
    const [latestTokenUrl, setLatestTokenUrl] = useState(null);
    const [copied, setCopied] = useState(false);
    const [groups, setGroups] = useState([])
    const INVITATION_FAILED = 0
    const INVITATION_SUCCEDED = 1
    const alert = useAlert()
    const formikRef = useRef()
    const Roles = Object.keys(userRoles).map( k => {
        return userRoles[k]
    })

    const inviteUrl = "https://api.bazookka.com/signup/"

    const useGroupBySchool = (schoolId, userId) => {
        const {data, error} = useSWR(
            (schoolId)
                ? [`https://api.bazookka.com/groups/school/${schoolId}`, userId]
                : null
            , bzkFetcher
        );

        return {
            groupData: data,
            groupIsLoading: !error && !data,
            groupIsError: error
        }
    }

    const createInvitationToken = email => {
        setInvitationEmail(email);
        return uuidv4();
    };

    useEffect(()=> {
        if(me && me.select) {
            dispatchRoles({
                type: langChoice === 'fr' ? 'SET_FR': 'SET_EN',
                payload: me.select.type === 'school' ? 'sch.': 'cmp.' 
            })   
        }
    }, [me])

    const { groupData } = useGroupBySchool(organizationId, globalId);

    useEffect(() => {
        if(groupData){
            setGroups(groupData.map( doc => {
                return {id: doc.groupid, name: doc.name}
            }))
        }
    }, [groupData])

    const registerInvitation = async (values, setSubmitting, resetForm) => {
        setInvitationStatus(null)
        setInvitationEmail(values.email)
        mutate()
        setSendingEmailStatus(null)
        setSaveRequestStatus(null)

        console.log("registerInvitation values ",values)
        const invitationToken = createInvitationToken(values.email);
        console.log("VALUES", values.groupsId);
        const resp = await fetch("https://api.bazookka.com/invites", {
            headers: {
                'Content-Type': 'application/json',
                'eid': globalId
            },
            method: 'post',
            body: JSON.stringify({
                name: values.displayName,
                role: values.accountRole,
                status: 'active',
                organisation_id: organizationId,
                type: type,
                token: invitationToken,
                email: type === 'email' ? values.email : null,
                lang: langChoice,
                groups: values.groupsId
            })
        });
        let json = await resp.json();

        setSaveRequestStatus('Invitation request registered!');
        setSubmitting(true)
        if(type !== 'email'){
            setLatestTokenUrl(inviteUrl+invitationToken);
        }
        resetForm()
        mutate()
        alert.success(t("general.registeredinvite"))
    };
    
    return (
        <InviteContainer>
            {latestTokenUrl &&
            <NewInviteContainer>
                <div>{t("general.invitelink")}</div>
                <input type="text" 
                    value={latestTokenUrl}
                    onChange={({target: {value}}) => {
                        setLatestTokenUrl(value)
                        setCopied(false);
                    }}
                />
                <CopyToClipboard text={latestTokenUrl} onCopy={() => setCopied(true)}>
                    <button className="button"><i className="mdi mdi-content-copy"></i></button>
                </CopyToClipboard>

                {copied ? <div style={{color: 'green'}}>{t("general.copied")}</div> : null}
            </NewInviteContainer>
            }
            <Formik
            innerRef={formikRef}
            initialValues={{
                displayName: "",
                email: "",
                groupsId: [],
                accountRole: "" // added for our select
            }}
            validationSchema={Yup.object({
                // displayName: Yup.string()
                // .min(5, "Must be 5 characters or less")
                // .required(t('validation.required')),
                // email: Yup.string()
                // .email("Invalid email addresss`")
                // .required(t('validation.required')),
                accountRole: Yup.string()
                .oneOf(
                    Roles,
                    "Invalid Account Type"
                )
                .required(t('validation.required')),
               // groupsId: Yup.array().min(1).required(t('validation.musthaveonegroupe'))
            })}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                registerInvitation(values, setSubmitting, resetForm);
            }}
            >
                {({values, isSubmitting, setFieldValue, errors, resetForm}) => {
                    return (
                        <Form>
                        {type === 'email' &&
                            <NiceInput
                                label={t('general.fullname')}
                                name="displayName"
                                type="text"
                                placeholder="Jane Doe"
                            />
                        }

                        {type === 'email' &&
                            <NiceInput
                                label={t('general.emailaddress')}
                                name="email"
                                type="email"
                                placeholder="jane@bazookka.com"
                            />
                        }

                        <NiceSelect label={t('general.accounttype')} name="accountRole">
                            <option value="">{t('general.selecttype')}</option>
                            { me &&
                                is2Object(me.select.type, t).map( (option, key) => {
                                    return <option key={key} value={option.key}>{option.value}</option>
                                })
                            }
                        </NiceSelect>

                        {(type === 'email' || type === 'group') &&
                            <div className="field">
                                <label>{t('general.groups')}</label>
                                <div className="control">
                                    <Multiselect
                                        name="groupsId"
                                        options={groups}
                                        displayValue="name"
                                        placeholder={t('overlay.select')}
                                        emptyRecordMsg={t("multiselect.noOptionAvailable")}
                                        onSelect={(v)=> {
                                            setFieldValue('groupsId', v.map( k => k.id))
                                        }}
                                        onRemove={(v)=> {
                                            setFieldValue('groupsId', v.map( k => k.id))
                                        }}
                                    />
                                    {errors.groupsId && <div style={{color: 'red'}}>{errors.groupsId}</div>}
                                </div>
                            </div>
                        }

                        {company && <NiceInput
                            label={t('general.companyname')}
                            name="name"
                            type="text"
                            placeholder={t('general.companyname')}
                        />}
                        <button className="button is-fullwidth" type="submit" disabled={isSubmitting}>{t('general.submit')}</button>
                        <ResetButton className="button is-fullwidth" type="button" disabled={isSubmitting}
                          onClick={e => resetForm()}
                        >{t('listing.reset')}
                        </ResetButton>
                        { invitationStatus !== null && (
                            <div className={ invitationStatus === 0 ? 'error' : '' } >
                                <p>{t('general.invitefor')} {invitationEmail}:</p>
                                <ul>
                                    <li>{saveRequestStatus}</li>
                                    <li>{sendingEmailStatus}</li>
                                </ul>
                            </div>
                        )}
                    </Form>
                    )
                }}
            </Formik>
        </InviteContainer>
    );
};

export default InvitationForm
