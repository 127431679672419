import React, { useState, useContext, useEffect } from "react"
import { MyContext } from "../stores";
import { Route, Switch, useParams, Link, useRouteMatch, useHistory } from "react-router-dom"
import { L10n } from '@syncfusion/ej2-base'
import { DialogComponent, ButtonPropsModel, AnimationSettingsModel } from '@syncfusion/ej2-react-popups';
import { Search, GridComponent, ColumnDirective, ColumnsDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, Inject, Toolbar } from "@syncfusion/ej2-react-grids";
import useSWR from 'swr'
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import styled from 'styled-components'
import Loader from "../components/Loader";
import { useAlert } from "react-alert";
import MyInternship from "./MyInternship";
import { EditInternshipObjectFormPG } from "../forms/EditInternshipObjectFormPG"
import BatchComponent from "./Batch";


const ButtonModeContainer = styled.div`
    margin: 10px 0;

    .button{
        background-color: var(--bazookka-darkblue);
        color:var(--bazookka-white)
    }
`

const ButtonCreateContainer = styled.div`
    margin:10px 0;

    .button{
        background-color: var(--bazookka-green);
        color:var(--bazookka-white);
    }
`

function Interns() {
    const { t } = useTranslation()
    const { path } = useRouteMatch()
    const { me, globalId, langChoice } = useContext(MyContext)

    let { data, error, mutate } = useSWR("https://api.bazookka.com/internshipObjects",
        url => fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'eid': globalId
            },
            method: 'get'
        }).then(r => r.json()));

    const onDelete = (data) => {
        console.log(data)
        fetch(`https://api.bazookka.com/internshipObjects/single/${data.id}/${data.user_id}`,
            {method: 'delete', headers: {'Content-Type': 'application/json', eid: globalId}}).then(r => {
                mutate()
                return r.json()
        })
    }



    const internGrip = [
        {
            headerText: 'Avatar',
            width: '40',
            template: function AvatarHeader(props) {
                return props.student.avatar ?
                    <Avatar src={props.student.avatar} size={40} round={true} /> :
                    <Avatar name={props.student.display_name} size={40} round={true} />
            }
        },
        // {
        //     headerText: 'Semaine',
        //     width: '200',
        //     field: 'meta.available'

        // },
        {
            headerText: t("listing.name"),
            width: '200',
            field: 'student.display_name'
        },
        {
            headerText: t("general.internship"),
            width: '200',
            field: 'title'
        },
        {
            headerText: t("general.company"),
            width: '200',
            field: 'meta.businessName'
        },
        {
            width: '80',
            template: function Display(props) {
                const { path } = useRouteMatch()
                return (
                    <div className="flex w-[50px] justify-between p-1">
                        <Link className="flex justify-center align-middle" to={`${path}single/${props.id}`}><i className="mdi mdi-eye-check"></i></Link>
                        <Link className="flex justify-center align-middle" to={`${path}${props.id}/edit`}><i className="mdi mdi-pencil"></i></Link>
                        <a className="flex justify-center align-middle" onClick={()=> onDelete(props)}><i className="mdi mdi-delete"></i></a>
                    </div>

                )
            }
        }
    ]

    if (!data) {
        return <Loader small={false} />
    }

    return (
        <React.Fragment>
            <ButtonModeContainer>
                <div className="flex flex-row gap-1">
                    <div className="w-1/2">
                        <Link to={`${path}`} className="button flex">{t('general.internshipAgreement')}</Link>
                    </div>
                    <div className="w-1/2">
                        <Link to={`${path}batch`} className="button flex">{t('general.groupedInternshipAgreement')}</Link>
                    </div>
                </div>
            </ButtonModeContainer>
            <ButtonCreateContainer>
                <div className="">
                    <Link to={`${path}create`} className="button flex">{t('mentorView.mentoringOverview.createConvention')}</Link>
                </div>
            </ButtonCreateContainer>
            <GridComponent
                locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                width={`auto`}
                id="gridcomp"
                dataSource={data}
                allowPaging
                allowSorting
                pageSettings={{ pageSize: 25 }}
                contextMenuItems={['AutoFit']}
                toolbar={["Search"]}
                allowResizing={true}
            >
                <ColumnsDirective
                >
                    {internGrip.map((item, index) => {
                        return <ColumnDirective
                            key={index}
                            {...item}
                            headerText={t(item.headerText)}
                            maxWidth={800}
                        />
                    })}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Search, Toolbar]} />
            </GridComponent>
        </React.Fragment>
    )
}


function ConventionGroups() {
    const { t } = useTranslation()
    const { path } = useRouteMatch()
    const { me, globalId, langChoice } = useContext(MyContext)
    let { data, error, mutate } = useSWR("https://api.bazookka.com/internshipObjects/group",
        url => fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'eid': globalId
            },
            method: 'get'
        }).then(r => r.json()));
    const onDelete = (data) => {
        console.log(data)
        fetch(`https://api.bazookka.com/internshipObjects/single/${data.id}/${data.user_id}`,
            {method: 'delete', headers: {'Content-Type': 'application/json', eid: globalId}}).then(r => {
            mutate()
            return r.json()
        })
    }

    const internGrip = [
        {
            headerText: 'Avatar',
            width: '100',
            template: function Display(props) {
                return (
                    <div className="-space-x-4">
                        {
                            props.interns.map((intern, i) => {
                                if(intern.user.avatar) {
                                    return (
                                        <Avatar src={intern.user.avatar} alt={intern.user.display_name} key={i} size={40} round={true} />
                                    )
                                }
                                return (
                                    <Avatar name={intern.user.display_name} alt={intern.user.display_name} key={i} size={40} round={true} />
                                )
                            })
                        }

                    </div>
                )
            }
        },
        // {
        //     headerText: 'Semaine',
        //     width: '200',
        //     field: '.interns[0].post.meta.available'
        // },

        {
            headerText: t("general.internship"),
            width: '200',
            template: function Display(props) {
                return (
                    <div>{props.interns[0].post.title}</div>
                )
            }
        },
        {
            headerText: t("general.company"),
            width: '200',
            template: function Display(props) {
                return (
                    <div>{props.interns[0].post.meta.businessName}</div>
                )
            }
        },{
        template: function Display(props) {
            const {path} = useRouteMatch()
            return (
                <div className="flex w-[50px] justify-between p-1">
                    <Link className="flex justify-center align-middle" to={`${path}/${props.id}`}><i
                        className="mdi mdi-eye-check"></i></Link>
                    <Link className="flex justify-center align-middle" to={`${path}/${props.id}/edit`}><i
                        className="mdi mdi-pencil"></i></Link>
                    <a className="flex justify-center align-middle" onClick={() => onDelete(props)}><i
                        className="mdi mdi-delete"></i></a>
                </div>

            )
        }
    }
        // {
        //     width: '80',
        //     template: function Display(props) {
        //         const { path } = useRouteMatch()
        //         return (
        //             <Link to={`${path}/${props.id}`} className="flex justify-center"><i className="mdi mdi-eye-check"></i></Link>
        //         )
        //     }
        // }
    ]

    if (!data) {
        return <Loader small={false} />
    }

    return (
        <React.Fragment>
            <ButtonModeContainer>
                <div className="flex flex-row gap-1">
                    <div className="w-1/2">
                        <Link to={`/me/io`} className="button flex">{t('general.internshipAgreement')}</Link>
                    </div>
                    <div className="w-1/2">
                        <Link to={`${path}`} className="button flex">{t('general.groupedInternshipAgreement')}</Link>
                    </div>
                </div>
            </ButtonModeContainer>
            <ButtonCreateContainer>
                <div className="">
                    <Link to={`/me/io/create`} className="button flex">{t('mentorView.mentoringOverview.createConvention')}</Link>
                </div>
            </ButtonCreateContainer>
            <GridComponent
                locale={langChoice === "fr" ? "fr-FR" : "en-US"}
                width={`auto`}
                id="gridcomp"
                dataSource={data}
                allowPaging
                allowSorting
                pageSettings={{ pageSize: 25 }}
                contextMenuItems={['AutoFit']}
                toolbar={["Search"]}
                allowResizing={true}
            >
                <ColumnsDirective
                >
                    {internGrip.map((item, index) => {
                        return <ColumnDirective
                            key={index}
                            {...item}
                            headerText={t(item.headerText)}
                            maxWidth={800}
                        />
                    })}
                </ColumnsDirective>
                <Inject services={[Resize, Sort, Page, ContextMenu, Filter, Toolbar]} />
            </GridComponent>
            <Switch>
                <Route path={`${path}/batch/:id`} component={ConventionGroupsOver} exact>
                </Route>
            </Switch>
        </React.Fragment>


    )
}

const ConventionGroupsOver = () => {
    const { history } = useHistory()
    return (
        <div className='control-pane'>
            <div id='targetElement' className='control-section col-lg-12 defaultDialogComponent dialog-target'>
                <DialogComponent id="defaultDialog" showCloseIcon={true} animationSettings={{ effect: 'None' }} visible={true} width={'700px'} ref={dialog => this.dialogInstance = dialog}
                    target={'#targetElement'} header='Syncfusion Components inside Dialog' buttons={this.buttons} open={true} close={() => history.goBack()}>


                </DialogComponent>
            </div>
        </div>
    )
}

export default function InternshipsObject() {
    const { path } = useRouteMatch()
    const { t } = useTranslation()

    L10n.load({
        'fr-FR': {
            'grid': {
                'EmptyDataSourceError': 'Erreur de chargement des données',
                'EmptyRecord': `Aucun stage á charger`,
                'Item': 'stage',
                'Items': 'stages',
                'name': 'nom',
                'Search': "Chercher"
            },
            'pager': {
                'currentPageInfo': 'Page {0} de {1}',
                'totalItemsInfo': '({0} stages)',
                'totalItemInfo': '({0} stage)'
            }
        },
        'en-US': {
            'grid': {
                'EmptyDataSourceError': 'Error loading data',
                'EmptyRecord': `There's no internship to load`,
                'Item': 'internship',
                'Items': 'internships',
                'name': 'nom',
                'Search': "Search"
            },
            'pager': {
                'currentPageInfo': 'Page {0} of {1}',
                'totalItemsInfo': '({0} internships)',
                'totalItemInfo': '({0} internship)'
            }
        }
    })

    return (
        <div className="h-full w-[100%]" style={{ position: "relative" }}>
            <Switch>
                <Route path={`${path}/`} component={Interns} exact>
                </Route>
                <Route path={`${path}/single/:slug`} component={MyInternship} exact></Route>
                <Route path={`${path}/:slug/edit`} component={EditInternshipObjectFormPG}></Route>
                <Route path={`${path}/create`} component={EditInternshipObjectFormPG}></Route>
                <Route path={`${path}/batch`} component={ConventionGroups} exact></Route>
                <Route path={`${path}/batch/:slug`} component={BatchComponent}></Route>
            </Switch>
        </div>
    )
}

