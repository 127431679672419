import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { version as uuidVersion, validate as uuidValidate } from 'uuid';
import { firebaseInstance } from "../../firestore/firestoreService";
import { useTranslation } from "react-i18next";
import Registration from "./Registration";
import { JustCoverBG } from "../main/CoverBG";
import useSWR from 'swr';
import Loader from '../../components/Loader';
import ErrorPage from '../ErrorPage';


const SignUp = () => {
    const { t } = useTranslation()
    const [signUpStep, setSignUpStep] = useState(1);
    const [invitation, setInvitation] = useState(null);
    const [badToken, setBadToken] = useState(false);
    const [id, setId] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false)
    const { token } = useParams();

    const { data } = useSWR(token != '' && uuidValidateV4(token) ?
        `https://api.bazookka.com/users/u/tokenInfo/${token}` : null,
        url => fetch(url, { method: 'get', headers: { 'Content-Type': 'application/json' } })
            .then(r => r.json())
    )

    !data && <Loader/>

    const userExists = firebaseInstance()
        .functions()
        .httpsCallable('checkUserExist')
    const [initialValues, setInitialValues] = useState({
        displayName: "",
        email: "",
        name: "",
        address: "",
        invites: [],
        role: '',
    });

    function uuidValidateV4(uuid) {
        return uuidValidate(uuid) && uuidVersion(uuid) === 4;
    }

    useEffect(async () => {
        if (!isRegistered) {
            let check = await userExists({ email: initialValues.email })
            if (check?.data?.email) {
                setIsRegistered(true)
            }
        }
    }, [isRegistered, initialValues])

    return (
        <React.Fragment>
            {invitation && <JustCoverBG {...invitation.data} />}
            {
                data && data.status === 'active'  ? 
                <Registration token={token} tokenInfo={data} setSignUpStep={setSignUpStep} setBadToken={setBadToken} badToken={badToken} t={t} data={initialValues} id={id} saved={true} />
                : <ErrorPage/>
            }
        </React.Fragment>
    )
}

export default SignUp;
